import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function OrderDeal() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');

    useEffect(() => {

    }, []);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        endAdornment={<IconButton><SearchIcon /></IconButton>}
                        value={searchVal}
                        onChange={handleSearch}
                    />
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>日期</TableCell>
                                    <TableCell align="right">订单号</TableCell>
                                    <TableCell align="right">产品SKU</TableCell>
                                    <TableCell align="right">平台</TableCell>
                                    <TableCell align="right">用户ID</TableCell>
                                    <TableCell align="right">订单处理</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => setOpen(true)}>
                                                退换货
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>

                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b"> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTab} aria-label="lab API tabs example">
                                    <Tab label="退货" value="1" />
                                    <Tab label="换货" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div className="erp-mt-20">
                                    <TextField
                                        className="erp-w-100"
                                        label="快递公司"
                                        placeholder="快递公司"
                                    />
                                </div>
                                <div className="erp-mt-20">
                                    <TextField
                                        className="erp-w-100"
                                        label="快递公司"
                                        placeholder="快递公司"
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value="2">
                                <div className="erp-mt-20">
                                    <TextField
                                        className="erp-w-100"
                                        label="快递公司"
                                        placeholder="快递公司"
                                    />
                                </div>
                                <div className="erp-mt-20">
                                    <TextField
                                        className="erp-w-100"
                                        label="快递公司"
                                        placeholder="快递公司"
                                    />
                                </div>
                                <div className="erp-mt-20">
                                    <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                                        换货SKU
                                    </Button>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                        取消
                    </Button>
                    <Button variant="outlined" id="upload" >
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default OrderDeal;