import React, {useState, useEffect} from 'react';  
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarsIcon from '@mui/icons-material/Stars'; 
import blog1 from '../../../images/blog1.png';
import blog2 from '../../../images/blog2.png';
import blog3 from '../../../images/blog3.png';  
import mAvatar from '../../../images/male.png';
import fAvatar from '../../../images/female.png';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';  
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog'; 
import DialogContent from '@mui/material/DialogContent'; 
import Avatar from '@mui/material/Avatar';
import AvataImg from '../../../images/avatar1.png'; 
import SendIcon from '@mui/icons-material/Send';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RedeemIcon from '@mui/icons-material/Redeem';
import './it.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,   
    paddingTop: '70px',
    paddingBottom: '50px',
  },    
}));

export default function Recommend(props) {
  const classes = useStyles(); 
  const [recommend, setRecommend] = useState([]);
  const [recommendItem, setRecommendItem] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const [nIndex, setNIndex] = useState(1);

  useEffect(() => {   
    setRecommend(JSON.parse(localStorage.getItem("recommend")));  
  }, [props])

  useEffect(()=>{
    setRecommendItem(recommend[0]);
    setLoaded(true);
  }, [recommend])

  const widthMatch = useMediaQuery(
    json2mq({
      minWidth: 600,
    }),
  );
  const isMobile = useMediaQuery(
    json2mq({
      minWidth: 465,
    }),
  ); 

  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenProfile = () => {
    setOpenProfile(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const selectItem = (item, index) =>{
    setRecommendItem(item);
    setNIndex(index);
  };

  return (
    <div className={classes.root}>
      <Container  maxWidth="lg" className={"recommend"}>
        <h2 className={"recommendTitle"}>Recommended for you ( You select {nIndex} th recommend friend)</h2>
        <Card sx={{ minWidth: 275 }} className={"card"}> 
            <Grid  container spacing={1}> 
                <Grid  item xs={12} md={6} className={"desGroup"}>
                   {recommendItem!=null?
                    <div> 
                      <h4>{recommendItem["userInfo"]["name"]}   {recommendItem["userInfo"].birth.substring(0, 10)}</h4>
                      <h4>{recommendItem["userInfo"]["location"]}<span className={"profileLink"} onClick={()=>handleClickOpenProfile()}> ViewProfile ></span></h4>
                    </div>:
                    <></> 
                   } 
                </Grid>   
                <Grid  item xs={12} md={6} className={"btnGroup"}>
                  <Button variant="outlined" startIcon={<CloseIcon />} className={"passBtn"}>Pass</Button>
                  <Button variant="outlined" startIcon={<FavoriteIcon />} className={"likeBtn"}>Like</Button>
                  <Button variant="outlined" startIcon={<StarsIcon />}  className={"superBtn"}>SuperLike</Button>
                  {/* <Button variant="outlined" startIcon={<StarsIcon />} onClick={handleClickOpen} className={"superBtn"}>SuperLike</Button> */}

                </Grid>
            </Grid>
            <Grid  container spacing={1} className={"horizontalView"}> 
             
               {recommend.length > 0 ?
                  <div className={"profileWrap"}>
                    {
                      recommend.map((item, index)=>{
                        return(
                          <div className={"item"}>
                            <div  className={"profileImgWrap recommend-item"}><img src={item.userInfo.photo.length===0?mAvatar:item.userInfo.photo[0]} className={"profileImg"} alt=""/></div> 
                            <div className={"cover"}>
                                <VisibilityIcon className="btn-view" onClick={()=>selectItem(item, index+1)}/>
                            </div>
                          </div>

                        );
                      })
                    }
                  </div> :
                  <></> 
               } 
            
            </Grid>
        </Card>         
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <div className={"avatarWrap"}>
            <Avatar alt="Sharp" src={AvataImg} sx={{ width: 80, height: 80 }} className={"vixenAvatar"} />
            <p className={"sendName"}>Name of Match</p>
            <Button variant="outlined" startIcon={<SendIcon />} className={"messageBtn"}>Message</Button>
        </div>
        <DialogContent> 
           <p className={"vixenAmount"}>$15.00</p>
           <div className={"vixenBtnWrap"}><Button onClick={handleClose} variant="outlined" startIcon={<MonetizationOnIcon />} className={"messageBtn"}>VXN</Button></div>
           <div className={"vixenBtnWrap"}><Button onClick={handleClose} variant="outlined" startIcon={<RedeemIcon />} className={"messageBtn"}>Send  Vixen as Gift</Button></div>  
        </DialogContent> 
      </Dialog>
      <Dialog open={openProfile} onClose={handleCloseProfile}>
        <div className={"avatarWrap"}>
            <Avatar alt="Sharp" src={recommendItem?.userInfo.photo.length===0?mAvatar:recommendItem?.userInfo["photo"][0]} sx={{ width: 80, height: 80 }} className={"vixenAvatar"} />
            <p className={"sendName"}>Name: {recommendItem?.userInfo.name}</p>
            <p className={"sendName"}>Birth: {recommendItem?.userInfo.birth.substring(0, 10)}</p>
            <p className={"sendName"}>BodyType: {recommendItem?.userInfo.body}</p>
            <p className={"sendName"}>...</p>
            {/* <Button variant="outlined" startIcon={<SendIcon />} className={"messageBtn"}>Message</Button> */}
        </div>
        <DialogContent>  
           <div className={"vixenBtnWrap"}><Button onClick={handleCloseProfile} variant="outlined"  className={"messageBtn"}>Close</Button></div>  
        </DialogContent> 
      </Dialog>
    </div>
  );
}
