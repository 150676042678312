import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, LinearProgress } from '@material-ui/core';
import glass from './glass.jpg';
import { styled } from '@mui/material/styles';
import '../it.css';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));


function CategoryBox() {
    const [checked, setChecked] = React.useState({});

    const handleChange = (e) => {
        // var temp = { ...checked };
        // temp[item] = e.target.checked;
        // setChecked(temp);
    };
    return (
        <Grid item xs={12} md={3} className="right-scope">
            <div className='item-area'>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <h3>腿部要求</h3>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h3 className="ai-c-label">要求1</h3>
                        <FormControlLabel
                            className="check-box-wrap"
                            control={
                                <Checkbox defaultChecked checked={checked[0]}
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"AP1"}
                        />
                        <FormControlLabel
                            className="check-box-wrap"
                            control={
                                <Checkbox defaultChecked checked={checked[0]}
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"AP1"}
                        />

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h3 className="ai-c-label">要求1</h3>
                        <FormControlLabel
                            className="check-box-wrap"
                            control={
                                <Checkbox defaultChecked checked={checked[0]}
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"AP1"}
                        />
                        <FormControlLabel
                            className="check-box-wrap"
                            control={
                                <Checkbox defaultChecked checked={checked[0]}
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"AP1"}
                        />

                    </Grid>

                </Grid>
            </div>
            <div className='btn-area'>
                <Button variant="contained" className='primary-color primary-width primary-tm'>AI设计</Button>
            </div>

        </Grid>
    );
}

export default CategoryBox;
