import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import json2mq from 'json2mq';
import Logo from '../../../images/logo.png';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MessageIcon from '@mui/icons-material/Message';
import CommonBtn from '../../Global/CommonBtn';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector, useDispatch } from 'react-redux';
import { setShowChat } from '../../../data/Status/Action';
import { setSignInfo } from "../../../data/User/Action";
import './it.css';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  navButtonWrap: {
    float: 'right',
    paddingRight: '5%'
  },
  navInnerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullList: {
    width: 'auto',
  },
  drawerMenuTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: "#e4caa9",
      cursor: 'pointer'
    },
  },
  drawer: {
    background: '#151c28'
  },
  buttonHover: {
    textTransform: 'initial',
    fontFamily: 'Montserrat',
    fontWeight: 'w500',
    borderRadius: '1px',
    fontSize: '18px',
    color: 'white',
    textDecoration: 'none',
    marginRight: '10px',
    lineHeight: '1.15',
    '&:hover': {
      borderBottom: 'solid',
      borderBottomWidth: '0px',
      color: "#e4caa9",
    }
  },
  activeBtn: {
    textTransform: 'initial',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    borderRadius: '1px',
    fontSize: '18px',
    textDecoration: 'none',
    marginRight: '10px',
    borderBottom: 'solid',
    borderBottomWidth: '0px',
    color: "#688ce8",
  },
  appLogo: {
    maxWidth: '105px !important',
    position: 'absolute'
  }

}));


function ScrollTop(props) {

  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Navbar(props) {

  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.signInfo);
  const [state, setState] = useState({ top: false, });
  const [userInfo, setUserInfo] = useState("");
  const [islog, setIslog] = useState(false);
 

  useEffect(() => {
    setUserInfo(user);
    console.log("header>>>>>", user)
    try{
      if('token' in user){
        if(user.token != ""){
          setIslog(true);
        }
      }
    }catch(e){

    } 
  }, [user]);

  const widthMatch = useMediaQuery(
    json2mq({
      minWidth: 768,
    }),
  );
  // const isMobile = useMediaQuery(
  //   json2mq({
  //     minWidth: 465,
  //   }),
  // );
  const classTwo = useStyles2();
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classTwo.list, { [classTwo.fullList]: anchor === 'top' || anchor === 'bottom', })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {userInfo?.userRole >= 0 ?
        <div>
          {userInfo?.userRole === 0 ?
            <List className={classTwo.drawer}>
              {/* {['Admin', 'Profile', 'Tokens', 'Gifting Hub', 'Buy Tokens', 'WhitePaper', 'Chat'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemText className={classTwo.drawerMenuTitle} primary={text} onClick={() => btnActive(text)} />
                  <Divider />
                </ListItem>
              ))} */}
            </List> :
            <List className={classTwo.drawer}>
              {/* {['Profile', 'Tokens', 'Gifting Hub', 'Buy Tokens', 'WhitePaper', 'Chat'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemText className={classTwo.drawerMenuTitle} primary={text} onClick={() => btnActive(text)} />
                  <Divider />
                </ListItem>
              ))} */}
            </List>
          }
        </div>
        :
        <List className={classTwo.drawer}>
          {/* {['Gifting Hub', 'Tokens', 'Buy Tokens', 'Sign In'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemText className={classTwo.drawerMenuTitle} primary={text} onClick={() => btnActive(text)} />
              <Divider />
            </ListItem>
          ))} */}
        </List>
      }

    </div>
  );

  const btnActive = (value) => {

  }


  const goToken = () => {
    history.push("/token");
  }


  const openChat = () => {
    dispatch(setShowChat(true));
  }

  const logout = () => {
    localStorage.clear();
    dispatch(setSignInfo(null));
    history.push("/sign_in")
  }

  return (
    <div className="headerWrap">
      <CssBaseline />
      <AppBar  >
        <Toolbar className={classTwo.navInnerWrap}>
          <Typography variant="h6"><div className={"logoWrap"} onClick={()=>history.push("/")}><img alt="glasses" className={classTwo.appLogo} src={Logo} /></div></Typography>
          <div className={classTwo.navButtonWrap}>
            {widthMatch ?
              <div>
                <Button className={classTwo.buttonHover} onClick={()=>history.push("/search_glass")}>样品搜索</Button>
                <Button className={classTwo.buttonHover} onClick={()=>history.push("/match_glass")}>AI设计</Button>
                { islog&&<Button className={classTwo.buttonHover} onClick={()=>logout()}>登出</Button> }
                
                {/* {userInfo?.userRole >= "0" ?
                  <div>
                    {userInfo?.userRole === "0" ? 
                    <Button className={classTwo.buttonHover} onClick={go_admin}>Admin</Button> : <></>}
                    <Button className={classTwo.buttonHover} onClick={profile}>Profile</Button>
                    <Button className={classTwo.buttonHover} onClick={goToken}>Tokens</Button>
                    <Button className={classTwo.buttonHover} onClick={goShop}>Gifting Hub</Button>
                    <Button className={classTwo.buttonHover} onClick={goWhite}>WhitePaper</Button>
                    <CommonBtn name="Buy Token" />                     
                  </div> :
                  <div>
                    <Button className={classTwo.buttonHover} onClick={goToken}>Tokens</Button>
                    <Button className={classTwo.buttonHover} onClick={goShop}>Gifting Hub</Button>
                    <Button className={classTwo.buttonHover} onClick={goWhite}>WhitePaper</Button> 
                    <Button className={"join-button"} onClick={sign_in}>Sign In</Button>
                  </div>
                } */}
              </div> : <IconButton color="inherit" onClick={toggleDrawer('top', true)}><MenuIcon /></IconButton>}
          </div>
        </Toolbar>
      </AppBar>

      <div>
        <React.Fragment key={'top'}>
          <SwipeableDrawer anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)} onOpen={toggleDrawer('top', true)}>
            {list('top')}
          </SwipeableDrawer>
        </React.Fragment>
      </div>

      <Toolbar id="back-to-top-anchor" />
      <ScrollTop className={"scroll-btn"} {...props}>
        <Fab className="toUp" color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}
