import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ProfilePage from '../pages/ProfilePage';
import SignIn from '../pages/SigninPage';
import SignUp from '../pages/SignupPage';
import PasswordReset from '../pages/PasswordReset';
import ContactPage from '../pages/ContactPage';
import Dashboard from '../pages/Dashboard/Dashboard';
import AuthRoute from "./AuthRoute"; 
import MatchPart from "../pages/MatchPart/MatchPart";
import { useSelector } from 'react-redux';
import HomePage from "../pages/HomePage";
import SearchGlass from "../pages/SearchGlass/SearchGlass";

export default function MyRouters() {
    const user = useSelector((state) => state.user.signInfo);
    useEffect(() => {
        //  console.log(">>>>router user ", user);
        //  const role = user.user.e_roles;
        //  console.log(">>>>router user ", role);
    }, [user])
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={SignIn} />
                <Route exact path="/sign_in" component={SignIn} />
                <Route exact path="/menu" component={HomePage} /> 
                <Route exact path="/search_glass" component={SearchGlass} />
                <Route exact path="/match_glass" component={MatchPart} />
                <AuthRoute>
                    <Route path="/admin" component={Dashboard} />
                </AuthRoute>
            </Switch>
        </Router>
    );
}