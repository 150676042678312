import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import { setErpSampleTags, setErpPageIndex } from "../../../data/Status/Action";
import '../it.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function SearchBox() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const [categoryData, setCategoryData] = useState({});
    const [checked, setChecked] = React.useState({});
    const [ptags, setPtags] = useState([]);
    const [sId, setSId] = useState("");
    const [mCat, setMCat] = useState([]);
    const [sCat, setSCat] = useState([]);
    const [scat, setScat] = useState("");
    const [mcat, setMcat] = useState("");
    const [mode, setMode] = useState(true);
    const [glass, setGlass] = useState([])
    const [pidx, setPidx] = useState(0);
    const [sidx, setSidx] = useState(false);
    const [popImg, setPopImg] = useState("");
    const [downItem, setDownItem] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const category = useSelector((state) => state.status.setSampleTags);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const [slideImg, setSlideImg] = useState([]);


    useEffect(() => {
        setCategoryData(category);
        if (typeof category == 'object') {  
            var temp = {};
            category && Object.keys(category).map((keys, indexs) => {
                category[keys].forEach((item, index) => {
                    var tp = item + ":" + keys;
                    temp[tp] = false;
                });
            });
            setChecked(temp);
        }
    }, [category]);

    const handleChange = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setPtags(temps);
    }

    const setPage = () => {
        dispatch(setErpPageIndex(""))
    }

     
    const handleId = (e) => {
        if (e.target.value == "") {
            setMode(true)
        } else {
            setMode(false);
        }
        setSId(e.target.value);
    }
    const searchObject = () => {
        setWaiting(true);
        var tag = "";
        var id = "";
        var page = "";
        var m = 0;
        if (mode) {
            tag = ptags
            m = 2;
        } else {
            id = sId;
            m = 1
        }
        var data = {
            mode: m,
            g_id: id,
            g_tag: tag,
            g_page: ""
        }
        axios.patch(api_prefix + "/glass", data)
            .then((response) => {
                setWaiting(false);
                var temp = response.data;
                temp.forEach((i, index) => {
                    temp[index]["add"] = false;
                    temp[index]["idx"] = index;
                })
                setGlass(temp);
                setPidx(1);
            })
            .catch((error) => {
                throw (error);
            })
    }
    const addItems = (item, index) => {
        var gTemp = [...glass];
        var temp = [...downItem];
        if (gTemp[index].add == false) {
            temp.push(item);
            setDownItem(temp);
        } else {
            var ind = temp.indexOf(item);
            temp.splice(ind, 1);
            setDownItem(temp);
        }
        gTemp[index].add = !gTemp[index].add;
        setGlass(gTemp);
    }
    const removeItems = (item) => {
        var temp = [...downItem];
        var ind = temp.indexOf(item);
        temp.splice(ind, 1);
        setDownItem(temp);
    }
    const toSearch = () => {
        setPidx(0);
    }
    const openFolder = () => {
        setSidx(!sidx)
    }
    const handleClickOpen = (e, img, item) => {
        setSlideImg(item.gsurl);
        setPopImg(img);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const downPdf = async () => {
        for (var i = 0; i < downItem.length; i++) {
            var data = {
                id: downItem[i].id,
                gid: downItem[i].gid,
                url: downItem[i].gmurl,
                surl: downItem[i].gsurl,
                mode: "2"
            }
            setWaiting(true);
            axios.post(api_prefix + "/pdf", data)
                .then((response) => {
                    let filename = "hindar-optical.pdf";
                    setWaiting(false);
                    let pdfUrl = response.data;
                    const axios = require('axios');
                    axios({
                        method: "get",
                        url: pdfUrl,
                        responseType: "arraybuffer"
                    })
                        .then((response) => {
                            var link = document.createElement("a");
                            link.href = window.URL.createObjectURL(
                                new Blob([response.data], { type: "application/octet-stream" })
                            );
                            link.download = filename;
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(function () {
                                window.URL.revokeObjectURL(link);
                            }, 200);
                        })
                        .catch((error) => { });
                })
                .catch((error) => {
                    setWaiting(false);
                    throw (error);
                })
        }
    }


    return (
        <>
            {waiting ?
                <Grid item xs={12} md={9}>
                    <LinearProgress />
                </Grid> :
                <></>
            }
            {pidx == "0" ?
                <Grid item xs={12} md={9} className="left-scopre">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <OutlinedInput
                                className='search-box'
                                endAdornment={<IconButton><SearchIcon /></IconButton>}
                                placeholder="请输入产品编号"
                                value={sId}
                                onChange={handleId}
                            />
                        </Grid>
                        <Grid item xs={0} md={8}> </Grid>
                        {mode ?
                            <> 
                                <Grid item md={8} xs={12}>
                                    {
                                        categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                            <>
                                                <div className="check-box-mux">
                                                    <p className="check-label">{keys} :</p>
                                                    {categoryData[keys].map((item, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                className="check-box-wrap"
                                                                control={
                                                                    <Checkbox defaultChecked
                                                                        checked={checked[`${item}:${keys}`]}
                                                                        onChange={(e) => handleChange(e, item, keys)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                }
                                                                label={`${item}`}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <Divider className="my-divider" />
                                            </>
                                        ))
                                    }
                                </Grid>
                            </> : <></>
                        }

                    </Grid>
                </Grid>
                :
                <Grid item xs={12} md={9} className="left-scope">
                    <Grid container spacing={3}>
                        {
                            glass.length > 0 ?
                                glass.map((item, index) => {
                                    return (
                                        <Grid item xs={12} md={4} >
                                            <div className="item-wrap">
                                                <div className="item-img-wrap">
                                                    <img src={item.gmurl} alt="" className="item-img" />
                                                    <div className="hover-wrap" onClick={(e) => handleClickOpen(e, item.gmurl, item)}>
                                                        {/* <ZoomInIcon className="grey-color-btn-icon" /> */}
                                                    </div>
                                                </div>
                                                <div className="item-des-wrap">
                                                    <Grid container>
                                                        <Grid item xs={12} md={9}>
                                                            <p className="item-label">款号：{item.gids}</p>
                                                            <p className="item-label">样品来源：{item.gname}</p>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} className="btn-wrap">
                                                            <IconButton onClick={() => addItems(item, index)}>
                                                                {
                                                                    item.add ?
                                                                        <RemoveCircleIcon className="disable-color-btn-icon" />
                                                                        :
                                                                        <AddCircleIcon className="primary-color-btn-icon" />
                                                                }

                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                }) :
                                <Grid item xs={12} md={4} >
                                    <h5>No result</h5>
                                </Grid>
                        }
                    </Grid>
                    <Dialog
                        fullWidth={true}
                        maxWidth={"md"}
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle className="my-font-b">详细视图</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {slideImg.length > 0 ?
                                    <Slide>
                                        {slideImg.map((slideImage, index) => (
                                            <div className="each-slide" key={index}>
                                                <img src={slideImage} alt=""  />
                                            </div>
                                        ))}
                                    </Slide> :
                                    <p>没有要显示的子图像.</p>
                                }  
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="my-font-b" onClick={handleClose}>关闭</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            } 
            <Grid item xs={12} md={3} className="right-scope">
                <div>
                    <StyledBadge badgeContent={downItem.length} color="primary" className='folder-badge' onClick={openFolder}>
                        <FolderOpenIcon color="action" className='folder-icon' />
                    </StyledBadge >
                </div> 
                <div className='item-area'>
                    <Grid container>
                        {sidx && downItem.length != 0 ?
                            downItem.map((item, index) => {
                                return (
                                    <Grid item xs={6} md={6}>
                                        <div className="image-wrap">
                                            <img src={item.gmurl} data={item.murl} className="main-image-item" alt="thumb" />
                                            <IconButton className="image-control-btn" onClick={() => removeItems(item)}>
                                                <CancelRoundedIcon className="close-icon" />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                );
                            }) : <></>
                        }
                    </Grid>
                </div>
                <div className='btn-area'>
                    {sidx && downItem.length != 0 ?
                        <Button variant="contained" className='primary-color primary-width primary-tm' onClick={downPdf}>下载 PDF</Button> : <></>
                    }
                    <br />
                    {
                        pidx == 0 ?
                            <Button variant="contained" className='primary-color primary-width primary-tm' onClick={searchObject}>开始</Button>
                            :
                            <Button variant="contained" className='primary-color primary-width primary-tm' onClick={toSearch}>取消</Button>
                    }
                </div> 
            </Grid>
        </>
    );
}

export default SearchBox;
