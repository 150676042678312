import Navbar from '../../components/Global/Header/Nav';
import React, { useEffect, useState } from "react"; 
import { useSelector, useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container'; 
import Badge from '@mui/material/Badge'; 
import { LinearProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import './it.css';
import SearchBox from './part/SearchBox'; 
import DownBox from './part/DownBox';
import ItemBox from './part/ItemBox';
import { setErpSampleTags } from '../../data/Status/Action';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));


function SearchGlass() { 
    const [page, setPage] = useState("");
    const pageIndex = useSelector((state) => state.status.setPageIndex);
    const user  = useSelector((state) => state.user.signInfo);

    const dispatch = useDispatch();

    useEffect(() => { 
        setPage(pageIndex); 
        var session = localStorage.getItem("user"); 
    }, [pageIndex]);

    useEffect(()=>{
        console.log(">>>>user", user);
    }, [user])

    return (
        <div >
            <Navbar />
            <Container maxWidth="xl" className='search-scope'>
                <h2>纯音科技管理系统</h2>
                <Grid container spacing={2}>
                    <SearchBox />  
                </Grid>
            </Container>
        </div>
    );
}

export default SearchGlass;
