import './App.css';
import MyRouters from './routers/Routers';

function App() {
  return (
    <div className='HINDAR'>
      <MyRouters/>
    </div>
  );
}

export default App;
