import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FileUploadIcon from '@mui/icons-material/FileUpload';


const attributesERP = ["成镜", "镜片", "包装", "眼镜配件", "电子配件", "辅料", "加工"];

function SupplyChain() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const s3_prefix = process.env.REACT_APP_S3_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [supplierList, setSupplierList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0); 

    const [id, setId] = useState("");
    const [c_name, setC_name] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");
    const [url, setUrl] = useState("");
    const [proImg, setProImg] = useState(null);
    const [product, setProduct] = useState("");
    const [property, setProperty] = useState("");
    const [payCycle, setPayCycle] = useState(1);

    useEffect(() => {
        setLoaded(true)
        axios.get(api_prefix + '/supplier')
            .then(res => {
                var resData = res.data;
                var temp = [...supplierList];
                resData.forEach((item) => {
                    temp.push(item)
                })
                setSupplierList(temp);
                setLoaded(false)
            }).catch((error) => {
            });
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changeProProperty = (event) => {
        setProperty(event.target.value);
    };

    const changePayCycle = (event) => {
        setPayCycle(event.target.value)
    }

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setProImg(e.target.files[0]);
            setUrl("");
        }
    }

    const uploadFile = async (dir, names) => {
        let reader = new FileReader();
        await reader.readAsDataURL(proImg);
        reader.onload = function () {
            var data = {
                dir: dir,
                img: reader.result,
                imgName: names
            }
            axios.post(api_prefix + "/uploadImg", data)
                .then((res) => {
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }

    const createSupplier = async () => {
        if (c_name === "" || address === "" || contact === "" || phone === "" || proImg === null || product === "" || property === "" || payCycle === "") {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var fileName = Date.now();
        var dir = "supplier";
        var n = proImg.name.split('.');
        var ext = n[n.length - 1];
        uploadFile(dir, fileName);
        var urls = s3_prefix + '/' + dir + '/' + fileName + "." + ext;
        var data = {
            c_name,
            info: {
                address,
                contact,
                phone,
                url: urls,
                product,
                property,
                payCycle
            }
        }
        axios.post(api_prefix + '/supplier', data)
            .then(res => {
                var temp = [...supplierList];
                temp.push(res.data)
                setSupplierList(temp);
                notify_success("创建成功");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("未创建！ 检查您的网络");
            });
    }

    const editSupplier = async () => {
        if (c_name === "" || address === "" || contact === "" || phone === "" || product === "" || property === "" || payCycle === "") {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var urls = "";
        if (url === "") {
            var fileName = Date.now();
            var dir = "supplier";
            var n = proImg.name.split('.');
            var ext = n[n.length - 1];
            uploadFile(dir, fileName);
            urls = s3_prefix + '/' + dir + '/' + fileName + "." + ext;
        } else {
            urls = url;
        }
        var data = {
            id,
            c_name,
            info: {
                address,
                contact,
                phone,
                url: urls,
                product,
                property,
                payCycle
            }
        }
        axios.put(api_prefix + '/supplier', data)
            .then(res => {
                var temp = [...supplierList];
                temp[editIndex] = data;
                setSupplierList(temp);
                notify_success("成功更新");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const deleteSupplier = () => {
        setLoaded(true)
        axios.delete(api_prefix + '/supplier/' + id)
            .then(res => {
                var temp = [...supplierList];
                temp.splice(editIndex, 1);
                setSupplierList(temp);
                notify_success("成功删除");
                setLoaded(false)
                setOpen(false);
            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const openCreateModal = () => {
        setId("");
        setC_name("");
        setAddress("");
        setContact("");
        setPhone("");
        setUrl("");
        setProduct("");
        setProperty("");
        setPayCycle("");
        setEditIndex(0);
        setEditMode(0);
        setOpen(true)
    }
    const openEditModal = (item, index) => {
        setId(item.id);
        setC_name(item.c_name);
        setAddress(item.info.address);
        setContact(item.info.contact);
        setPhone(item.info.phone);
        setUrl(item.info.url);
        setProduct(item.info.product);
        setProperty(item.info.property);
        setPayCycle(item.info.payCycle);
        setEditIndex(index);
        setEditMode(1);
        setOpen(true);
    }
    const confirmEdit = () => {
        if (editMode === 0) {
            createSupplier();
        } else {
            editSupplier();
        }
    }

    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="xl" className="sample-scope" >
                        <Grid container spacing={3}>
                            {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                            <Grid item xs={12} md={12}>
                                <OutlinedInput
                                    endAdornment={<IconButton><SearchIcon /></IconButton>}
                                    value={searchVal}
                                    onChange={handleSearch}
                                />
                                <IconButton className="erp-ml-10" onClick={() => openCreateModal()}>
                                    <AddCircleIcon className="erp-primary-color-btn-icon" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>供应商名称</TableCell>
                                                <TableCell align="right">地址</TableCell>
                                                <TableCell align="right">联系人</TableCell>
                                                <TableCell align="right">电话号码</TableCell>
                                                <TableCell align="right">明细</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {supplierList.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={item.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{item.c_name}</TableCell>
                                                        <TableCell align="right">{item.info.address}</TableCell>
                                                        <TableCell align="right">{item.info.contact}</TableCell>
                                                        <TableCell align="right">{item.info.phone}</TableCell>
                                                        <TableCell align="right">
                                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => openEditModal(item, index)}>
                                                                查看或更改
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>

                            </Grid>
                        </Grid>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                        <ToastContainer />
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container>
                                        <Grid item md={8} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="供应商名称"
                                                placeholder="供应商名称"
                                                value={c_name}
                                                onChange={(e) => setC_name(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="地址"
                                                placeholder="地址"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="联系人"
                                                placeholder="联系人"
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="电话号码"
                                                placeholder="电话号码"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <input accept="image/*" type="file" onChange={imageChange} id="main-image" style={{ display: 'none' }} />
                                            <label htmlFor="main-image">
                                                <IconButton className="erp-ml-10" variant="contained" component="span" >
                                                    <FileUploadIcon className="erp-primary-color-btn-icon" /><span className="erp-font-s">资质上传</span>
                                                </IconButton>
                                            </label>
                                            {/* <img src={proImg != null ? URL.createObjectURL(proImg) : url = !"" ? url : ""} className="main-image-item" alt="thumb" /> */}

                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="主力产品"
                                                placeholder="主力产品"
                                                value={product}
                                                onChange={(e) => setProduct(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="property">产品属性</InputLabel>
                                                    <Select
                                                        labelId="property"
                                                        value={property}
                                                        label="property"
                                                        onChange={changeProProperty}
                                                    >
                                                        {
                                                            attributesERP.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="paycycle">付款周期</InputLabel>
                                                    <Select
                                                        labelId="paycycle"
                                                        value={payCycle}
                                                        label="paycycle"
                                                        onChange={changePayCycle}
                                                    >
                                                        <MenuItem value={1} className="erp-ml-10 erp-sel">1 月</MenuItem>
                                                        <MenuItem value={2} className="erp-ml-10 erp-sel">2 月</MenuItem>
                                                        <MenuItem value={3} className="erp-ml-10 erp-sel">3 月</MenuItem>
                                                        <MenuItem value={4} className="erp-ml-10 erp-sel">4 月</MenuItem>
                                                        <MenuItem value={5} className="erp-ml-10 erp-sel">5 月</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {editMode == 1 ?
                                    <Button variant="outlined" onClick={() => deleteSupplier()} >
                                        删除
                                    </Button> : <></>
                                }
                                <Button variant="outlined" onClick={() => confirmEdit()}>
                                    添加并审核
                                </Button>
                                <Button variant="outlined" onClick={() => setOpen(false)} >
                                    取消
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
        </Container>
    );
}
export default SupplyChain;