import firebase from "firebase";
  
const firebaseConfig = {
  apiKey: "AIzaSyDXc7ivtnEL3DCP0bjVMKagIuW1BTscgb0",
  authDomain: "vixen-c072d.firebaseapp.com",
  projectId: "vixen-c072d",
  storageBucket: "vixen-c072d.appspot.com",
  messagingSenderId: "144979695789",
  appId: "1:144979695789:web:c5a687ed1958ec1cea4e24"
};
 
// Initialize Firebase

let instance  = firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  timestampsInSnapshots: true
})

export const myFirebase = firebase;
export const myFirestore = firebase.firestore();
export const myStorage = firebase.storage();

const FirebaseInstance = ()=>{  
  if (typeof window !== "undefined") {
    if (instance) return instance;
    
    return instance;
  }
}  
export default FirebaseInstance;


