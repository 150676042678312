import React, {useEffect, useState} from "react";  
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './it.css';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete'; 
import AddBoxIcon from '@mui/icons-material/AddBox';
import TextField from '@mui/material/TextField';
import { Button, LinearProgress} from '@material-ui/core';
import axios from 'axios'; 
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function Home(props) {
  
  
  useEffect(() => {   
     
  }, []);  

  const notify_success = () =>  toast.success('Successfully  updated!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notify_fail = () =>  toast.error('Update failed!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });  

  return (  
        <Container maxWidth="xl"  className={'profile-container'}> 
          
        </Container>  
         
  );
} 
export default Home;

