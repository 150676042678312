import React, {useEffect} from "react";   
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import BannerBackImg from '../../../images/vixen_profile_back.jpg';   
  

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,  
    backgroundImage: `url(${BannerBackImg})`,
    paddingTop: '70px',
    paddingBottom: '50px',
    minHeight:'200px', 
  },   
}));

export default function TitleBanner(props) {
  const classes = useStyles();  

  useEffect(() => {   
  }, [props]);    
   
  return (
    <div className={classes.root}>
    <Container  maxWidth="lg">
      <Grid  container spacing={3}> 
            <Grid  item xs={12} md={3}>
                <p className={"profileTitle"}>{props.title}</p>
            </Grid>     
      </Grid>
      </Container>
    </div>
  );
}
