import React, { useEffect, useState } from "react"; 
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search'; 
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'; 
import Box from '@mui/material/Box'; 
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
} 

function Customer() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const s3_prefix = process.env.REACT_APP_S3_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [customerList, setCustomerList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0);

    const [id, setId] = useState("");
    const [c_name, setC_name] = useState("");
    const [cType, setCType] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [c_type, setC_type] = useState('1')

    useEffect(() => {
        setLoaded(true)
        axios.get(api_prefix + '/customer')
            .then(res => {
                var resData = res.data;
                var temp = [...customerList];
                resData.forEach((item) => {
                    temp.push(item)
                })
                setCustomerList(temp);
                setLoaded(false)
            }).catch((error) => {
            });
    }, []);

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const handleTab = (event, newValue) => {
        setTabValue(newValue);
        setC_type(newValue);
    };

    const createCustomer = async () => {
        if (c_name === "" || address === "" || contact === "" || phone === "") {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var data = {
            c_name,
            info: {
                cType,
                address,
                contact,
                phone,
                c_type
            }
        }
        axios.post(api_prefix + '/customer', data)
            .then(res => {
                var temp = [...customerList];
                temp.push(res.data)
                setCustomerList(temp);
                notify_success("创建成功");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("未创建！ 检查您的网络");
            });
    }

    const editCustomer = async () => {
        if (c_name === "" || address === "" || contact === "" || phone === "") {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var data = {
            id,
            c_name,
            info: {
                cType,
                address,
                contact,
                phone,
                c_type
            }
        }
        axios.put(api_prefix + '/customer', data)
            .then(res => {
                var temp = [...customerList];
                temp[editIndex] = data;
                setCustomerList(temp);
                notify_success("成功更新");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const deleteSupplier = () => {
        setLoaded(true)
        axios.delete(api_prefix + '/customer/' + id)
            .then(res => {
                var temp = [...customerList];
                temp.splice(editIndex, 1);
                setCustomerList(temp);
                notify_success("成功删除");
                setLoaded(false);
                setOpen(false);
            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const openCreateModal = () => {
        setId("");
        setC_name("");
        setAddress("");
        setContact("");
        setPhone("");
        setCType("");
        setEditIndex(0);
        setEditMode(0);
        setOpen(true)
    }
    const openEditModal = (item, index) => {
        setId(item.id);
        setC_name(item.c_name);
        setAddress(item.info.address);
        setContact(item.info.contact);
        setPhone(item.info.phone);
        setTabValue(item.info.c_type);
        setC_type(item.info.c_type);
        setEditIndex(index);
        setEditMode(1);
        setOpen(true);
    }

    const confirmEdit = () => {
        if (editMode === 0) {
            createCustomer();
        } else {
            editCustomer();
        }
    }



    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="xl" className="sample-scope" >
                        <Grid container spacing={3}>
                            {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                            <Grid item xs={12} md={12}>
                                <OutlinedInput
                                    endAdornment={<IconButton><SearchIcon /></IconButton>}
                                    value={searchVal}
                                    onChange={handleSearch}
                                />
                                <IconButton className="erp-ml-10" onClick={() => openCreateModal()}>
                                    <AddCircleIcon className="erp-primary-color-btn-icon" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>供应商名称</TableCell>
                                                <TableCell align="right">地址</TableCell>
                                                <TableCell align="right">联系人</TableCell>
                                                <TableCell align="right">电话号码</TableCell>
                                                <TableCell align="right">明细</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {customerList.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={item.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{item.c_name}</TableCell>
                                                        <TableCell align="right">{item.info.address}</TableCell>
                                                        <TableCell align="right">{item.info.contact}</TableCell>
                                                        <TableCell align="right">{item.info.phone}</TableCell>
                                                        <TableCell align="right">
                                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => openEditModal(item, index)}>
                                                                查看或更改
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>

                            </Grid>
                        </Grid>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                        <ToastContainer />
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <TabContext value={tabValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTab} aria-label="lab API tabs example">
                                                <Tab label="公司" value="1" />
                                                <Tab label="个人" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Grid container>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="公司名称"
                                                        placeholder="公司名称"
                                                        value={c_name}
                                                        onChange={(e) => setC_name(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="company-type">公司性质</InputLabel>
                                                            <Select
                                                                labelId="company-type"
                                                                id="company-type"
                                                                value={cType}
                                                                label="company"
                                                                onChange={(e) => setCType(e.target.value)}
                                                            >
                                                                <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                                                <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                                                <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="联系人"
                                                        placeholder="联系人"
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="电话"
                                                        placeholder="电话"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="地址"
                                                        placeholder="地址"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <Grid container>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="公司名称"
                                                        placeholder="公司名称"
                                                        value={c_name}
                                                        onChange={(e) => setC_name(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="company-type">公司性质</InputLabel>
                                                            <Select
                                                                labelId="company-type"
                                                                id="company-type"
                                                                value={cType}
                                                                label="company"
                                                                onChange={(e) => setCType(e.target.value)}
                                                            >
                                                                <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                                                <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                                                <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="联系人"
                                                        placeholder="联系人"
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="电话"
                                                        placeholder="电话"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="地址"
                                                        placeholder="地址"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {editMode == 1 ?
                                    <Button variant="outlined" onClick={() => deleteSupplier()} >
                                        删除
                                    </Button> : <></>
                                }
                                <Button variant="outlined" onClick={() => setOpen(false)} >
                                    取消
                                </Button>
                                <Button variant="outlined" onClick={() => confirmEdit()} >
                                    确认
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Customer;