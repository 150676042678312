import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import glass from './glass.jpg';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../it.css';
import { Button, Divider, LinearProgress } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { PDFViewer } from '@react-pdf/renderer';
const imageToBase64 = require('image-to-base64');

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function SearchBox() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const boxCategory = useSelector((state) => state.status.setBoxTags);
    const legCategory = useSelector((state) => state.status.setLegTags);

    const [categoryData, setCategoryData] = useState({});
    const [checkeded, setCheckeded] = React.useState({});
    const [checked, setChecked] = React.useState({});
    const [ptags, setPtags] = useState([]);

    const [boxCat, setBoxCat] = useState({});
    const [legCat, setLegCat] = useState({});
    const [mCat, setMCat] = useState([]);
    const [sCat, setSCat] = useState([]);
    const [scat, setScat] = useState("");
    const [mcat, setMcat] = useState("");
    const [searchMode, setSearchMode] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [pstate, setPstate] = useState(0);
    const [sampleItems, setSampleItems] = useState([]);
    const [selTag, setSelTag] = useState({});
    const [popImg, setPopImg] = useState("");
    const [tags, setTags] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [rsel, setRsel] = useState([]);
    const [selId, setSelId] = useState("");
    const [sload, selSload] = useState(false);
    const [matchObj, setMatchObj] = useState({});

    const [pdf, setPdf] = useState(1);
    const [pdfMurl, setPdfMurl] = useState("");
    const [pdfSurl, setPdfSurl] = useState("");

    useEffect(() => {
        setBoxCat(boxCategory);
        setLegCat(legCategory);
        setSelTag(legCategory)
        if (typeof boxCategory == 'object') {
            if (Object.keys(boxCategory).length > 0) {
                var mTemp = [];
                boxCategory && Object.keys(boxCategory).map((keys, indexs) => {
                    mTemp.push(keys);
                });
                setMCat(mTemp);
                var temp = {};
                legCategory && Object.keys(legCategory).map((keys, indexs) => {
                    legCategory[keys].forEach((item, index) => {
                        var tp = item + ":" + keys;
                        temp[tp] = false;
                    });
                });
                setChecked(temp);
                var sTemp = [];
                boxCategory[mTemp[0]].forEach((item) => {
                    sTemp.push(item);
                })
                setSCat(sTemp);
            }
            setLoaded(true);
        }
    }, [boxCategory, legCategory]);


    useEffect(() => {
        setCategoryData(boxCategory);
        if (typeof boxCategory == 'object') {
            var temp = {};
            boxCategory && Object.keys(boxCategory).map((keys, indexs) => {
                boxCategory[keys].forEach((item, index) => {
                    var tp = item + ":" + keys;
                    temp[tp] = false;
                });
            });
            setCheckeded(temp);
        }
    }, [boxCategory]);

    const selectMode = (mode) => { 
        if(mode == 0){
            setSearchMode(0);
            setCategoryData(boxCategory);
            if (typeof boxCategory == 'object') {
                var temp = {};
                boxCategory && Object.keys(boxCategory).map((keys, indexs) => {
                    boxCategory[keys].forEach((item, index) => {
                        var tp = item + ":" + keys;
                        temp[tp] = false;
                    });
                });
                setSelTag(legCategory)
                setCheckeded(temp);
            }
        }else{
            setSearchMode(1);
            setCategoryData(legCategory);
            if (typeof legCategory == 'object') {
                var temp = {};
                legCategory && Object.keys(legCategory).map((keys, indexs) => {
                    legCategory[keys].forEach((item, index) => {
                        var tp = item + ":" + keys;
                        temp[tp] = false;
                    });
                });
                setSelTag(boxCategory)
                setCheckeded(temp);
            }
        } 
    }

    const selMtag = (e) => {
        setMcat(e.target.value);
        var sTemp = [];
        if (searchMode == 0) {
            boxCat[e.target.value].forEach((item) => {
                sTemp.push(item);
            })
        } else {
            legCat[e.target.value].forEach((item) => {
                sTemp.push(item);
            })
        }
        setSCat(sTemp);
    }
    const selStag = (e) => {
        setScat(e.target.value);
    }

    const matchItems = () => { 
        selSload(true); 
        var data = {
            mode: 3,
            tags: ptags
        }

        if (searchMode === 0) { 
            axios.patch(api_prefix + "/frame", data)
                .then((response) => {
                    setSampleItems(response.data);
                    setPstate(1);
                    var temp = [];
                    response.data.forEach(() => {
                        temp.push(false);
                    });
                    setRsel(temp);
                    selSload(false);
                })
                .catch((error) => {
                    selSload(false);
                    throw (error);
                })
        } else {
            axios.patch(api_prefix + "/leg", data)
                .then((response) => {
                    setSampleItems(response.data);
                    setPstate(1);
                    var temp = [];
                    response.data.forEach(() => {
                        temp.push(false);
                    });
                    setRsel(temp);
                    selSload(false);
                })
                .catch((error) => {
                    selSload(false);
                    throw (error);
                })
        }
    }

    const handleChangeSel = (event, index) => {
        var temp = [...rsel];
        var ch = temp[index];
        temp.forEach((t, i) => {
            temp[i] = false;
        })
        temp[index] = !ch;
        setRsel(temp);
        if (ch) {
            setSelId("");
        } else {
            setSelId(event.target.value)
        }
    };
    const handleClickOpen = (img) => {
        setPopImg(img)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeCheck = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setTags(temps);
    }

    const searchAI = () => {
        var data = {
            id: selId,
            tags: tags,
            mode: searchMode === 0 ? "0" : "1"
        }
        selSload(true);
        axios.patch(api_prefix + "/assemble", data)
            .then((response) => {
                setPstate(2);
                selSload(false);
                setMatchObj(response.data);
            })
            .catch((error) => {
                selSload(false);
                throw (error);
            })
    }

    const downPdf = (item) => {
        var data = {
            id: item.id,
            url: item.surl,
            surl: item.url,
            mode: "1"
        }
        selSload(true);
        axios.post(api_prefix + "/pdf", data)
            .then((response) => {
                let filename = "hindar-optical.pdf";
                selSload(false);
                let pdfUrl = response.data;
                const axios = require('axios');
                axios({
                    method: "get",
                    url: pdfUrl,
                    responseType: "arraybuffer"
                })
                    .then((response) => {
                        var link = document.createElement("a");
                        link.href = window.URL.createObjectURL(
                            new Blob([response.data], { type: "application/octet-stream" })
                        );
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(function () {
                            window.URL.revokeObjectURL(link);
                        }, 200);
                    })
                    .catch((error) => { });
            })
            .catch((error) => {
                selSload(false);
                throw (error);
            })

    }

    const goBack = () => {
        setPstate(1);
    }
    const goPdfBack = () => {
        setPdf(1);
    }

    const handleChange = (e, item, mk) => {
        var temp = { ...checkeded };
        temp[`${item}:${mk}`] = e.target.checked;
        setCheckeded(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setPtags(temps);
    }

    return (
        <>
            {
                pstate === 0 ?
                    <Grid item xs={12} md={12} className="left-scopre">
                        <Grid container spacing={3}>
                            {
                                sload ?
                                    <Grid item xs={12} md={12}>
                                        <LinearProgress />
                                    </Grid>
                                    :
                                    <></>
                            }
                            <Grid item xs={12} md={6} className="a-center">
                                <Button variant="contained" className={searchMode == 0 ? "primary-color" : "disable-color"} onClick={() => selectMode(0)}>从框开始设计</Button>
                            </Grid>
                            <Grid item xs={12} md={6} className="a-center">
                                <Button variant="contained" className={searchMode == 1 ? "primary-color" : "disable-color"} onClick={() => selectMode(1)}>从腿开始设计</Button>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>
                             
                            <Grid item md={8} xs={12}>
                                {
                                    categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                        <>
                                            <div className="check-box-mux">
                                                <p className="check-label">{keys} :</p>
                                                {categoryData[keys].map((item, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            className="check-box-wrap"
                                                            control={
                                                                <Checkbox defaultChecked
                                                                    checked={checkeded[`${item}:${keys}`]}
                                                                    onChange={(e) => handleChange(e, item, keys)}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            }
                                                            label={`${item}`}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <Divider className="my-divider" />
                                        </>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12} md={12} className="a-right">
                                <Button variant="contained" className="primary-color" onClick={matchItems}>开始</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    pstate === 1 ?
                        <>
                            <Grid item xs={12} md={9} className="left-scope">
                                <Grid container spacing={3}>
                                    {
                                        sload ?
                                            <Grid item xs={12} md={12}>
                                                <LinearProgress />
                                            </Grid>
                                            :
                                            <></>
                                    }
                                    {
                                        sampleItems.length > 0 ?
                                            sampleItems.map((item, index) => {
                                                return (
                                                    <Grid item xs={12} md={4}>
                                                        <div className="item-wrap">
                                                            <div className="item-img-wrap">
                                                                <img src={searchMode == 0 ? item.surl : item.murl} alt="" className="item-img" />
                                                                <div className="hover-wrap" onClick={() => handleClickOpen(searchMode == 0 ? item.surl : item.url)}>
                                                                    {/* <ZoomInIcon className="grey-color-btn-icon" /> */}
                                                                </div>
                                                            </div>
                                                            <div className="in-row">
                                                                <input type="radio"
                                                                    checked={rsel[index]}
                                                                    onClick={(e) => handleChangeSel(e, index)}
                                                                    value={item.id}
                                                                />
                                                                <p className="item-label">款号：{item.angle}-{item.len}</p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            }) :
                                            <Grid item xs={12} md={4}>
                                                <h3> 没有结果</h3>
                                            </Grid>
                                    }
                                </Grid>
                                <Dialog
                                    fullWidth={true}
                                    maxWidth={"md"}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <DialogTitle className="my-font-b">详细视图</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <img src={popImg} alt="" className="item-img-zoom" />
                                        </DialogContentText>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="my-font-b" onClick={handleClose}>关闭</Button>
                                    </DialogActions>
                                </Dialog>

                            </Grid>
                            <Grid item xs={12} md={3} className="right-scope">
                                {
                                    loaded ?
                                        <div className='item-area-ai'>
                                            {
                                                selTag && Object.keys(selTag).map((keys, indexs) => (
                                                    <>
                                                        <div className="check-box-mux">
                                                            <p className="check-label">{keys} :</p>
                                                            {selTag[keys].map((item, index) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        className="check-box-wrap"
                                                                        control={
                                                                            <Checkbox checked={checked[`${item}:${keys}`]}
                                                                                onChange={(e) => handleChangeCheck(e, item, keys)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        }
                                                                        label={`${item}`}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                        <Divider className="my-divider" />
                                                    </>
                                                ))
                                            }
                                        </div> :
                                        <Grid item xs={12} md={12}>
                                            <p>没有结果</p>
                                        </Grid>
                                }

                                <div className='btn-area'>
                                    <Button variant="contained" className='primary-color primary-width primary-tm' onClick={searchAI}>AI设计</Button>
                                    <Button variant="contained" className='primary-color primary-width primary-tm ml-10' onClick={()=>setPstate(0)}>回去</Button>
                                </div>

                            </Grid>
                        </> :
                        <>
                            <Grid item xs={12} md={12} className="match-scope">
                                <Grid container spacing={3}>
                                    {
                                        sload ?
                                            <Grid item xs={12} md={12}>
                                                <LinearProgress />
                                            </Grid>
                                            :
                                            <></>
                                    }
                                    {
                                        matchObj.length > 0 ?
                                            matchObj.map((item, index) => {
                                                return (
                                                    <Grid item xs={12} md={4}>
                                                        <div className="item-wrap">
                                                            <div className="item-img-wrap">
                                                                <img src={item.url} alt="" className="item-img" />
                                                                <img src={item.surl} alt="" className="item-img" />
                                                                <div className="hover-wrap" onClick={() => handleClickOpen(item.url)}>
                                                                    {/* <ZoomInIcon className="grey-color-btn-icon" /> */}
                                                                </div>
                                                            </div>
                                                            <div className='btn-down-area'>
                                                                <Button variant="contained" className='primary-color primary-width primary-tm' onClick={() => downPdf(item)}>查看</Button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            }) :
                                            <Grid item xs={12} md={12}>
                                                <p>没有结果</p>
                                            </Grid>
                                    }
                                    <Grid item xs={12} md={12}>
                                        <div className='btn-area align-r'>
                                            <Button variant="contained" className='primary-color primary-width primary-tm' onClick={goBack}>回去</Button>
                                        </div>
                                    </Grid>

                                </Grid>
                                <Dialog
                                    fullWidth={true}
                                    maxWidth={"md"}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <DialogTitle className="my-font-b">详细视图</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <img src={popImg} alt="" className="item-img-zoom" />
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="my-font-b" onClick={handleClose}>关闭</Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </>

            }
        </>

    );
}

export default SearchBox;
