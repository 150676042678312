import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Button, LinearProgress } from '@material-ui/core';

import Badge from '@mui/material/Badge';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Navbar from "../components/Global/Header/Nav"; 


function HomePage() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    let history = useHistory(); 
    const user = useSelector((state) => state.user.signInfo);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const home = () => {
        history.push("/");
    }

    useEffect(() => {
        console.log(">>>>>USER", user)
    }, [user]);

    const setPage = () => {
        // dispatch(setErpPageIndex(""))
    }

    const move_page = (url) => {
        history.push("/admin" + url);
    }

    return (
        <>
            <Navbar />
            <Container maxWidth="lg" className='search-scope'>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Divider className="erp-divider-m" />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" id="search_product" onClick={() => history.push('/search_product')}>搜索产品</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" id="match_product" onClick={() => history.push('/match_product')}>匹配产品</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Divider className="erp-divider-m" />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/sample_manage") }}>样品管理</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/ai_design") }}>人工智能设计</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/data_entry") }}>资料录入</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/supply_chain") }}>供应商</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/customer_manage") }}>客户资料</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" className="primary-color" onClick={() => { move_page("/order_manage") }}>订单管理</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Divider className="erp-divider-m" />
                    </Grid>
                    <Grid item md={12} xs={12} className="erp-right">
                        <Button variant="contained" className="primary-color erp-ml-10" onClick={() => history.push('/sign_in')}>登入</Button>
                        <Button variant="contained" className="primary-color erp-ml-10" onClick={() => history.push('/sign_up')}>报名</Button>

                    </Grid>


                </Grid>
            </Container>
        </>
    );
}

export default HomePage;
