import React, {useEffect, useState} from "react";   
import {BrowserRouter as Redirect} from "react-router-dom"; 

export default function AuthRoute(props){
    const [admin, setAdmin] = useState(false);   
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {   
        let user = JSON.parse(localStorage.getItem("user"));  
        if(user != null && user.userRole === "0"){
            setAdmin(true);
            console.log('admin')
        }else{
 
        } 
        setLoaded(true); 
        setAdmin(true);
    }, []);      
  return (
    <>{
        loaded?
        admin?
        props.children: 
        <Redirect to="/" />:<></>
      }
    </>
  );
}