import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
function AccessDeny(props) {
    useEffect(() => {
    }, []);
    return (
        <Container maxWidth="xl" className="user-scope">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h2>您没有此页面的权限.</h2>
                </Grid>
            </Grid>
        </Container>

    );
}
export default AccessDeny;

