import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import './Dashboard.css'
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MainListItems from '../Dashboard/ListItem';
import UserList from './UserList';
import Home from './Home/Home';
import SampleManage from "./SampleManage/SampleManage";
import AiDesign from "./AiDesign/AiDesign";
import Ecommerce from "./Ecommerce/Ecommerce";
import Order from "./Order/Order";
import Customer from "./Customer/Customer";
import DataEntry from "./DataEntry/DataEntry";
import SupplyChain from "./SupplyChain/SupplyChain";
import AuditRecord from "./AuditRecord/AuditRecord";
import OrderDeal from "./OrderDeal/OrderDeal";
import { useSelector } from 'react-redux';
import AccessDeny from "./AccessDeny";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const user = useSelector((state) => state.user.signInfo);
  let history = useHistory();
  const [open, setOpen] = useState(true);
  const [role, setRole] = useState(undefined);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {

    console.log(">>>>Drouter user ", user);
    if (user != null) {
      setRole(user.user.e_roles);
    }

  }, [user])

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
              sx={{ marginRight: '36px', ...(open && { display: 'none' }), }}>
              <MenuIcon />
            </IconButton>
            <Typography className={"dashboard-header"} component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} >
              纯音科技管理系统
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1], }} >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <MainListItems />
        </Drawer>
        <Box component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
          id="dash-box"
        >
          <Toolbar />
          <div className={"container-wrap"}>
            <Switch>
              <Route exact path="/admin" component={Home} />
              {role && <Route path="/admin/sample_manage" component={role.includes('图库管理') || role.includes('全部') ? SampleManage : AccessDeny} />}
              {role && <Route path="/admin/ai_design" component={role && role.includes('AI设计') || role.includes('全部') ? AiDesign : AccessDeny} />}
              {role && <Route path="/admin/users_manage" component={role && role.includes('账户管理') || role.includes('全部') ? UserList : AccessDeny} />}
              <Route path="/admin/ecommerce_manage" component={Ecommerce} />
              <Route path="/admin/order_manage" component={Order} />
              <Route path="/admin/customer_manage" component={Customer} />
              <Route path="/admin/data_entry" component={DataEntry} />
              <Route path="/admin/supply_chain" component={SupplyChain} />
              <Route path="/admin/audit_record" component={AuditRecord} />
              <Route path="/admin/order_deal" component={OrderDeal} />
            </Switch>
          </div>
        </Box>
      </Box>

    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}