import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import Jimp from 'jimp';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import './it.css';
import Radio from '@mui/material/Radio';
// import calls from './custom.js'

function FrameEdit() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const category = useSelector((state) => state.status.setBoxTags);
    const user = useSelector((state) => state.user.signInfo);
    const [categoryData, setCategoryData] = useState({});
    const [open, setOpen] = React.useState(false);
    const [uid, setUid] = useState("");
    const [angle, setAngle] = useState(0);
    const [len, setLen] = useState(0);
    const [tags, setTags] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selectedImageMain, setSelectedImageMain] = useState(null);
    const [murl, setMurl] = useState(null);
    const [surl, setSurl] = useState(null);
    const [optimizeImg, setOptimizeImg] = useState(null);
    const [selectedImageSub, setSelectedImageSub] = useState(null);
    const [checked, setChecked] = React.useState({});
    const [upcnt, setUpcnt] = useState(0);
    var sTemp = "";
    var mTemp = "";
    var callCnt = 0;

    const [point1, setPoint1] = useState([0, 0]);
    const [point2, setPoint2] = useState([0, 0]);
    const [selPoint, setSelPoint] = useState([1, 0]);
    const [selectedValue, setSelectedValue] = React.useState('a');

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);
    const [success, setSuccess] = useState(false);
    const [frameList, setFrameList] = useState([]);

    const [isView, setIsView] = useState(true);
    const [eidtItem, setEditItem] = useState(null);
    const [eidtIndex, setEditIndex] = useState(0);

    const editItems = (item, index) => {
        setUid(item.id);
        setEditIndex(index);
        setAngle(item.angle);
        setLen(item.len)
        var temp = { ...checked };
        var t_gtag = item.tags;
        Object.keys(temp).forEach((i) => {
            if (t_gtag.includes(i)) {
                temp[i] = true;
            }
        });
        setChecked(temp);
        setIsView(false);
        setMurl(item.murl)
        setPoint1(item.point[0]);
        setPoint2(item.point[1]);
        setSurl(item.surl)
    }

    const confirmUpload = () => {
        setSuccess(false);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
        setAngle("");
        setLen("")
        setTags([]);
        setSelectedImageMain(null);
        setSelectedImageSub(null);
        setOptimizeImg(null);
        setUpcnt(0);
        setPoint1([0, 0]);
        setPoint2([0, 0]);
        setSelectedValue('a');
        setSelPoint([1, 0]);
        sTemp = "";
        mTemp = "";
        callCnt = 0;
    }

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
    };

    var c = null;
    var ctx = null;
    var zoomR = 0.5;
    let framePoint = [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]];
    var paddingVal = 20;



    useEffect(() => {
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if (roles.includes('图库管理') || roles.includes('全部')) {
                    setHasRole(true);
                }
                setLoaded(true);
                var data = {
                    mode: 2,
                    c_id: user.user.id,
                }
                axios.patch(api_prefix + "/frame", data)
                    .then((res) => {
                        setLoaded(false);
                        console.log(">>>>>frame", res)
                        setFrameList(res.data);
                    })
                    .catch((error) => {
                        throw (error);
                    })
            } else {

            }
        } else {
            //history.push("/sign_in");
        }

    }, [user])

    useEffect(() => {
        setCategoryData(category);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
    }, [category]);

    useEffect(() => {
        if (isView == false) {
            c = document.getElementById("myCanvas");
            ctx = c.getContext("2d");
            var z = document.getElementById("zoomCanvas");
            var zCtx = z.getContext("2d");
            let rect = c.getBoundingClientRect();
            z.width = 200;
            z.height = 200;
            c.addEventListener("mousemove", function (e) {
                var sT = window;
                zCtx.fillStyle = "white";
                zCtx.fillRect(0, 0, 200, 200);
                zCtx.drawImage(c, e.offsetX / zoomR - 100, e.offsetY / zoomR - 100, 200, 200, 0, 0, 200, 200);
                z.style.top = 0 + 'px';
                z.style.left = 0 + 'px';
                zCtx.beginPath()
                zCtx.moveTo(100, 0);
                zCtx.lineTo(100, 200);
                zCtx.moveTo(0, 100);
                zCtx.lineTo(200, 100);
                zCtx.lineWidth = 1;
                zCtx.strokeStyle = '#000';
                zCtx.stroke();
                z.style.zoom = 1;
                z.style.display = 'block';
            });
            c.addEventListener("mouseout", function () {
                z.style.display = 'none';
            });
        }

    }, [murl])

    useEffect(() => {
        if (isView == false) {
            c = document.getElementById("myCanvas");
            c.removeEventListener("mousedown", act);
            function act(e) {
                let rect = c.getBoundingClientRect();
                let x = e.offsetX / zoomR;
                let y = e.offsetY / zoomR;
                //console.log(">>>pos", e.offsetX / zoomR, "--", e.offsetY / zoomR);
                var cha = document.getElementById("ch-a");
                var chb = document.getElementById("ch-b");
                if (cha.checked) {
                    setPoint1([x, y]);
                }
                if (chb.checked) {
                    setPoint2([x, y]);
                }
            }
            c.addEventListener("mousedown", act);
        }

    }, [murl]);

    useEffect(() => {
        if (isView == false) {
            Jimp.read(murl)
                .then(img => {
                    var imgHeight = img.bitmap.height;
                    var imgWidth = img.bitmap.width;
                    var maxX = 0;
                    var minX = imgWidth;
                    var maxY = 0;
                    var minY = imgHeight;

                    for (var x = 10; x < imgWidth - 10; x++) {
                        for (var y = 10; y < imgHeight - 10; y++) {
                            var colorVal = img.getPixelColor(x, y);
                            var rgba = Jimp.intToRGBA(colorVal);
                            if (colorVal != 0 && rgba["a"] > 150) {
                                //  find the 2 point
                                if (x < minX) {
                                    minX = x;
                                    framePoint[2][0] = minX;
                                    framePoint[2][1] = y;
                                }
                                // find the 0 point
                                if (x > maxX) {
                                    maxX = x;
                                    framePoint[0][0] = maxX;
                                    framePoint[0][1] = y;
                                }
                                // find the 3 point
                                if (y < minY) {
                                    minY = y;
                                    framePoint[3][0] = x;
                                    framePoint[3][1] = minY;
                                }
                                // find the 1 point
                                if (y > maxY) {
                                    maxY = y;
                                    framePoint[1][0] = x;
                                    framePoint[1][1] = maxY;
                                }
                            }
                        }
                    }

                    var newWidth = framePoint[0][0] - framePoint[2][0];
                    var newHeight = framePoint[1][1] - framePoint[3][1];
                    let transparentImg = new Jimp(newWidth + paddingVal * 2, newHeight + paddingVal * 2, '#00000000');
                    img.crop(framePoint[2][0], framePoint[3][1], framePoint[0][0] - framePoint[2][0], framePoint[1][1] - framePoint[3][1]);
                    var imgFrame = transparentImg.blit(img, paddingVal, paddingVal);

                    const WIDTH = imgFrame.bitmap.width;
                    const HEIGHT = imgFrame.bitmap.height;
                    c.width = WIDTH;
                    c.height = HEIGHT;
                    const arrayBuffer = new ArrayBuffer(WIDTH * HEIGHT * 4);
                    const pixels = new Uint8ClampedArray(arrayBuffer);
                    for (var y = 0; y < HEIGHT; y++) {
                        for (var x = 0; x < WIDTH; x++) {
                            const i = (y * WIDTH + x) * 4;
                            var colorVal = imgFrame.getPixelColor(x, y);
                            var rgba = Jimp.intToRGBA(colorVal);
                            pixels[i] = rgba["r"];   // red
                            pixels[i + 1] = rgba["g"];   // green
                            pixels[i + 2] = rgba["b"];   // blue
                            pixels[i + 3] = rgba["a"]; // alpha 
                        }
                    }
                    const imageData = new ImageData(pixels, WIDTH, HEIGHT);
                    c.style.zoom = zoomR;
                    ctx.putImageData(imageData, 0, 0);
                    c = document.getElementById("myCanvas");
                    c.style.border = 'solid 1px #6262626b';
                    var dataURL = c.toDataURL();
                    setOptimizeImg(dataURL);
                })
                .catch(err => {

                });
        }
    }, [murl, isView])

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const imageChangeMain = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImageMain(e.target.files[0]);
            var t_url = URL.createObjectURL(e.target.files[0]);
            setMurl(t_url);
            var cnt = upcnt + 1;
            setUpcnt(cnt);
        }
    }
    const imageChangeSub = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImageSub(e.target.files[0]);
            var t_url = URL.createObjectURL(e.target.files[0]);
            setSurl(t_url);
            var cnt = upcnt + 1;
            setUpcnt(cnt);
        }
    }
    const removeSelectedImageMain = () => {
        setSelectedImageMain(null);
        var cnt = upcnt - 1;
        setUpcnt(cnt);
    }
    const removeSelectedImageSub = () => {
        setSelectedImageSub(null);
        setSurl(null)
        var cnt = upcnt - 1;
        setUpcnt(cnt);
    }
    const handleChange = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setTags(temps);
    }
    const uploadFile = async (dir, names, proImg) => {
        let reader = new FileReader();
        await reader.readAsDataURL(proImg);
        reader.onload = function () {
            var data = {
                dir: dir,
                img: reader.result,
                imgName: names
            }
            axios.post(api_prefix + "/uploadImg", data)
                .then((res) => {
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }
    const updateData = () => {
        var mUrl = "";
        if (selectedImageMain == null) {
            mUrl = murl;
        } else {
            var fileName = Date.now();
            var dir = "frame";
            var n = selectedImageMain.name.split('.');
            var ext = n[n.length - 1];
            uploadFile(dir, fileName, selectedImageMain);
            mUrl = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
        }
        var sUrl = "";
        if (selectedImageSub == null) {
            sUrl = surl;
        } else {
            var fileName = Date.now();
            var dir = "frame";
            var n = selectedImageMain.name.split('.');
            var ext = n[n.length - 1];
            uploadFile(dir, fileName, selectedImageMain);
            sUrl = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
        }
        var frameData = {
            id: uid,
            f_id: "frame",
            f_angle: angle,
            f_len: len,
            f_tags: tags,
            f_murl: mUrl,
            f_surl: sUrl,
            f_point: [point1, point2],
            extra: {
                creator
            }
        }
        axios.put(api_prefix + '/frame', frameData)
            .then(response => {
                console.log(">>res", response.data);
                setLoaded(false);
                notify_success("上传成功!");
                setSuccess(true);
                var data = {
                    tbody: response.data,
                    sel: 1,
                };
                axios.post(api_prefix + "/assemble", data).
                    then((res) => {
                    });
            }).catch((error) => {
                notify_fail("上传失败");
            });
        mTemp = "";
        sTemp = "";
        callCnt = 0;
    }

    const deleteLeg = () => {
        axios.delete(api_prefix + '/frame/' + uid)
            .then(response => {
                setLoaded(false);
                var temp = [...frameList];
                temp.splice(eidtIndex, 1);
                setFrameList(temp);
                setIsView(true);
                notify_success("上传成功!");
                setSuccess(true);
            }).catch((error) => {
                notify_fail("上传失败");
            });
    }

    const handleLen = (e) => {
        setLen(e.target.value);
    }
    const handleAngle = (e) => {
        setAngle(e.target.value);
    }

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ?
                    <Grid item md={12}>
                        <LinearProgress />
                    </Grid> :
                    <></>
                }
                {
                    isView ?
                        <Grid container spacing={3}>
                            {
                                frameList.length > 0 ?
                                    frameList.map((item, index) => {
                                        return (
                                            <Grid item xs={12} md={4}>
                                                <div className="item-wrap">
                                                    <div className="item-img-wrap">
                                                        <img src={item.surl} alt="" className="item-img" />
                                                        <div className="hover-wrap" >
                                                            {/* <ZoomInIcon className="grey-color-btn-icon" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="in-row erp-space-bet">
                                                        <p className="item-label">款号：{item.angle}-{item.len}</p>
                                                        <Button variant="contained" className="primary-color erp-edit-btn" onClick={() => editItems(item, index)}>查看和编辑</Button>

                                                    </div>
                                                </div>
                                            </Grid>
                                        );
                                    }) :
                                    <Grid item xs={12} md={4}>
                                        {loaded ? <h3>加载...</h3> : <h3> 没有结果</h3>}
                                    </Grid>
                            }
                        </Grid> :
                        <>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TextField
                                    label="桩头高度"
                                    placeholder="桩头高度"
                                    value={len}
                                    onChange={handleLen}
                                    type="number"
                                    InputProps={{ inputProps: { step: 0.01, max: 50, min: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TextField
                                    label="倾斜角度"
                                    placeholder="倾斜角度"
                                    value={angle}
                                    onChange={handleAngle}
                                    type="number"
                                    InputProps={{ inputProps: { step: 0.1, max: 50, min: -50 } }}
                                />
                            </Grid>
                            <Grid item xs={12} className="category-section">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2}>
                                        <Button variant="contained" endIcon={<AddIcon />} className="primary-color" onClick={() => handleClickOpen()}>标签</Button>
                                    </Grid>
                                    <Grid item xs={12} md={10} className={'row-wrap-g'}>
                                        {checked &&
                                            Object.keys(checked).map((item) => {
                                                return (
                                                    <>
                                                        {checked[item] === true ?
                                                            <div className="checked-item">{item}</div> : <></>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <input accept="image/*" type="file" onChange={imageChangeMain} id="main-image" style={{ display: 'none' }} />
                            <Grid item xs={12} className="main-img-section">
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={3}>
                                        <label htmlFor="main-image">
                                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">框侧面图</Button>
                                        </label>
                                        <p className="warn-msg">要求：透明底png格式</p>
                                        <div className="in-row">
                                            <Radio
                                                id="ch-a"
                                                checked={selectedValue === 'a'}
                                                onChange={handleChangeRadio}
                                                value="a"
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <p className="ml"> Point1 : {`${point1[0]}`}-{`${point1[1]}`}</p>
                                        </div>
                                        <div className="in-row">
                                            <Radio
                                                id="ch-b"
                                                checked={selectedValue === 'b'}
                                                onChange={handleChangeRadio}
                                                value="b"
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <p className="ml"> Point2 : {`${point2[0]}`}-{`${point2[1]}`}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} className={'row-wrap-g'}>
                                        {selectedImageMain ? <div className="image-wrap ">
                                            <canvas id="myCanvas" />
                                            <IconButton className="image-control-btn" onClick={removeSelectedImageMain}>
                                                <CancelRoundedIcon className="close-icon" />
                                            </IconButton>
                                        </div> : <canvas id="myCanvas" />}

                                    </Grid>
                                    <Grid item xs={12} md={5} className={'row-wrap-g'}>
                                        <canvas id="zoomCanvas" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <input accept="image/*" type="file" onChange={imageChangeSub} id="sub-image" style={{ display: 'none' }} />
                            <Grid item xs={12} className="sub-img-section">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3}>
                                        <label htmlFor="sub-image">
                                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">框正面图</Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={9} className={'row-wrap-g over-scroll'}>
                                        {
                                            surl != null ?
                                                <div className="image-wrap">
                                                    <img src={surl} className="main-image-item-frame" alt="thumb" />
                                                    <IconButton className="image-control-btn" onClick={removeSelectedImageSub}>
                                                        <CancelRoundedIcon className="close-icon" />
                                                    </IconButton>
                                                </div> : <></>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className={'row-wrap-g align-right'}>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={() => setIsView(true)}>取消</Button>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={() => deleteLeg()}>删除</Button>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={() => updateData()}>更新</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                }
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b">选择类别</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                <>
                                    <div className="check-box-mux">
                                        <p className="check-label">{keys} :</p>
                                        {categoryData[keys].map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    className="check-box-wrap"
                                                    control={
                                                        <Checkbox defaultChecked checked={checked[`${item}:${keys}`]}
                                                            onChange={(e) => handleChange(e, item, keys)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label={`${item}`}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Divider className="my-divider" />
                                </>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="my-font-b" onClick={handleClose}>确认</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={success}
                onClose={() => setSuccess(false)}
            >
                <DialogTitle className="my-font-b">通知 : 上传成功</DialogTitle>
                <DialogActions>
                    <Button className="my-font-b primary-color" onClick={() => confirmUpload()}>确认</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default FrameEdit;