import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setErpBoxTag, setErpLegTag, setErpSampleTags } from "../../data/Status/Action";
import { setSignInfo } from "../../data/User/Action";
import { callErpSupModel } from "../../data/Status/Action";

export default function TopWrap(props) {
  const dispatch = useDispatch();
  const api_prefix = process.env.REACT_APP_API_PREFIX;

  useEffect(() => {
    var session = localStorage.getItem("user");
    dispatch(setSignInfo(JSON.parse(session)));
    dispatch(callErpSupModel());
    
    axios.get(api_prefix + "/data/glassTag")
      .then((response) => { 
         dispatch(setErpSampleTags(response.data.eData));
      })
      .catch((error) => {
        throw (error);
      }); 
    axios.get(api_prefix + "/data/frameTag")
      .then((response) => {
        dispatch(setErpBoxTag(response.data.eData));
      })
      .catch((error) => {
        throw (error);
      });
    axios.get(api_prefix + "/data/legTag")
      .then((response) => { 
         dispatch(setErpLegTag(response.data.eData));
      })
      .catch((error) => {
        throw (error);
      }); 


    // axios.post("https://vnrefxuxd6.execute-api.us-east-2.amazonaws.com/dev/token")
    //   .then((response) => {
    //     const token = response.data.access_token;
    //     localStorage.setItem("token", token);
    //   })
    //   .catch((error) => {
    //     throw (error);
    //   })
  }, []);


  return (
    <>
      {props.children}
    </>
  );

}

