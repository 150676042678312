import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SideImg from "../images/network/wixen_signin_2.jpg";
import LogoImg from "../images/logoback.png";
import axios from 'axios';
import useInput from "../components/Global/Input";
import { useSelector, useDispatch } from 'react-redux';
import { getSignInfo } from '../data/User/Action';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
var md5 = require('md5');

function Copyright(props) {
  let history = useHistory();
  const home = () => {
    history.push("/");
  }

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link onClick={home} color="inherit" href="#">
        Hindar Optcal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const api_prefix = process.env.REACT_APP_API_PREFIX;
  let history = useHistory();

  const [info, setInfo] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");

  const sign_in = () => {
    history.push("/sign_in");
  }

  const email_sign_up = async () => {
    try {
      const user = {
        id: "",
        email: email,
        info: info,
        e_roles: [],
        pw: md5(pw)
      }
      if (email != "" && info != "" && pw != "") {
        axios.post(api_prefix + "/user", user)
          .then((res) => {
            if (res.status == 201) {
              notify_success("创建成功，请联系管理员验证.");
              history.push("/sign_in");
            }
            if (res.status == 202) {
              notify_warn("用户已经存在.");
            }
          })
          .catch((error) => {
            notify_fail("网络有问题.");
            throw (error);
          })
      } else {
        notify_warn("您必须填写所有信息")
      }

    } catch (e) {
      console.log("error", e);
    }

  }

  const notify_success = (msg) => toast.success(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notify_warn = (msg) => toast.warn(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notify_fail = (msg) => toast.error(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7}
          sx={{
            backgroundImage: `url(${SideImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center", }} >
            <img src={LogoImg} className={"sign_in_logo"} alt="vixen_logo" />
            <Box component="form" noValidate sx={{ mt: 1 }} >
              <TextField value={info} margin="normal" required fullWidth label="姓名" autoFocus onChange={(e) => setInfo(e.target.value)} />
              <TextField value={email} margin="normal" required fullWidth label="电话号码" autoComplete="email" onChange={(e) => setEmail(e.target.value)} />
              <TextField value={pw} margin="normal" required fullWidth label="密码" type="password" autoComplete="current-password" onChange={(e) => setPw(e.target.value)} />
              <Button onClick={email_sign_up} className={"vixen_button_pink"} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
                报名
              </Button>
              <Grid container>
                <Grid item>
                  <Link onClick={sign_in} variant="body2" className={"vixen_font have_account"}>
                    {"我已经有一个帐户. 登入."}
                  </Link>
                </Grid>
              </Grid>
              <Copyright className={"vixen_font"} sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <ToastContainer />
    </ThemeProvider>
  );
}
