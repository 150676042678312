import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setSignInfo } from '../../../../data/User/Action';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import './it.css';

function SampleUpload() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const category = useSelector((state) => state.status.setSampleTags);
    const user = useSelector((state) => state.user.signInfo);
    const dispatch = useDispatch();
    let history = useHistory();
    const [categoryData, setCategoryData] = useState({});
    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [selectedImageMain, setSelectedImageMain] = useState(null);
    const [selectedImageSub, setSelectedImageSub] = useState([]);
    const [checked, setChecked] = React.useState({});
    const [pid, setPid] = useState("");
    const [pname, setPname] = useState("");
    const [ptags, setPtags] = useState([]);
    const [murl, setMurl] = useState("");
    const [surl, setSurl] = useState([]);
    const [upcnt, setUpcnt] = useState(0);

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);

    const [success, setSuccess] = useState(false);

    const init = () => {
        setSelectedImageMain(null);
        setSelectedImageSub([]); 
        setPid("");
        setPname("")
        setPtags([]);
        setUpcnt(0);
    }

    var sTemps = [];
    var mTemp = "";
    var callCnt = 0;

    useEffect(() => {
        setCategoryData(category);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
    }, [category]);

    useEffect(() => { 
        var session = JSON.parse(localStorage.getItem("user"));
        dispatch(setSignInfo(session));
    }, [])
    useEffect(() => {
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if(roles.includes('图库管理')|| roles.includes('全部')){
                    setHasRole(true);
                }
            } else {
                
            }
        }else{
            //history.push("/sign_in");
        }
    }, [user])

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const imageChangeMain = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImageMain(e.target.files[0]);
            var cnt = upcnt + 1;
            setUpcnt(cnt);
        }
    }
    const imageChangeSub = (e) => {
        console.log(">>>>>>ENTERED IMAGE", e.target.files)
        var temp = [...selectedImageSub];
        var cnt = 0;
        if (e.target.files && e.target.files.length > 0) {
            for(var i = 0; i < e.target.files.length; i++){
                temp.push(e.target.files[i])
                cnt++;
            }
            // e.target.files.forEach((element)=>{
            //     temp.push(element)
            //     cnt++;
            // })
            // temp.push(e.target.files[0]);
            setSelectedImageSub(temp);
            var cnts = upcnt + cnt;
            setUpcnt(cnts);
        }
    }
    const removeSelectedImageMain = () => {
        setSelectedImageMain(null);
        var cnt = upcnt - 1;
        setUpcnt(cnt);
    }
    const removeSelectedImageSub = (index) => {
        var temp = [...selectedImageSub];
        temp.splice(index, 1);
        setSelectedImageSub(temp);
        var cnt = upcnt - 1;
        setUpcnt(cnt);
    }
    const handleChange = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setPtags(temps);
    }
    const handlePid = (e) => {
        setPid(e.target.value);
    }
    const handlePname = (e) => {
        setPname(e.target.value);
    }
    const uploadImgInterface = async (file, mode) => {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = function () {
            var data = {
                dir: 'glasses',
                img: reader.result
            }
            axios.post(api_prefix + "/upload", data)
                .then((response) => {
                    if (mode == 0) {
                        setMurl(response.data.Location);
                        mTemp = response.data.Location;
                        callCnt++;
                    } else {
                        sTemps.push(response.data.Location);
                        callCnt++;
                    }
                    if (callCnt == upcnt) {
                        saveDate();
                    }
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }
    const saveDate = () => {
        setLoaded(true);
        var glassData = {
            g_ids: pid,
            g_name: pname,
            g_tags: ptags,
            g_murl: mTemp,
            g_surl: sTemps,
            extra: {
                creator
            }
        };
        axios.post(api_prefix + '/glass', glassData)
            .then(response => {
                notify_success();
                setSuccess(true);
                setLoaded(false);
                init();
            }).catch((error) => {
                notify_fail();
                setLoaded(false);
                init();
            });
        mTemp = "";
        sTemps = [];
        callCnt = 0;
    }
    const handleSave = () => {
        if(!hasRole){
            notify_warn('您没有上传样本的权限. 请联系管理员.');
            return;
        }
        uploadImgInterface(selectedImageMain, 0);
        selectedImageSub.forEach((item) => {
            uploadImgInterface(item, 1);
        });
    }
    const handleCancel = () => {
        mTemp = "";
        sTemps = [];
    }

    const confirmUpload = () => {
        setSuccess(false);
        setPid("");
        setPname("");
        setPtags([]);
        setMurl("");
        setSurl([]);
        setUpcnt(0); 
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
    }
    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TextField
                        id="outlined-textarea-id"
                        label="款号"
                        placeholder="款号"
                        multiline
                        value={pid}
                        onChange={handlePid}
                    />
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TextField
                        id="outlined-textarea-source"
                        label="样品来源"
                        placeholder="样品来源"
                        multiline
                        value={pname}
                        onChange={handlePname}
                    />
                </Grid>
                <Grid item xs={12} className="category-section">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" onClick={() => handleClickOpen()}>标签</Button>
                        </Grid>
                        <Grid item xs={12} md={10} className={'row-wrap-g'}>
                            {checked &&
                                Object.keys(checked).map((item) => {
                                    return (
                                        <>
                                            {checked[item] === true ?
                                                <div className="checked-item">{item}</div> : <></>
                                            }
                                        </>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <input accept="image/*" type="file" onChange={imageChangeMain} id="main-image" style={{ display: 'none' }} />
                <Grid item xs={12} className="main-img-section">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <label htmlFor="main-image">
                                <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">主图</Button>
                            </label>
                        </Grid>
                        <Grid item xs={12} md={10} className={'row-wrap-g'}>
                            {selectedImageMain &&
                                <div className="image-wrap">
                                    <img src={selectedImageMain && URL.createObjectURL(selectedImageMain)} className="main-image-item" alt="thumb" />
                                    <IconButton className="image-control-btn" onClick={removeSelectedImageMain}>
                                        <CancelRoundedIcon className="close-icon" />
                                    </IconButton>
                                </div>}
                        </Grid>
                    </Grid>
                </Grid>
                <input accept="image/*" type="file" multiple onChange={imageChangeSub} id="sub-image" style={{ display: 'none' }} />
                <Grid item xs={12} className="sub-img-section">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <label htmlFor="sub-image">
                                <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">附图</Button>
                            </label>
                        </Grid>
                        <Grid item xs={12} md={10} className={'row-wrap-g over-scroll'}>
                            {selectedImageSub && selectedImageSub.map((item, index) => {
                                return (<>
                                    <div className="image-wrap">
                                        <img src={URL.createObjectURL(selectedImageSub[index])} className="main-image-item" alt="thumb" />
                                        <IconButton className="image-control-btn" onClick={() => removeSelectedImageSub(index)}>
                                            <CancelRoundedIcon className="close-icon" />
                                        </IconButton>
                                    </div>
                                </>);
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} className={'row-wrap-g align-right'}>
                            <Button variant="outlined" color="primary" className="gap-right" onClick={handleCancel}>取消</Button>
                            <Button variant="outlined" color="primary" className="gap-right" onClick={handleSave}>保存上传</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b">选择类别</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                <>
                                    <div className="check-box-mux">
                                        <p className="check-label">{keys} :</p>
                                        {categoryData[keys].map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    className="check-box-wrap"
                                                    control={
                                                        <Checkbox defaultChecked checked={checked[`${item}:${keys}`]}
                                                            onChange={(e) => handleChange(e, item, keys)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label={`${item}`}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Divider className="my-divider" />
                                </>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="my-font-b" onClick={handleClose}>确认</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={success}
                onClose={()=>setSuccess(false)}
            >
                <DialogTitle className="my-font-b">通知 : 上传成功</DialogTitle> 
                <DialogActions>
                    <Button className="my-font-b primary-color" onClick={()=>confirmUpload()}>确认</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
        </Container>
    );
}
export default SampleUpload;