import React, { useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";

export default function MainListItems() {

  let history = useHistory();

  const go_dashboard = () => {
    history.push("/admin");
  }



  return (
    <div>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/users_manage") }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="用户管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/sample_manage") }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="样品图像管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/ai_design") }}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="AI设计素材管理" />
      </ListItem>
      <Divider />

      <ListItem className={"list-item"} onClick={() => { history.push("/admin/sale_report") }}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="销售看板" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/ecommerce_manage") }}>
        <ListItemIcon>
          <StorefrontIcon />
        </ListItemIcon>
        <ListItemText primary="电商管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/order_manage") }}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="订单管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/customer_manage") }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="客户资料" />
      </ListItem>
      <Divider />

      <ListItem className={"list-item"} onClick={() => { history.push("/admin/data_entry") }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="资料录入(dataentry)" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/supply_chain") }}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="供应商(supply)" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/audit_record") }}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="审核记录(audit)" />
      </ListItem>
      <Divider />

      <ListItem className={"list-item"} onClick={() => { history.push("/admin/order_deal") }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="订单处理(o_deal)" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/order_process") }}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="订单进度(o_process)" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/order_payment") }}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="发起支付(payment)" />
      </ListItem>
      <Divider />

      <ListItem className={"list-item"} onClick={() => { history.push("/admin/inventory_manage") }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="入库管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/outbound_manage") }}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="出库管理" />
      </ListItem>
      <ListItem className={"list-item"} onClick={() => { history.push("/admin/manage_payment") }}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="发起支付" />
      </ListItem>
      <Divider />
      
      

       
    </div>
  );
}