import React, {useCallback, useEffect, useState} from "react";   
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@mui/material/Card'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import TransgenderIcon from '@mui/icons-material/Transgender';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import PetsIcon from '@mui/icons-material/Pets';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button'; 
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';
import './it.css';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePlacesWidget } from "react-google-autocomplete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,   
    paddingTop: '40px',
    paddingBottom: '50px',
  },    
}));

export default function MyselfSummary() {
  const classes = useStyles(); 
   
  const [genderMux, setGenderMux] = useState('');
  const [bodyMux, setBodyMux] = useState('');
  const [locationMux, setLocationMux] = useState('');
  const [drinkMux, setDrinkMux] = useState('');
  const [animalMux, setAnimalMux] = useState('');
  const [lookingMux, setLookingMux] = useState('');
  const [photoMux, setPhotoMux] = useState([]);
  const [tokenMux, setTokenMux] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tokenTemp, setTokenTemp] = useState(null);
  const [fTokenTemp, setFTokenTemp] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [user, setUser] = useState(null);
    
  

  useEffect(() => {        
    const profile_type = JSON.parse(localStorage.getItem("profile_type")).p_data; 
    console.log(profile_type.gender)
    const user = JSON.parse(localStorage.getItem('user')); 
    setUser(user)
    setUserInfo(user.userInfo);  
    setGenderMux( profile_type.gender);
    setBodyMux(profile_type.body);
    setLocationMux(profile_type.location);
    setDrinkMux(profile_type.drink);
    setAnimalMux(profile_type.animal);
    setLookingMux(profile_type.look);  
    setTokenMux(profile_type.token);
    setLoaded(true); 
    document.getElementById('location_id').value = user.userInfo.location;  
  }, []); 

  const notify_success = () =>  toast.success('Successfully  updated!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notify_fail = () =>  toast.error('Update failed!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
 
  const onSave = async () => {  
    var lc = document.getElementById('location_id').value;
    let temp = {...userInfo} 
    temp.location = lc;
    setUserInfo(temp);   
    user.userInfo = temp; 
   
    localStorage.setItem('user', JSON.stringify(user)); 
    
    const token = localStorage.getItem('token'); 
    const headers = {
      'Authorization': `Bearer ${token}`,  
    }  
    const data = {
      id: user.id,
      userInfo: user.userInfo
    }  
    axios.put('https://vnrefxuxd6.execute-api.us-east-2.amazonaws.com/dev/user', data, { headers })
        .then(response => { 
          notify_success();
        }).catch((error)=>{ 
          notify_fail();
        }); 
     
  }
  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyAQ6AaioPcRuPjkjbVsH8IB4oCmqvKIayM',
    onPlaceSelected: (place) => {
     console.log(place.formatted_address) 
     updateLocation(place.formatted_address);
    },
    inputAutocompleteValue: "country", 
  });

  const updateLocation = (place) =>{ 
    var lc = document.getElementById('location_id').value;
    let temp = {...userInfo} 
    console.log(temp);  
  };
  
  
  const locationChange = (event) =>{
    let temp = {...userInfo} 
    temp.location =  event.target.value;
    setUserInfo(temp); 
  } 
  const nameChange = (event) =>{
    let temp = {...userInfo}
    console.log(temp)
    temp.name =  event.target.value;
    setUserInfo(temp); 
  } 
  const genderChange = (event) => {
    let temp = {...userInfo}
    temp.gender =  event.target.value;
    setUserInfo(temp); 
  };
  const bodyChange = (event) => {
    let temp = {...userInfo}
    temp.body =  event.target.value;
    setUserInfo(temp);  
  };
  const drinkChange = (event) => {
    let temp = {...userInfo}
    temp.drink =  event.target.value;
    setUserInfo(temp);   
  };
  const animalChange = (event) => {
    let temp = {...userInfo}
    temp.animal =  event.target.value;
    setUserInfo(temp);    
  };
  
  const lookChange = (event) =>{
    let temp = {...userInfo}
    temp.look =  event.target.value;
    setUserInfo(temp);
  }
  const onSummaryChange = (event) =>{
    let temp = {...userInfo}
    temp.summary =  event.target.value;
    setUserInfo(temp);
  }
  const onTokenChange = (event) =>{
    setTokenTemp(event.target.value); 
  }
  const onFTokenChange = (event) =>{
    setFTokenTemp(event.target.value);
  }


  const addToken = (event) =>{ 
    let tokenVal = document.getElementById("token-val").value;
    console.log(tokenTemp); 
      let temp = {...userInfo}
      var data = {
        token: tokenTemp,
        val: tokenVal
      }
      temp.token.push(data);
      setUserInfo(temp); 
  }
  const addFToken = (event) =>{  
      console.log(tokenTemp); 
      let temp = {...userInfo}  
      temp.favToken.push(fTokenTemp);
      setUserInfo(temp); 
  }

  const Input = styled('input')({
    display: 'none',
  }); 

  const getBase64 = async (file) => {
    let reader = new FileReader();
    await reader.readAsDataURL(file);
    
    reader.onload = function () { 
      console.log("--------------------");
      console.log(reader.result);
        axios.post("https://vnrefxuxd6.execute-api.us-east-2.amazonaws.com/dev/upload", reader.result)
        .then((response) =>{     
          let temp = {...userInfo}
          temp.photo.push(response.data.Location);
          console.log(response.data.Location);
          setUserInfo(temp); 
        })
        .catch((error) => {
          throw(error);
        }) 
    };
    reader.onerror = function (error) {
        console.log(error);
    };
  }

  const [selectedFile, setFileState] = useState(null);
  const onFileChange = async (event) => { 
    setFileState(event.target.files[0]);   
    getBase64(event.target.files[0]);   
  };

  const deletePhoto = (index) => {
     console.log(index)
    let temp = {...userInfo}
    temp.photo.splice(index, 1);
    setUserInfo(temp);
  } 

  const delTokenItem = (index) => {
    let temp = {...userInfo}
    temp.token.splice(index, 1);
    setUserInfo(temp);
  }
  const delFTokenItem = (index) => {
    let temp = {...userInfo}
    temp.favToken.splice(index, 1);
    setUserInfo(temp);
  }
  

  return (
    <div className={classes.root}> 
      <Container  maxWidth="lg" className={"summary"}> 
      <h2 className={"summaryTitle"}>Myself Summary</h2>  
      <div className="button-group">
        <Button variant="outlined" startIcon={<SaveIcon />} className={"likeBtn"} onClick={onSave}>Save</Button>  
        <label htmlFor="contained-button-file">
          <Input accept="image/*" id="contained-button-file" onChange={onFileChange} multiple type="file" /> 
          <Button variant="contained"  startIcon={<AddPhotoAlternateIcon />}  component="span" className={"passBtn"}>Add Photo</Button>
        </label>  
      </div>  
            <Grid  container spacing={1} className="summary-group">  
                <Grid  item xs={12} md={4} className={"btnGroup"}>
                    <div className={"summaryDetailWrap"}>
                        <DriveFileRenameOutlineIcon className={"summaryIcon"}/>
                        <TextField id="outlined-basic"  value={loaded&&userInfo.name} onChange={nameChange} label="Name" variant="outlined" className={"summaryDetail"} />
                    </div>
                    <div className={"summaryDetailWrap"}>
                        <PermContactCalendarIcon className={"summaryIcon"}/>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                          <DatePicker
                            label="Birth Day"
                            value={loaded&&userInfo.birth}
                            onChange={(newValue) => {  
                              let temp = {...userInfo}
                              temp.birth =  newValue;
                              setUserInfo(temp);
                            }}
                            
                            renderInput={(params) => <TextField {...params} className={"summaryDetail"}/>}
                          />
                        </LocalizationProvider> 
                    </div>  
                     
                    <div className={"summaryDetailWrap"}>
                        <TransgenderIcon className={"summaryIcon"}/>
                        <FormControl className={"summaryDetail myFont"}>
                            <InputLabel  >Gender  </InputLabel>
                            <Select value={loaded&&userInfo.gender} label="Gender" onChange={genderChange}>
                                { loaded&&genderMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                } 
                            </Select>
                        </FormControl>
                    </div>
                    <div className={"summaryDetailWrap"}>
                        <AccessibilityIcon className={"summaryIcon"}/>
                        <FormControl className={"summaryDetail"}>
                            <InputLabel  >BodyType  </InputLabel>
                            <Select value={loaded&&userInfo.body} label="BodyType" onChange={bodyChange}>
                                { loaded&&bodyMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                }  
                            </Select>
                        </FormControl>
                    </div>
                    <div className={"summaryDetailWrap"}>
                        <AddLocationAltIcon className={"summaryIcon"}/>
                        <TextField inputRef={materialRef} id="location_id" label="Location" variant="outlined" className={"summaryDetail"} /> 
                    </div> 
                    <div className={"summaryDetailWrap"}>
                        <FreeBreakfastIcon className={"summaryIcon"}/>
                        <FormControl className={"summaryDetail"}>
                            <InputLabel  >Favorite Drink  </InputLabel>
                            <Select value={loaded&&userInfo.drink} label="FavoriteDrink " onChange={drinkChange}>
                                { loaded&&drinkMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                } 
                            </Select>
                        </FormControl>
                    </div>
                    <div className={"summaryDetailWrap"}>
                        <PetsIcon className={"summaryIcon"}/>
                        <FormControl className={"summaryDetail"}>
                            <InputLabel  >Animals  </InputLabel>
                            <Select value={loaded&&userInfo.animal} label="FavoriteDrink " onChange={animalChange}>
                                { loaded&&animalMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                } 
                            </Select>
                        </FormControl>
                    </div> 
                    <div className={"summaryDetailWrap"}>
                        <VisibilityIcon className={"summaryIcon"}/>
                        <TextField id="outlined-basic" label="What I am looking for" variant="outlined" className={"summaryDetail"} value={loaded&&userInfo.look} onChange={lookChange} />
                    </div>
                    <div className={"summaryDetailWrap"}>
                        <FavoriteBorderIcon className={"summaryIcon"}/>
                        <FormControl className={"token-name"}>
                            <InputLabel  >Favorite Tokens  </InputLabel>
                            <Select id="fav-token" value={fTokenTemp} label="Favorite Tokens" onChange={onFTokenChange}>
                                { loaded&&tokenMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                } 
                            </Select>
                        </FormControl>
                         <Button variant="contained" color="primary" onClick={()=>addFToken()} className={"add-btn"}>Add</Button>
                    </div>
                    {
                     loaded&&userInfo.favToken.length>0?
                      <div className={"summaryLikeWrap"}> 
                            {loaded&&userInfo.favToken.map((item, index)=>{
                              return( 
                                <div className={"item"}>
                                  <div className={"like-item"}>
                                        <span>{item}</span>
                                        <DeleteForeverIcon className={"del-icon"} onClick={()=>delFTokenItem(index)} />
                                  </div>
                                </div>   
                              );
                            })}   
                      </div>
                     :<></>
                    } 
                    <div className={"summaryDetailWrap ownToken"}>
                        <MonetizationOnIcon className={"summaryIcon"}/>
                        <FormControl className={"token-name"}>
                            <InputLabel  >Owned Tokens  </InputLabel>
                            <Select id="own-token" value={tokenTemp} label="Owned Tokens" onChange={onTokenChange}>
                                { loaded&&tokenMux.map((item)=>{
                                    return(
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })
                                } 
                            </Select>
                        </FormControl>
                        <TextField id="token-val" label="Amount" variant="outlined" className={"token-value"} />
                        <Button variant="contained" color="primary" onClick={()=>addToken()} className={"add-btn"}>Add</Button>
                    </div>
                    {
                     loaded&&userInfo.token.length>0?
                      <div className={"summaryLikeWrap"}> 
                            {loaded&&userInfo.token.map((item, index)=>{
                              return( 
                                <div className={"item"}>
                                  <div className={"like-item"}>
                                        <span>{item.token}:{item.val}</span>
                                        <DeleteForeverIcon className={"del-icon"} onClick={()=>delTokenItem(index)} />
                                  </div>
                                </div>   
                              );
                            })}   
                      </div>
                     :<></>
                    } 
                   
                </Grid>
                <Grid  item xs={12} md={8} className={"desGroup"}>
                    <div className={"mySummaryContents"}>  
                        <TextField
                          className={"my-summary"}
                          id="outlined-multiline-static"
                          label="Myself Summary"
                          multiline
                          rows={10}
                          value={loaded&&userInfo.summary}
                          onChange={onSummaryChange} 
                        />
                    </div>
                    <div className="horizontalView">
                      <div className={"profileWrap"}>
                          {loaded&&userInfo.photo.map((item, index)=>{
                            return( 
                              <div className={"profile-item"}>
                                <div className={"profileImgWrap"}><img src={item} className={"profileImg"}/></div> 
                                <div className={"profile-button"} > 
                                   <DeleteForeverIcon className="btn-delete" onClick={()=>deletePhoto(index)}/>
                                </div>
                              </div> 
                            );
                          })} 
                      </div>  
                    </div>
                    
                </Grid>   
            </Grid>  
         
      </Container>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
      <ToastContainer />   
    </div>
  );
}
