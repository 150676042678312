import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import RootReducer from '../state/RootReducer';

const composeEnhancers = composeWithDevTools({
  trace: true,
});

const sentryEnhancer = Sentry.createReduxEnhancer({});

class ReduxStore {
  constructor() {
    this.store = createStore(
      RootReducer,
      composeEnhancers(applyMiddleware(thunk), sentryEnhancer),
    );
  }
}

export default new ReduxStore();
