import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function OpticianTreat() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [platform, setPlatform] = useState('');

    useEffect(() => {

    }, []);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changePlatform = (event) => {
        setPlatform(event.target.value);
    };

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        endAdornment={<IconButton><SearchIcon /></IconButton>}
                        value={searchVal}
                        onChange={handleSearch}
                    />
                    <IconButton className="erp-ml-10" onClick={() => setOpen(true)}>
                        <AddCircleIcon className="erp-primary-color-btn-icon" />
                    </IconButton>

                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>日期</TableCell>
                                    <TableCell align="right">订单号</TableCell>
                                    <TableCell align="right">产品SKU</TableCell>
                                    <TableCell align="right">平台</TableCell>
                                    <TableCell align="right">用户ID</TableCell>
                                    <TableCell align="right">订单处理</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-bb-color erp-btn-padding" >
                                                配镜完成
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>

                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b"> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="订单号"
                                    placeholder="订单号"
                                />
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="用户ID"
                                    placeholder="用户ID"
                                />
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pt-10">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="platform-sel">销售平台</InputLabel>
                                        <Select
                                            labelId="platform-sel"
                                            id="platform-sel"
                                            value={platform}
                                            label="platform"
                                            onChange={changePlatform}
                                        >
                                            <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                            <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                            <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} className="erp-mt-20">
                                <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                                    换货SKU
                                </Button>
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="镜片折射率"
                                    type="number"
                                    InputProps={{ inputProps: { step: 0.01, max: 10, min: -10 } }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pt-10">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="lense-sel">镜片功能</InputLabel>
                                        <Select
                                            labelId="lense-sel"
                                            id="lense-sel"
                                            value={platform}
                                            label="lense-sel"
                                            onChange={changePlatform}
                                        >
                                            <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                            <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                            <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={3} className="erp-mt-10">
                                    <Grid item md={12} xs={12}>
                                        R(OS)
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="S"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="C"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="A"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="erp-mt-10">
                                    <Grid item md={12} xs={12}>
                                        R(OS)
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="S"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="C"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="A"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="erp-mt-10">
                                    <Grid item md={12} xs={12}> 
                                    </Grid>
                                    <Grid item md={4} xs={12} className="erp-pt-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="PD"
                                        />
                                    </Grid> 
                                </Grid>
                            </Grid> 
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                        取消
                    </Button>
                    <Button variant="outlined" id="upload" >
                        确认
                    </Button> 
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default OpticianTreat;