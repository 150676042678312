import { useHistory } from "react-router-dom";
import axios from 'axios';  
const api_prefix = process.env.REACT_APP_API_PREFIX;

export const ERP_USER_ACTIONS = {
    SET_SIGN_INFO: 'ERP_SET_SIGN_INFO',
    SET_USER_INFO: 'ERP_SET_USER_INFO',
    CLEAR_USER_INFO: 'ERP_CLEAR_USER_INFO'
}  

export const getSignInfo = (data, index, user) => (dispatch) => {
 
};

export const setUserInfo = (payload) => ({
    type: ERP_USER_ACTIONS.SET_USER_INFO,
    payload,
});

export const setSignInfo = (payload) => ({
    type: ERP_USER_ACTIONS.SET_SIGN_INFO,
    payload,
});

export const clearUserInfo = () => ({
    type: ERP_USER_ACTIONS.CLEAR_USER_INFO,
}); 
 