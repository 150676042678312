import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import { setErpSampleTags } from "../../../../data/Status/Action";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import './it.css';

function EditSample() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const user = useSelector((state) => state.user.signInfo);
    const category = useSelector((state) => state.status.setSampleTags);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = useState(false);

    const [isView, setIsView] = useState(true);
    const [eidtItem, setEditItem] = useState(null);
    const [eidtIndex, setEditIndex] = useState(0);
    const [categoryData, setCategoryData] = useState({});

    const [uid, setUid] = useState("");
    const [glass, setGlass] = useState([])
    const [puid, setPuid] = useState("")
    const [pid, setPid] = useState("");
    const [pname, setPname] = useState("");
    const [ptags, setPtags] = useState([]);
    const [checked, setChecked] = useState({});
    const [murl, setMurl] = useState("");
    const [surl, setSurl] = useState([]);
    const [extra, setExtra] = useState({});
    const [pdf, setPdf] = useState("");
    const [selectedImageMain, setSelectedImageMain] = useState(null);
    const [selectedImageSub, setSelectedImageSub] = useState([]);

    useEffect(() => {
        setCategoryData(category);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
    }, [category]);

    useEffect(() => {
        setLoaded(true);
        setUid(user.user.id);
        var data = {
            mode: 3,
            g_id: user.user.id,
            g_tag: "",
            g_page: ""
        }
        axios.patch(api_prefix + "/glass", data)
            .then((response) => {
                setLoaded(false);
                var temp = response.data;
                temp.forEach((i, index) => {
                    temp[index]["add"] = false;
                    temp[index]["idx"] = index;
                })
                setGlass(temp);
            })
            .catch((error) => {
                throw (error);
            })
    }, [user]);

    const handleClickOpen = (e, img) => {
        setOpen(true);
    };

    const deletItem = () => {
        axios.delete(api_prefix + "/glass/" + puid)
            .then((response) => {
                setLoaded(false);
                var temp = [...glass]
                temp.splice(eidtIndex, 1)
                notify_success("成功更新.")
                setGlass(temp);
                setIsView(true);
            })
            .catch((error) => {
                throw (error);
            })
    }

    const editItems = (item, index) => {
        setEditIndex(index);
        setPuid(item.id);
        setPid(item.gids);
        setPname(item.gname);
        setPtags(item.gtags);
        setMurl(item.gmurl);
        setSurl(item.gsurl);
        setPtags(item.gtags);
        setExtra(item.extra);
        setPdf(item.pdf);
        setIsView(false);
        var temp = { ...checked };
        var t_gtag = item.gtags;
        Object.keys(temp).forEach((i) => {
            if (t_gtag.includes(i)) {
                temp[i] = true;
            }
        });
        setChecked(temp);
    }

    const imageChangeMain = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImageMain(e.target.files[0]);
            var tmp_url = URL.createObjectURL(e.target.files[0]);
            setMurl(tmp_url);
        }
    }
    const imageChangeSub = (e) => {
        var temp = [...surl];
        var tmp_simg = [...selectedImageSub];
        if (e.target.files && e.target.files.length > 0) {
            var tmp_url = URL.createObjectURL(e.target.files[0]);
            temp.push(tmp_url);
            tmp_simg.push(e.target.files[0]);
            setSurl(temp);
            setSelectedImageSub(tmp_simg);
        }
    }
    const removeSelectedImageMain = () => {
        setSelectedImageMain(null);
        setMurl("");
    }
    const removeSelectedImageSub = (index) => {
        var url_cnt = 0;
        surl.forEach((i) => {
            if (i.substring(0, 4) == "http") {
                url_cnt++;
            }
        })
        var findex = index - url_cnt;
        var temp = [...surl];
        var tmp_simg = [...selectedImageSub];
        temp.splice(index, 1);
        tmp_simg.splice(findex, 1);
        setSurl(temp);
        setSelectedImageSub(tmp_simg);
    }

    const uploadFile = async (dir, names, proImg) => {
        let reader = new FileReader();
        await reader.readAsDataURL(proImg);
        reader.onload = function () {
            var data = {
                dir: dir,
                img: reader.result,
                imgName: names
            }
            axios.post(api_prefix + "/uploadImg", data)
                .then((res) => {
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }

    const update = () => {
        setLoaded(true)
        var t_mimg = "";
        var t_simg = [];

        if (selectedImageMain != null) {
            var fileName = Date.now();
            var dir = "sample";
            var n = selectedImageMain.name.split('.');
            var ext = n[n.length - 1];
            uploadFile(dir, fileName, selectedImageMain);
            t_mimg = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
        } else {
            t_mimg = murl;
        }

        var sImgMix = selectedImageSub;
        surl.forEach((img, index) => {
            if (img.substring(0, 4) == "blob") {
                var fileName = Date.now();
                var dir = "sample";
                var sImg = sImgMix.shift();
                var n = sImg.name.split('.');
                var ext = n[n.length - 1];
                uploadFile(dir, fileName, sImg);
                var t_surl = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
                t_simg.push(t_surl);
            } else {
                t_simg.push(img)
            }
        })

        var t_extra = extra;
        t_extra.creator["mt"] = Date.now();
        var data = {
            id: puid,
            gids: pid,
            gname: pname,
            gmurl: t_mimg,
            gsurl: t_simg,
            gtags: ptags,
            extra: t_extra,
            pdf: pdf,
        }
        axios.put(api_prefix + "/glass", data)
            .then((res) => {
                setLoaded(false); 
                notify_success("成功更新.")
            })
            .catch((error) => {
                notify_fail("网络问题，请检查.")
                throw (error);
            })

    }

    const handleChange = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setPtags(temps);
    }

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container maxWidth="xl" className="sample-scope">
            {
                isView ?
                    <Container spacing={1}>
                        <Grid container spacing={3}>
                            {loaded ?
                                <Grid item xs={12} md={12}>
                                    <LinearProgress />
                                </Grid> :
                                <></>
                            }
                            {
                                glass.length > 0 ?
                                    glass.map((item, index) => {
                                        return (
                                            <Grid item xs={12} md={4} >
                                                <div className="item-wrap">
                                                    <div className="item-img-wrap">
                                                        <img src={item.gmurl} alt="" className="item-img" />
                                                        <div className="hover-wrap" onClick={(e) => handleClickOpen(e, item.gmurl)}>
                                                        </div>
                                                    </div>
                                                    <div className="item-des-wrap">
                                                        <Grid container>
                                                            <Grid item xs={12} md={7}>
                                                                <p className="item-label">款号：{item.gids}</p>
                                                                <p className="item-label">样品来源：{item.gname}</p>
                                                            </Grid>
                                                            <Grid item xs={12} md={5} className="btn-wrap">
                                                                <Button variant="contained" className="primary-color" onClick={() => editItems(item, index)}>查看和编辑</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        );
                                    }) :
                                    <Grid item xs={12} md={4} >
                                        {loaded ? <></> : <h5>No result</h5>}
                                    </Grid>
                            }
                        </Grid>
                    </Container> :

                    // edit part 
                    <Container spacing={1}>
                        <Grid container spacing={3}>
                            {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TextField
                                    label="款号"
                                    placeholder="款号"
                                    multiline
                                    value={pid}
                                    onChange={(e) => setPid(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TextField
                                    label="样品来源"
                                    placeholder="样品来源"
                                    multiline
                                    value={pname}
                                    onChange={(e) => setPname(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className="category-section">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2}>
                                        <Button variant="contained" endIcon={<AddIcon />} className="primary-color" onClick={() => handleClickOpen()}>标签</Button>
                                    </Grid>
                                    <Grid item xs={12} md={10} className={'row-wrap-g'}>
                                        {checked &&
                                            Object.keys(checked).map((item) => {
                                                return (
                                                    <>
                                                        {checked[item] === true ?
                                                            <div className="checked-item">{item}</div> : <></>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <input accept="image/*" type="file" onChange={imageChangeMain} id="main-image" style={{ display: 'none' }} />
                            <Grid item xs={12} className="main-img-section">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2}>
                                        <label htmlFor="main-image">
                                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">主图</Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={10} className={'row-wrap-g'}>
                                        {murl != "" ?
                                            <div className="image-wrap">
                                                <img src={murl} className="main-image-item" alt="thumb" />
                                                <IconButton className="image-control-btn" onClick={removeSelectedImageMain}>
                                                    <CancelRoundedIcon className="close-icon" />
                                                </IconButton>
                                            </div> : <></>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <input accept="image/*" type="file" onChange={imageChangeSub} id="sub-image" style={{ display: 'none' }} />
                            <Grid item xs={12} className="sub-img-section">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2}>
                                        <label htmlFor="sub-image">
                                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">附图</Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={10} className={'row-wrap-g over-scroll'}>
                                        {surl != [] ? surl.map((item, index) => {
                                            return (<>
                                                <div className="image-wrap">
                                                    <img src={item} className="main-image-item" alt="thumb" />
                                                    <IconButton className="image-control-btn" onClick={() => removeSelectedImageSub(index)}>
                                                        <CancelRoundedIcon className="close-icon" />
                                                    </IconButton>
                                                </div>
                                            </>);
                                        }) : <></>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className={'row-wrap-g align-right'}>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={()=>setIsView(true)}  >取消</Button>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={()=>deletItem()}  >删除</Button>
                                        <Button variant="outlined" color="primary" className="gap-right" onClick={() => update()} >保存上传</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
            }


            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle className="my-font-b">选择类别</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                <>
                                    <div className="check-box-mux">
                                        <p className="check-label">{keys} :</p>
                                        {categoryData[keys].map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    className="check-box-wrap"
                                                    control={
                                                        <Checkbox defaultChecked checked={checked[`${item}:${keys}`]}
                                                            onChange={(e) => handleChange(e, item, keys)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label={`${item}`}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Divider className="my-divider" />
                                </>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="my-font-b" onClick={() => setOpen(false)}>确认</Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}
export default EditSample;

