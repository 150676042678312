import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SideImg from "../images/network/wixen_signin_3.jpg";
import LogoImg from "../images/logoback.png";
import FirebaseInstance from "../components/Integrate/Firebase/Config";
import firebase from "firebase";
import { Alert } from "@mui/material";

function Copyright(props) {
  let history = useHistory();
  const home = () => {
    history.push("/");
  }

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link onClick={home} color="inherit" href="#">
        Hindar Optcal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function PasswordReset() {
  let history = useHistory();
  const firebaseInstance = FirebaseInstance();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const sign_in = () => {
    history.push("/sign_in");
  }

  const send_reset = async () => {

  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${SideImg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center", }} >
            <img src={LogoImg} className={"sign_in_logo"} alt="vixen_logo" />
            <Box className={"forget_from"} component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} >
              <TextField margin="normal" required fullWidth label="电话号码" autoFocus />
              <Button className={"vixen_button_pink"} fullWidth variant="contained" onClick={send_reset} sx={{ mt: 3, mb: 2 }}>
                发送
              </Button>
              <Grid container>
                <Grid item>
                  <Link onClick={sign_in} variant="body2" className={"vixen_font have_account"}>
                    {"我已经有账号了. 登入."}
                  </Link>
                </Grid>
              </Grid>

              <Grid container>
                <div  ></div>
              </Grid>
              <Copyright className={"vixen_font"} sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
