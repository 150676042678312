import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Gluten-Regular.ttf';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import Store from '../src/state/Store';
import TopWrap from './components/Global/TopWrap';

const {store} = Store;

ReactDOM.render( 
  <Provider store={store}>
    <TopWrap>
      <App />
    </TopWrap> 
  </Provider>, 
  document.getElementById('root')
);
 
reportWebVitals();
