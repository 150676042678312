import { ERP_USER_ACTIONS } from "./Action";

const defaultState = {};

const Reducer = (state = defaultState, action) => {
    switch (action.type){
        case ERP_USER_ACTIONS.SET_SIGN_INFO:
          return {...state, signInfo: action.payload};
        case ERP_USER_ACTIONS.SET_USER_INFO:
          return {...state, userInfo : action.payload};
        case ERP_USER_ACTIONS.CLEAR_USER_INFO:
          return {...state, }; 
        default:
          return state;        
    }
};

export default Reducer