import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const demandList = ['Taobao', 'Amazon'];

function Purchase() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const user = useSelector((state) => state.user.signInfo);
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [platform, setPlatform] = useState('');
    const [diagState, setDiagState] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0);

    const [s_num, setS_num] = useState("");
    const [delivery, setDelivery] = useState(null);
    const [c_num, setC_num] = useState("");
    const [m_num, setM_num] = useState("");
    const [totalData, setTotalData] = useState(null);
    const [extraList, setExtraList] = useState([]);
    const [exData, setExData] = useState([]);
    const [clientColor, setClientColor] = useState("");
    const [sku, setSku] = useState("");
    const [color, setColor] = useState("");
    const [other, setOther] = useState("");
    const [quanty, setQuanty] = useState(0);
    const [modelList, setModelList] = useState([]);
    const [totalQuanty, setTotalQuanty] = useState(0);

    const [productList, setProductList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [details, setDetails] = useState(null);
    const [detailIndex, setDetailIndex] = useState(0);
    const [uid, setUid] = useState('');

    // manual tab
    const [newOrderNum, setNewOrderNum] = useState("");
    const [newDelivery, setNewDelivery] = useState(null);
    const [demandPlatform, setDemandPlatform] = useState("Taobao");
    const [detailList, setDetailList] = useState([]);
    const [n_sku, setN_sku] = useState("");
    const [n_color, setN_color] = useState("");
    const [n_quanty, setN_quanty] = useState(0);
    const [n_price, setN_price] = useState(0);

    const [items, setItems] = useState(null);

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);

    useEffect(() => {
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if (roles.includes('全部')) {
                    setHasRole(true);
                }
            } else {

            }
        } else {
            //history.push("/sign_in");
        }
    }, [user])

    useEffect(() => {
        setLoaded(true);
        var data = {
            mode: "initial",
        };
        axios.patch(api_prefix + '/contract', data)
            .then(res => {
                setOrderList(res.data);
                axios.get(api_prefix + '/product')
                    .then(res => {
                        var temp = [];
                        res.data.forEach((item) => {
                            temp.push(item.p_name);
                        })
                        setProductList(temp);
                        setLoaded(false)
                    }).catch((error) => {

                    });
            }).catch((error) => {
            });
    }, []);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changePlatform = (event) => {
        setPlatform(event.target.value);
    };

    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const addExtra = () => {
        var temp = [...extraList];
        var data = {
            clientColor,
            sku,
            color,
            other,
            quanty,
            m_num,
        };
        temp.push(data);
        setExtraList(temp);
        setClientColor("");
        setQuanty(0);
        setOther("");
    }
    const removeExtra = (index) => {
        var temp = [...extraList];
        temp.splice(index, 1);
        setExtraList(temp);
    }

    const addDetail = (item, index) => {
        console.log(">>>>>details", item);
        if (item.c_model != "") {
            setM_num(item.c_model);
            setCompanyModel(item.c_model, 2);
        }
        setC_num(item.clientId);
        setDetails(item);
        setTotalQuanty(item.quanty);
        setExtraList(item.detail);
        setDetailIndex(index);
        setDiagState(1);
    }
    const confirm = () => {
        if (diagState == 1) {
            // update data.... 
            var temp = [...exData];
            temp[detailIndex].detail = extraList;
            temp[detailIndex].c_model = m_num;
            var totalQ = 0;
            extraList.forEach((i) => {
                totalQ = totalQ + i.quanty * 1;
            })
            if (totalQ != totalQuanty) {
                alert("合约数量为:" + totalQuanty + ", 目前数量为" + totalQ + "..., 它应该是一样的!");
                return
            }
            setExData(temp);
            setExtraList([]);
            setDiagState(0);
            var totalTemp = totalData;
            totalTemp.info.extraList = temp;
            totalTemp.info.state = "initial";
            setTotalData(totalTemp);
        }
        if (diagState == 0) {
            // update data....
            var temp = totalData.info;
            temp.delivery = delivery;
            temp.state = "initial";
            setLoaded(true)
            var data = {
                id: totalData.id,
                c_name: totalData.c_name,
                s_num: totalData.s_num,
                info: temp
            }
            axios.put(api_prefix + '/contract', data)
                .then(res => {
                    setLoaded(false)
                }).catch((error) => {
                });
            setOpen(false)
        }
    }

    const setSaleNumber = (e, mode) => {
        var v = "";
        if (mode == 1) {
            v = e.target.value;
        } else {
            v = e;
        }
        setS_num(v);
        if (v.length == 10) {
            setLoaded(true)
            var data = {
                mode: "s_order_num",
                s_num: v
            }
            axios.patch(api_prefix + '/contract', data)
                .then(res => {
                    if (res.status == 200) {
                        setTotalData(res.data);
                        setDelivery(res.data.info.delivery);
                        var exdata = res.data.info.extraList;
                        var temp = [];
                        exdata.forEach((item) => {
                            temp.push(item);
                        });
                        setExData(temp);

                    } else {

                    }
                    setLoaded(false)
                }).catch((error) => {
                });
        }
    }

    const createOrderNum = () => {
        const t = Date.now().toString();
        var od = "S" + t.substring(1, 10);
        setNewOrderNum(od);
    }
    const addN_extra = () => {
        var temp = [...detailList];
        var data = {
            clientColor: "",
            other: "",
            sku: sku,
            color: color,
            quanty: n_quanty,
            m_num,
        }
        temp.push(data);
        setDetailList(temp);
        setN_color("");
        setN_sku("");
        setN_quanty(0);
    }
    const removeN_extra = (index) => {
        var temp = [...detailList];
        temp.splice(index, 1);
        setDetailList(temp);
    }

    const save = () => {
        if (editMode == 1) {
            var ex_data = {
                clientId: "",
                c_model: "",
                detail: detailList,
                price: "",
                quanty: "",
                sum: ""
            }
            var extraList = [];
            extraList.push(ex_data);
            var data = {
                c_name: demandPlatform,
                s_num: newOrderNum,
                info: {
                    c_num,
                    delivery: newDelivery,
                    url: "",
                    extraList,
                    state: "initial",
                    at: Date.now(),
                    mt: Date.now(),
                    from: 'internal',
                    creator
                }
            }
            setLoaded(true)
            axios.post(api_prefix + '/contract', data)
                .then(res => {
                    notify_success("创建成功");
                    setLoaded(false)
                    setOpen(false);

                }).catch((error) => {
                    notify_fail("未创建！ 检查您的网络");
                });
        } else {
            var ex_data = {
                clientId: "",
                c_model: "",
                detail: detailList,
                price: "",
                quanty: "",
                sum: ""
            }
            var extraList = [];
            extraList.push(ex_data);
            var data = {
                id: uid,
                c_name: demandPlatform,
                s_num: newOrderNum,
                info: {
                    c_num,
                    delivery: newDelivery,
                    url: "",
                    extraList,
                    state: "initial",
                    at: items.info.at,
                    mt: Date.now(),
                    from: 'internal',
                    creator
                }
            }
            setLoaded(true)
            axios.put(api_prefix + '/contract', data)
                .then(res => {
                    notify_success("成功更新.");
                    var temp = [...orderList];
                    temp[editIndex] = res.data;
                    setOrderList(temp);
                    setLoaded(false)
                    setOpen(false);
                }).catch((error) => {
                    notify_fail("未创建！ 检查您的网络");
                });
        }

    }

    const detail_view = (item, index) => {
        setEditMode(2);
        setItems(item);
        setUid(item.id);
        setEditIndex(index);
        setOpen(true);
        if (item.info.from == "external") {
            setTabValue("1");
            setSaleNumber(item.s_num, 2);
        } else {
            setTabValue("2");
            setNewOrderNum(item.s_num);
            setDemandPlatform(item.c_name);
            setNewDelivery(item.info.delivery);
            setDetailList(item.info.extraList[0].detail);
        }
    }

    const create_one = () => {
        setOpen(true);
        setEditMode(1);
    }

    const setCompanyModel = (e, i) => {
        var model = "";
        if (i == 1) {
            model = e.target.value;
        } else {
            model = e;
        }
        setM_num(model);
        setLoaded(true)
        var data = {
            mode: 1,
            model
        }
        axios.patch(api_prefix + '/product', data)
            .then(res => {
                console.log(">>>>>SEE", res.data);
                var temp = res.data;
                setModelList(temp);
                setLoaded(false)
                setSku(temp[0].sku);
                setColor(temp[0].color);
            }).catch((error) => {
            });
    }

    const selectSku = (e) => {
        var index = e.target.value;
        setSku(modelList[index].sku);
        setColor(modelList[index].color);
    }

    const cancelDiag = () => {
        if (diagState == 1 && tabValue == '1') {
            setDiagState(0);
            return
        }
        setOpen(false);
        setDiagState(0);
        setS_num("");
        setDelivery(null);
        setExData([]);
        setNewOrderNum("");
        setNewDelivery(null);
        setDetailList([]);
    }

    const sum_quanty = (item) => {
        var t = 0;
        item.forEach((i) => {
            t += i.quanty * 1;
        })
        return t;
    }

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        endAdornment={<IconButton><SearchIcon /></IconButton>}
                        value={searchVal}
                        onChange={handleSearch}
                    />
                    <IconButton className="erp-ml-10" onClick={() => create_one()}>
                        <AddCircleIcon className="erp-primary-color-btn-icon" />
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>销售/采购单号</TableCell>
                                    <TableCell align="right">状态</TableCell>
                                    <TableCell align="right">详情</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderList.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{item.s_num}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-orb-color erp-btn-padding" >
                                                审核中
                                            </Button>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => detail_view(item, index)}>
                                                详细资料
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>

                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={() => cancelDiag()}
            >
                <DialogTitle className="my-font-b"> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTab} aria-label="lab API tabs example">
                                    <Tab label="客人订单" value="1" />
                                    <Tab label="自建采购" value="2" onClick={() => createOrderNum()} />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {diagState == 0 ?
                                    <Grid container>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="销售单号"
                                                placeholder="销售单号"
                                                value={s_num}
                                                onChange={(e) => setSaleNumber(e, 1)}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="交期"
                                                    value={delivery}
                                                    onChange={(newValue) => {
                                                        setDelivery(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pt-10">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>客人款号</TableCell>
                                                            <TableCell align="right">订单明细</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {exData.map((item, index) => (
                                                            <TableRow
                                                                key={item.clientId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{item.clientId}</TableCell>
                                                                <TableCell align="right">
                                                                    {
                                                                        sum_quanty(item.detail) < item.quanty * 1 ?
                                                                            <Button variant="contained" className="erp-grb-color erp-btn-padding" onClick={() => addDetail(item, index)}>
                                                                                添加明细
                                                                            </Button> :
                                                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addDetail(item, index)}>
                                                                                查看或更改
                                                                            </Button>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid> :
                                    <Grid container>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="销售单号"
                                                placeholder="销售单号"
                                                value={s_num}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="客人款号"
                                                placeholder="客人款号"
                                                value={c_num}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">

                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel >公司款号</InputLabel>
                                                    <Select
                                                        labelId="c_model"
                                                        label="c_model"
                                                        value={m_num}
                                                        onChange={(e) => setCompanyModel(e, 1)}
                                                    >
                                                        {
                                                            productList.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                        </Grid>

                                        <Grid item md={12} xs={12} className="erp-mt-20 erp-pt-10">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>客人色号</TableCell>
                                                            <TableCell>SKU</TableCell>
                                                            <TableCell>颜色</TableCell>
                                                            <TableCell>其他</TableCell>
                                                            <TableCell>合约数量:{totalQuanty}</TableCell>
                                                            <TableCell align="left">订单明细</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {extraList.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{item.clientColor}</TableCell>
                                                                <TableCell align="left" scope="row">{item.sku}</TableCell>
                                                                <TableCell align="left" scope="row">{item.color}</TableCell>
                                                                <TableCell align="left" scope="row">{item.other}</TableCell>
                                                                <TableCell align="left" scope="row">{item.quanty}</TableCell>
                                                                <TableCell align="left">
                                                                    <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => removeExtra(index)}>
                                                                        删除
                                                                    </Button>
                                                                    {/* // 查看或更改 */}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row"><input className="erp-input" value={clientColor} onChange={(e) => setClientColor(e.target.value)} /></TableCell>
                                                            <TableCell>
                                                                <select name="model" onChange={(e) => selectSku(e)}>
                                                                    {
                                                                        modelList.map((item, index) => {
                                                                            return (
                                                                                <option value={index}>{item.sku}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </TableCell>
                                                            <TableCell align="left"><input className="erp-input" value={color} /></TableCell>
                                                            <TableCell align="left"><input className="erp-input" value={other} onChange={(e) => setOther(e.target.value)} /></TableCell>
                                                            <TableCell align="left"><input className="erp-input" type="number" min="0" value={quanty} onChange={(e) => setQuanty(e.target.value)} /></TableCell>
                                                            <TableCell align="left">
                                                                <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addExtra()}>
                                                                    添加
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                }

                            </TabPanel>
                            <TabPanel value="2">
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12} className="erp-mt-20 erp-pr-10">
                                        <TextField
                                            className="erp-w-100"
                                            label="销售单号"
                                            placeholder="销售单号"
                                            value={newOrderNum}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} className="erp-mt-20 erp-pt-10">
                                        <FormControl className="erp-w-100 erp-mt-6">
                                            <InputLabel >需求平台</InputLabel>
                                            <Select
                                                labelId="property"
                                                value={demandPlatform}
                                                label="property"
                                                onChange={(e) => setDemandPlatform(e.target.value)}
                                            >
                                                {
                                                    demandList.map((item) => {
                                                        return (
                                                            <MenuItem value={item} className="erp-ml-10 erp-mt-6 erp-sel">{item}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12} className="erp-mt-20 erp-pr-10">

                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel >公司款号</InputLabel>
                                                <Select
                                                    labelId="c_model"
                                                    label="c_model"
                                                    value={m_num}
                                                    onChange={(e) => setCompanyModel(e, 1)}
                                                >
                                                    {
                                                        productList.map((item) => {
                                                            return (
                                                                <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </Grid>
                                    <Grid item md={3} xs={12} className="erp-mt-20 erp-pr-10">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="交期"
                                                value={newDelivery}
                                                onChange={(newValue) => {
                                                    setNewDelivery(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item md={8} xs={12} className="erp-mt-20 erp-pt-10">
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>SKU</TableCell>
                                                        <TableCell align="right">颜色</TableCell>
                                                        <TableCell align="right">数量</TableCell>
                                                        <TableCell align="right">笔记</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {detailList.map((item, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">{item.sku}</TableCell>
                                                            <TableCell align="right"> {item.color} </TableCell>
                                                            <TableCell align="right"> {item.quanty} </TableCell>
                                                            <TableCell align="right">
                                                                <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => removeN_extra(index)}>
                                                                    删除
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableBody>
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <select name="model" onChange={(e) => selectSku(e)}>
                                                                {
                                                                    modelList.map((item, index) => {
                                                                        return (
                                                                            <option value={index}>{item.sku}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </TableCell>
                                                        <TableCell align="right"><input className="erp-input" value={color} /></TableCell>
                                                        <TableCell align="right"><input className="erp-input" type="number" min="0" value={n_quanty} onChange={(e) => setN_quanty(e.target.value)} /></TableCell>
                                                        <TableCell align="right">
                                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addN_extra()}>
                                                                添加
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        tabValue == "1" ?
                            <>
                                <Button variant="outlined" onClick={() => cancelDiag()} >
                                    取消
                                </Button>
                                <Button variant="outlined" onClick={() => confirm()} >
                                    确认
                                </Button>
                            </> :
                            <>
                                <Button variant="outlined" onClick={() => cancelDiag()} >
                                    取消
                                </Button>
                                <Button variant="outlined" onClick={() => save()} >
                                    确认
                                </Button>
                            </>
                    }

                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default Purchase;