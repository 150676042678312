import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
];
const products = [
    createData('6008', 159, 6.0, 24, 4.0),
    createData('8746', 237, 9.0, 37, 4.3),
];

function OrderDeal() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const user = useSelector((state) => state.user.signInfo);
    const m_data = useSelector((state) => state.status.setSupModel);
    const [open, setOpen] = React.useState(false);
    const [detailView, setDetailView] = useState(false);
    const [supplierView, setSupplierView] = useState(false);
    const [spStep, setSpStep] = useState(0);
    const [scView, setScView] = useState(false);
    const [fnView, setFnView] = useState(false);

    const [diagStage, setDiagStage] = useState(1);
    const [editNameState, setEditNameState] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [loaded, setLoaded] = useState(false);

    const [orderList, setOrderList] = useState([]);
    const [d_s_num, setD_s_num] = useState("");
    const [d_extra, setD_extra] = useState([]);

    const [skus, setSkus] = useState([]);
    const [skuSpplier, setSkuSupplier] = useState([]);
    const [orderNum, setOrderNum] = useState("");
    const [orderDay, setOrderDay] = useState(null);



    const [addProtList, setAddProList] = useState()
    const [addPro, setAddPro] = useState("");
    const [addSup, setAddSup] = useState("");
    const [mergedSkus, setMergedSkus] = useState({});
    const [buyer, setBuyer] = useState("");

    const [modelData, setModelData] = useState([]);
    const [subModel, setSubModel] = useState([]);
    const [bmodel, setBmodel] = useState("");

    const [mList, setMList] = useState([]);
    const [delivery, setDelivery] = useState('')
    const [contractPro, setContractPro] = useState([]);
    const [editNum, setEditNum] = useState(0);

    const [contractMux, setContractMux] = useState([]);

    useEffect(() => {
        setLoaded(true);
        var data = {
            mode: "initial",
        };
        axios.patch(api_prefix + '/contract', data)
            .then(res => {
                setOrderList(res.data);
                setLoaded(false);
            }).catch((error) => {
            });
    }, []);

    useEffect(() => {
        console.log('>>>>model', m_data)
        var m_name = [];
        m_data && m_data.forEach((e) => {
            if (!m_name.includes(e.info.supplier)) {
                m_name.push(e.info.supplier)
            }
        })
        setModelData(m_name);
    }, [m_data])

    useEffect(() => {
        console.log('>>>>model', user)

    }, [user])

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }
    const closeDetailView = () => {
        setDetailView(false);
    }
    const closeSupplierView = (i) => {
        if (i == 0) {
            setSpStep(1);
            callArrangeSup();

        } else if (i == 1) {
            setSpStep(0);
        } else {
            setSupplierView(false);
            setSpStep(0);
            setFnView(true);
        }
    }
    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const addSupplier = () => {
        setEditNameState(false)
    }

    const viewDetails = (item, index) => {
        console.log(">>>>INOF", item)
        setD_s_num(item.s_num);
        setD_extra(item.info.extraList);
        setDetailView(true);
    }
    const viewSuppliers = (item, index) => {
        console.log(">>>>>>view", item)
        setD_s_num(item.s_num);
        setDelivery(item.info.delivery);
        setSupplierView(true);
        var temp = [];
        var stemp = [];
        item.info.extraList.forEach((i) => {
            i.detail.forEach((d) => {
                var a = {
                    model: d.m_num,
                    supplier: getSupplierList(d.m_num)[0]
                }
                d.supplier = getSupplierList(d.m_num)[0];
                temp.push(d);
            })
        })
        setSkus(temp);
    }
    const getDate = (t) => {
        var date = new Date(t);
        return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    }
    const getSupplierList = (n) => {
        var temp = [];
        m_data.forEach((e) => {
            if (e.p_name == n) {
                temp.push(e.info.supplier)
            }
        })
        return temp;
    }
    const supplierChange = (item, index) => {
        var temp = [...skus];
        temp[index].supplier = item;
        setSkus(temp);
    }
    const selectAddingSupplier = (i) => {
        var temp = [];
        m_data.forEach((e) => {
            if (e.info.supplier === i) {
                temp.push(e.p_name);
            }
        })
        setSubModel(temp);
        setAddSup(i);
    }
    const addSupplierMore = () => {
        var temp = [...skus];
        var d = {
            m_num: addPro,
            supplier: addSup,
            new: true
        }
        temp.push(d);
        setSkus(temp);
    }
    const removeSupplierMore = (i) => {
        var temp = [...skus];
        temp.splice(i, 1);
        setSkus(temp);
    }
    const callArrangeSup = () => {
        var temp = {};
        skus.forEach((e) => {
            if (e.supplier in temp) {
                var t = temp[e.supplier];
                t.push(e.m_num);
                temp[e.supplier] = t;
            } else {
                temp[e.supplier] = [e.m_num];
            }
        })
        setMergedSkus(temp);

        var f = [];
        temp && Object.keys(temp).map((key) => {
            var t = {
                supplier: key,
                product: temp[key],
                state: 0,
                pof: '',
                extra: '',
                data: '',
                createAt: '',
                tables:'',
                creator:'',
                reviewer: ''
            }
            f.push(t)
        })
        setContractMux(f);
        console.log(">>>>>SSOSOS", f)
    }
    const createOrderNum = () => {
        const t = Date.now().toString();
        var od = "POF" + t.substring(1, 10);
        setOrderNum(od);
    }

    const viewSc = (s, index) => {
        setEditNum(index);
        createOrderNum();
        setBuyer(s);
        getToday();
        setMList(mergedSkus[s]);
        var temp = [];
        mergedSkus[s].forEach((item) => {
            var d = {
                g_model: d_s_num,
                a_model: item,
                b_model: getBmodel(item, s),
                quanty: 0,
                unit: "副",
                u_price: getUnitPrice(item, s),
                sums: 0,
                delivery,
                extra: "",
            }
            temp.push(d)
        })
        setContractPro(temp);
        setScView(true);
    }

    useEffect(() => {

    }, [contractPro])

    const getBmodel = (n, buyer) => {
        var res = "";
        m_data.forEach((e) => {
            if (e.p_name == n && e.info.supplier == buyer) {
                res = e.info.model;
            }
        })
        return res;
    }

    const getUnitPrice = (n, buyer) => {
        var res = "";
        m_data.forEach((e) => {
            if (e.p_name == n && e.info.supplier == buyer) {
                res = e.info.price;
            }
        })
        return res;
    }

    const getToday = () => {
        var t = Date.now();
        var d = new Date(t);
        var s = d.getFullYear() + "-" + (d.getMonth() * 1 + 1) + "-" + d.getDate();
        setOrderDay(s);
        return s;
    }

    const setQuanty = (e, i) => {
        var v = e.target.value;
        var temp = [...contractPro];
        temp[i].sums = Math.floor(temp[i].u_price * v * 100) / 100;
        temp[i].quanty = v;
        setContractPro(temp)
    }


    const keepOneContract = () => {
        console.log(">>>>>>heree", contractPro)
        var temp = [...contractMux];
        var editComplete = true;
        contractPro.map((item) => {
            if (item.sums == 0) {
                editComplete = false;
            }
        })
        if(editComplete){
            temp[editNum]['state'] = 1;
            temp[editNum]['tables'] = contractPro;
            temp[editNum]['pof'] = orderNum;
            temp[editNum]['createAt'] = Date.now();
            temp[editNum]['creator'] = user&& user.user.info;
        }
        setContractMux(temp);
        console.log(">>>>>>mux", temp)
        setScView(false)
    }

    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                <Grid item md={12} xs={12}>
                    <Container maxWidth="xl" className="sample-scope" >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <OutlinedInput
                                    endAdornment={<IconButton><SearchIcon /></IconButton>}
                                    value={searchVal}
                                    onChange={handleSearch}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>时间</TableCell>
                                                <TableCell align="right">销售单号</TableCell>
                                                <TableCell align="right">来源</TableCell>
                                                <TableCell align="right">明细</TableCell>
                                                <TableCell align="right">采购单</TableCell>
                                                <TableCell align="right">审核进度</TableCell>
                                                <TableCell align="right">下单</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderList.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{getDate(item.info.mt)}</TableCell>
                                                    <TableCell align="right">{item.s_num}</TableCell>
                                                    <TableCell align="right">{item.info.creator.name}</TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => viewDetails(item, index)}>
                                                            查看明细
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => viewSuppliers(item, index)}>
                                                            查看或更改
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" >
                                                            查看或更改
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" >
                                                            查看或更改
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>

                            </Grid>
                        </Grid>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                        <ToastContainer />
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={detailView}
                            onClose={closeDetailView}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <p className="erp-m-0">销售单号：{d_s_num}</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <IconButton className="erp-ml-10"  >
                                                <DownloadIcon className="erp-primary-color-btn-icon" /><span className="erp-font-s">下载资料</span>
                                            </IconButton>
                                        </Grid>
                                        {
                                            d_extra.map((extra, index) => (
                                                <>
                                                    <Grid key={index} item md={12} xs={12} className="erp-mt-20 erp-pr-10">
                                                        <p>客人款号：{extra.clientId}</p>
                                                        <TableContainer component={Paper}>
                                                            <Table aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>SKU</TableCell>
                                                                        <TableCell align="right">客户色号</TableCell>
                                                                        <TableCell align="right">颜色</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {extra.detail.map((item, index) => (
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell component="th" scope="row">{item.sku}</TableCell>
                                                                            <TableCell align="right">{item.clientColor}</TableCell>
                                                                            <TableCell align="right">{item.color}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </>
                                            ))
                                        }

                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} className="erp-right erp-mt-10">
                                            <Button variant="outlined" onClick={() => setDetailView(false)}>
                                                关闭
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={supplierView}
                            onClose={() => closeSupplierView(0)}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <p className="erp-m-0">销售单号：{d_s_num} </p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                            <IconButton className="erp-ml-10"  >
                                                <DownloadIcon className="erp-primary-color-btn-icon" /><span className="erp-font-s">下载资料</span>
                                            </IconButton>
                                        </Grid>

                                        <Grid item md={12} xs={12} className="erp-mt-20 erp-pr-10">
                                            {spStep == 0 ?
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>产品</TableCell>
                                                                <TableCell align="right">供应商</TableCell>
                                                                <TableCell align="right">行动</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {skus.map((item, index) => (

                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">{item.m_num}</TableCell>
                                                                    <TableCell align="right">
                                                                        {"new" in item ?
                                                                            item.supplier :
                                                                            <select name="model" onChange={(e) => supplierChange(e.target.value, index)} >
                                                                                {
                                                                                    getSupplierList(item.m_num).map((item, index) => {
                                                                                        return (
                                                                                            <option value={item.m_num}>{item}</option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        }

                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item.new &&
                                                                            <Button variant="contained" className="erp-gb-color erp-btn-padding" onClick={() => removeSupplierMore(index)}>
                                                                                删除
                                                                            </Button>}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <select onChange={(e) => setAddPro(e.target.value)} >
                                                                        {
                                                                            subModel && subModel.map((item, index) => {
                                                                                return (
                                                                                    <option value={item}>{item}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <select onChange={(e) => selectAddingSupplier(e.target.value)} >
                                                                        {
                                                                            modelData && modelData.map((item, index) => {
                                                                                return (
                                                                                    <option value={item}>{item}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addSupplierMore()}>
                                                                        添加
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer> :
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>供应商</TableCell>
                                                                <TableCell align="right">产品</TableCell>
                                                                <TableCell align="right">行动</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                mergedSkus && Object.keys(mergedSkus).map((keys, index) => (
                                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                        <TableCell component="th" scope="row">{keys}</TableCell>
                                                                        <TableCell align="right">
                                                                            {
                                                                                mergedSkus[keys].map((i) => {
                                                                                    return (
                                                                                        <span className="erp-set-model">{i}</span>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Button variant="contained" className={contractMux[index].state == 0?"erp-grb-color erp-btn-padding":"erp-pb-color erp-btn-padding"}  onClick={() => viewSc(keys, index)}>
                                                                                 {contractMux[index].state == 0? "添加详情" : "编辑详情"}
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} className="erp-right erp-mt-10">
                                            {spStep == 0 ?
                                                <Button variant="outlined" onClick={() => closeSupplierView(0)}>
                                                    下一步
                                                </Button> :
                                                <div>
                                                    <Button variant="outlined" className="erp-mr-10" onClick={() => closeSupplierView(1)}>
                                                        以前的
                                                    </Button>
                                                    <Button variant="outlined" onClick={() => closeSupplierView(2)}>
                                                        下一步
                                                    </Button>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth={true}
                            maxWidth={"xl"}
                            open={scView}
                            onClose={() => setScView(false)}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-center">温州纯音科技有限公司采购订单合同</p>
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">合同订单号: {orderNum} </p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">需方(甲方): {buyer}</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">签约时间: {orderDay}</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">供方(乙方)：温州创宇光学有限公司</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">签约地址：浙江温州市瓯海区</p>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">制单人: {user && user.user.info + "(" + user && user.user.email + ")"} </p>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">审核：</p>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">批准： </p>
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-mt-10 erp-pr-10">
                                            甲、乙双方根据《中华人民共和国合同法》及相关法律规定,经友好协商,签订本合同并信守下列条款,共同严格履行。
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-pr-10">
                                            <p>一、产品明细：</p>
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-pr-10">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell >客人型号</TableCell>
                                                            <TableCell align="right">甲方型号</TableCell>
                                                            <TableCell align="right">乙方型号</TableCell>
                                                            <TableCell align="right">数量</TableCell>
                                                            <TableCell align="right">单位</TableCell>
                                                            <TableCell align="right">单价</TableCell>
                                                            <TableCell align="right">金额</TableCell>
                                                            <TableCell align="right">交期</TableCell>
                                                            <TableCell align="right">质量要求</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {contractPro.length > 0 && contractPro.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {item.g_model}
                                                                </TableCell>
                                                                <TableCell align="right">{item.a_model}</TableCell>
                                                                <TableCell align="right">
                                                                    {item?.b_model}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <input className="erp-sel-w-r" value={item.quanty} type="number" onChange={(e) => setQuanty(e, index)} />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {item.unit}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {item.u_price}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {item.sums}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {item.delivery.substring(0, 10)}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <input className="erp-sel-w-r" value={item.extra} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-mt-20 erp-pr-10">
                                            <p>二 交货时间及要求：</p>
                                            <p>1. 交货时间：在订单出货时间前将货物运送至需方指定国内仓库,运费由供方负责.乙方应严格按照订单中的交货时间交货,若交货期内不能够准时交货,将有可能导致客人索赔及空运,甚至取消订单,一切后果与责任根由乙方负责.</p>
                                            <p>2.收货标准: 凭甲方及客户验货员抽检合格后签字的进仓单发货.</p>
                                            <p>二 交货时间及要求：</p>
                                            <p>1.甲方保证所有提供的包装材料最迟在出货日期前5天到齐，如果因甲方包装材料延迟，交货期可以顺延.</p>
                                            <p>2.延期5天以内（至少提前一周通知我司）不做扣款，延期6-10天每单扣款500元，延期10-15天按订单总货款5%金额处罚，延期15-20天按订单总货款10%金额处罚，延期20天以上按总货款20%金额处罚，同时对订单延期工厂需承担相应的快递或空运费用，客人因货期延误取消订单，由工厂自行承担.</p>
                                            <p>3. 此单为第三方验货，甲方只承担第一次验货费用，如果验货不通过，后续的验货费用由乙方负责.</p>
                                            <p>4.质量保质期客人收到货期限为1年，虽然甲方会有验货程序，但以最终客户验收为准，因品质、数量、包装等问题引起的客户索赔或拒收的一切责任由乙方负责.</p>
                                            <p>四 货款结算 ：</p>
                                            <p>1.乙方凭有效正确增值税发票，在出运后90天向需方办理结算.</p>
                                            <p>2.此单如乙方需我司代购物料 费用/付，会在乙方货款中代扣.</p>
                                            <p>五.合作约定：</p>
                                            <p>1.甲方提供的所有款式（包括设计图稿）印刷客户LOGO的产品,工艺及包装物料等乙方不得提供给第三方。非经我司同意不得开店在贵司的对外目录展览会上展示，如违反此条款而引发的客户索赔及一切责任由乙方承担所有法律责任 。 生产结束后乙方须将剩余包装物料收起并无条件退还给甲方.</p>
                                            <p>2.本合同一式两份，自甲乙双方签字盖章时生效，连同相关附件（包括合同传真件）具有同等法律效力，甲乙双方必须遵照执行，如有异议，双方可协商解决。协商无果，可申请仲裁和申诉.</p>
                                            <p></p>
                                            <p></p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-pr-10">
                                            <p>需方(单位名称)：浙江亨达眼镜有限公司 </p>
                                            <p>单位地址：温州市瓯海区娄桥工业园区集光路518号</p>
                                            <p>法定代表人：</p>
                                            <p>签约人：</p>
                                            <p>联系方式：  电话：0577-56586657 传真：0577-56586657</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-pr-10">
                                            <p>供方(单位名称)：</p>
                                            <p>单位地址：</p>
                                            <p>法定代表人：</p>
                                            <p>签约人：</p>
                                            <p>联系方式：</p>
                                        </Grid>
                                        <Grid item md={6} xs={12} className="erp-pr-10">
                                            打印日期:2022-02-18 11:32:01
                                        </Grid>

                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} className="erp-right erp-mt-10">
                                            <Button variant="outlined" onClick={() => keepOneContract()}>
                                                下一步
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth={true}
                            maxWidth={"xl"}
                            open={fnView}
                            onClose={() => setFnView(false)}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12} className="erp-mt-10 erp-pr-10">
                                            <p className="erp-m-0">销售单号: {d_s_num} </p>
                                        </Grid>
                                        <Grid item md={12} xs={12} className="erp-pr-10">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableBody>
                                                        {
                                                            mergedSkus && Object.keys(mergedSkus).map((keys, index) => (
                                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell component="th" scope="row">{keys}</TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            mergedSkus[keys].map((i) => {
                                                                                return (
                                                                                    <span className="erp-set-model">{i}</span>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <div>KKKKK</div>
                                                                        <div>OJOJO</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} className="erp-right erp-mt-10">
                                            <Button variant="outlined" onClick={() => setScView(false)}>
                                                下一步
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
export default OrderDeal;