import Navbar from '../components/Global/Header/Nav';   
import Footer from '../components/Global/Footer/Footer';  
import TitleBanner from '../components/Global/TitleBanner/TitleBanner';  
import Contact from '../components/Global/Contact/Contact';
 

function ContactPage() {
  return (
    <div >
       <Navbar/>  
       <TitleBanner title={"Contact Us"} /> 
       <Contact/> 
       <Footer/>  
    </div>
  );
} 
export default ContactPage;
