import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './it.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SaleList from "./part/SaleList";
import OrderDeal from "./part/OrderDeal";
import ReturnDeal from "./part/ReturnDeal";
import OpticianTreat from "./part/OpticianTreat";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Ecommerce(props) {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {

    }, []);
    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 2, borderColor: 'divider' }}
                    >
                        <Tab label="销售录入" {...a11yProps(0)} />
                        <Tab label="订单处理" {...a11yProps(1)} />
                        <Tab label="退换货进度" {...a11yProps(2)} />
                        <Tab label="配镜治疗 " {...a11yProps(3)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={10} className="manage-scope">
                    <TabPanel value={value} index={0}>
                        <SaleList />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <OrderDeal />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ReturnDeal />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <OpticianTreat />
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Ecommerce;

