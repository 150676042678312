import React from "react";  
import { useHistory } from "react-router-dom"; 
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core"; 
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import json2mq from 'json2mq';
import CloudShape from '../../../images/vixen_cloudshape_image.png';
import HeartImg from '../../../images/vixen_heart_image.png';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';  
import PinterestIcon from '@material-ui/icons/Pinterest';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook'; 
import './it.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
  decorate:{
    paddingBottom:'50px'
  }
  
})); 

export default function Footer() { 
   let history =useHistory();
   const sign_up = ()=>{
     history.push("/sign_up");
   }
  //  const widthMatch = useMediaQuery(
  //    json2mq({
  //      minWidth: 768,
  //    }),
  //  );

    const goPage =() =>{
      
    }
    const goAbout =() =>{ 
    }
    const goContact =() =>{ 
    }
    const goPrivacy =() =>{ 
    } 
    const goBlog =() =>{ 
    }
    const goWhite=() =>{
      history.push("/whitepaper");
    }
    const goTwitter = () =>{
      // window.open("https://twitter.com/VixenETH");
    } 
    const goTelgram = () =>{ 
    }
    

    const classes = useStyles();
    return (
      <div className="FooterSection" >  
          
          <div className={classes.root}>
            <div className={classes.decorate}>
               <img src={CloudShape} className={"CloudShapeImg"} alt=""/>
            </div> 
                
            <Grid  container spacing={0}>  
                <Grid   item xs={12} sm={3} className={classes.heart}>
                   <div className="joinParentWrap" >
                     <div className={"joinWrap"}>
                        <div className="heartImg"><img src={HeartImg} className={"heartImg"} alt="" /></div>
                        <div className={"vixenBtn"}><Button className={"join-button"} onClick={sign_up}>Join Vixen!</Button></div>  
                     </div> 
                   </div>
                   
                </Grid>
                <Grid   item xs={12} sm={8} >
                  <div>
                    <div>
                          <Grid  container spacing={0}>  
                              <Grid   item xs={6} md={3} className={"itemWrap"}>
                                <p className={"footerMenuItem"}>COMPANY</p>
                                  <p className={"footerMenu"} onClick={()=>goAbout()}> <DoubleArrowIcon className={"icons"}/>About</p>
                                  <p className={"footerMenu"} onClick={()=>goWhite()}> <DoubleArrowIcon className={"icons"}/>WhitePaper</p>
                                  <p className={"footerMenu"} onClick={()=>goContact()}> <DoubleArrowIcon className={"icons"}/>Contact</p> 
                              </Grid>
                              <Grid   item xs={6} md={3} className={"itemWrap"}>
                                <p className={"footerMenuItem"}>CONDITIONS</p>
                                  <p className={"footerMenu"} onClick={()=>goPrivacy()}> <DoubleArrowIcon className={"icons"}/>Privacy</p>
                                   
                              </Grid> 
                              <Grid   item xs={6} md={3} className={"itemWrap"}>
                                <p className={"footerMenuItem"}>FOLLOW</p>
                                  <p className={"footerMenu"} onClick={()=>goBlog
                                    ()}> <DoubleArrowIcon className={"icons"}/>Blog</p>
                                  <p className={"footerMenu"} onClick={()=>goPage()}><DoubleArrowIcon className={"icons"}/>Tech Blog</p>
                              </Grid>
                              <Grid   item xs={6} md={3} className={"itemWrap"}>
                                <p className={"footerMenuItem"}> SPECIAL</p>
                                  <p className={"footerMenu"} onClick={()=>goPage()}> <DoubleArrowIcon className={"icons"}/>Redeem Promotion</p>
                                  <p className={"footerMenu"} onClick={()=>goPage()}><DoubleArrowIcon className={"icons"}/>Download Apps</p>
                              </Grid>                            
                          </Grid>
                    </div>
                    <div>
                      <p className={"divider"}></p> 
                      <Grid container spacing={0}>
                         <Grid   item xs={12} sm={8} className="copyWrap">
                            <p className={"copyright"}>Copyright © 2021.All Rights Reserved By Vixenapp.io</p>
                         </Grid>
                         <Grid   item xs={12} sm={4}>
                            <div className="footerWrap">
                                <TelegramIcon className="fIcons" onClick={()=>goTelgram()} />
                                <FacebookIcon className="fIcons"/>
                                <TwitterIcon className="fIcons" onClick={()=>goTwitter()}/>  
                            </div> 
                         </Grid>
                      
                      </Grid>
                    </div>
                  </div>                  
                </Grid> 
            </Grid>
                 
                       
          </div>  
      </div>
    );
  }