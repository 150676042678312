import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import cnLocale from "date-fns/locale/zh-CN";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});

function SaleList() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [platform, setPlatform] = useState('');
    const changePlatform = (event) => {
        setPlatform(event.target.value);
    };


    useEffect(() => {

    }, []);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} className={'row-wrap-g erp-center'}>
                    <Button variant="contained" id="upload_report" startIcon={<UploadIcon />} onClick={() => setOpen(true)}>
                        报表上传
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} className={'row-wrap-g erp-center'}>
                    <Button variant="contained" id="upload_manual" startIcon={<EditIcon />}>
                        手工录入
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} md={6} className="erp-center erp-mt-20">
                                <span>销售平台</span>
                            </Grid>
                            <Grid item xs={12} md={6} className="erp-center erp-mt-20">
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="erp-w-50">
                                    <Select value={platform} onChange={changePlatform}>
                                        <MenuItem className="erp-ml-10" value={10}>P1</MenuItem>
                                        <MenuItem className="erp-ml-10" value={20}>P2</MenuItem>
                                        <MenuItem className="erp-ml-10" value={30}>P3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} className="erp-center erp-mt-20">
                                <span>销售时间</span>
                            </Grid>
                            <Grid item xs={12} md={6} className="erp-center erp-mt-20">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} className="erp-w-50">
                                    <DatePicker value={selectedDate} onChange={handleDateChange} className="erp-w-50" />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={12} className="erp-center erp-mt-40">
                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" multiple type="file" />
                                    <Button variant="contained" id="upload" component="span" startIcon={<UploadIcon />}>
                                        上传报告
                                    </Button>
                                </label>
                            </Grid>
                        </Grid> 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                        取消
                    </Button>
                    <Button variant="outlined" id="upload" >
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}
export default SaleList;