import React, { useEffect, useState} from "react";
import { useSelector, useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import { setErpBoxTag } from "../../../../data/Status/Action";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import './it.css';

function ManageBoxTag() {
    const api_prefix  = process.env.REACT_APP_API_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [mainCategoryChange, setMainCategoryChange] = useState("");
    const [categoryData, setCategoryData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const category = useSelector((state) => state.status.setBoxTags);
    const dispatch = useDispatch();

    useEffect(() => { 
        setCategoryData(category); 
    }, [category]);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const dataUpdate = () => {
        // const token = localStorage.getItem('token'); 
        // const headers = {
        //   'Authorization': `Bearer ${token}`,  
        // }  
        // const data = {
        //   id: 'profile_type',
        //   data: profileData
        // }  
        // console.log(data)
        // axios.put('https://vnrefxuxd6.execute-api.us-east-2.amazonaws.com/dev/data', data, { headers })
        //     .then(response => { 
        //       notify_success();

        //     }).catch((error)=>{ 
        //       notify_fail();
        //     }); 
    }
    const addMainCategory = () => {
        let temp = { ...categoryData };
        temp[mainCategoryChange] = [];
        setCategoryData(temp);
        console.log(">>", temp)
        setOpen(false);
        setMainCategoryChange("");
        dispatch(setErpBoxTag(temp));
    }

    const removeMainCategory = (keys) => {
        let temp = { ...categoryData };
        delete temp[keys];
        setCategoryData(temp);
        dispatch(setErpBoxTag(temp));
    }

    const addSubCategory = (c, i, k, d) => {
        let temp = { ...categoryData };
        if (c === 1) {
            let sc = document.getElementById(`sc-field-${d}`).value;
            if (sc == "") return;
            temp[k].push(sc);
            document.getElementById(`sc-field-${d}`).value = "";
        } else {
            temp[k].splice(i, 1);
        }
        setCategoryData(temp);
        dispatch(setErpBoxTag(temp));
        console.log(temp);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMainCategoryChange("");
    };

    const handleChangeMainCategory = (event) => {
        setMainCategoryChange(event.target.value)
    }


    return (
        <Container maxWidth="xl">  
            <Container spacing={1}>
                <Grid container>
                    <Grid item md={1} xs={1}>
                        <h3 className={"subtitle"}>主分类</h3>
                    </Grid>
                    <Grid item md={1} xs={1} className="wrap-center">
                        <IconButton onClick={() => handleClickOpen()}>
                            <AddCircleIcon className="primary-color-btn-icon" />
                        </IconButton>
                    </Grid> 
                </Grid>
            </Container>
            {
                categoryData && Object.keys(categoryData).map((keys, indexs) => (
                    <>
                        <div className="row-wrap">
                            <h5 className={'scope-title my-font-b'}>{keys} : </h5>
                            <DeleteIcon className={'del-icon my-icon'} onClick={() => removeMainCategory(keys)} />
                        </div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} className={'scope-part'}>
                                <div className={'scope-wrap'}>
                                    <ul className='tag-wrap'>
                                        {categoryData[keys].map((item, index) => {
                                            return (
                                                <li className='tag-item'>
                                                    <span>{item}</span>
                                                    <IconButton onClick={() => addSubCategory(0, index, keys, indexs)} className={'icon-btn'} aria-label="delete" color="primary">
                                                        <DeleteIcon className={'del-icon'} />
                                                    </IconButton>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <TextField onKeyDown={(e) => e.key === 'Enter' ? addSubCategory(1, 0, keys, indexs) : null} id={`sc-field-${indexs}`} className={'tag-input'} variant="standard" />
                                </div>
                            </Grid>
                        </Grid><br />
                    </>
                ))
            }
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={false}
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b">添加主要类别</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            defaultValue=""
                            value={mainCategoryChange}
                            variant="filled"
                            size="small"
                            className="my-font-l"
                            onChange={handleChangeMainCategory}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="my-font-b" onClick={addMainCategory}>确认</Button>
                    <Button className="my-font-b" onClick={handleClose}>取消</Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}
export default ManageBoxTag;

