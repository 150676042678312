import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './it.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ManageTag from "./part/ManageTag";
import SampleUpload from "./part/SampleUpload";
import EditSample from "./part/EditSample";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function SampleManage(props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {

    }, []);
    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 2, borderColor: 'divider' }}
                    >
                        <Tab label="上传图片" {...a11yProps(0)} />
                        <Tab label="标签管理" {...a11yProps(1)} />
                        <Tab label="编辑样本" {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={10} className="manage-scope">
                    <TabPanel value={value} index={0}>
                        <SampleUpload />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ManageTag />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <EditSample />
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
}
export default SampleManage;

