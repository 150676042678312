import Navbar from '../components/Global/Header/Nav';  
import Banner from '../components/Profile/Banner/Banner';
import Footer from '../components/Global/Footer/Footer';  
import Recommend from '../components/Profile/Recommend/Recommend';
import MyselfSummary from '../components/Profile/Myselfsummary/Myselfsummary'; 

function ProfilePage() {
  return (
    <div >
       <Navbar/>  
       <Banner/>
       <Recommend/>
       <MyselfSummary/> 
       <Footer/>
    </div>
  );
}

export default ProfilePage;
