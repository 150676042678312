import React, { useEffect, useState } from "react";
import avatar from '../../images/dashboard/avatar1.png';
import { Button, CircularProgress, Container, Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { setErpBoxTag } from "../../data/Status/Action"; 

const roles = ["账户管理", "订单和采购", "销售明细", "仓库管理", "供应链管理", "图库管理", "AI设计", "全部"];

function UserList(props) {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [role, setRole] = useState([]);
    // const [user, setUser] = useState({name:'', pw:'', email:'', role:[]});
    const [info, setInfo] = useState("");
    const [email, setEmail] = useState("");
    const [pw, setPw] = useState("");
    const [id, setId] = useState("");
    const [users, setUsers] = useState([])
    const [selUser, setSelUser] = useState([]);
    const [editState, setEditState] = useState(false);

    useEffect(() => {
        var temp = [];
        roles.forEach((item) => {
            temp.push(false);
        });
        setChecked(temp);
        axios.get(api_prefix + "/users/75e6216b-c34f-4b49-94cc-f92ee40ab17f")
            .then((response) => {
                setUsers(response.data);
                var temp = [];
                response.data.forEach(() => {
                    temp.push(false);
                })
                setSelUser(temp);
                setLoading(false);
            })
            .catch((error) => {
                throw (error);
            })
    }, []);

    const handleChange = (event, index) => {
        var roleTemp = [];
        let temp = [...checked];
        temp[index] = event.target.checked;
        setChecked(temp);
        temp.forEach((i, index) => {
            if (i) {
                roleTemp.push(roles[index])
            }
        });
        setRole(roleTemp);
    };
    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const handleClickOpen = () => {
        setEmail("");
        setInfo("");
        setPw(""); 
        setEditState(false);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const addUser = () => {
        if (editState == false) {
            const user = {
                id: id,
                email: email,
                info: info,
                e_roles: role,
                pw: pw
            }
            if (email != "" && info != "" && pw != "") {
                axios.post(api_prefix + "/user", user)
                    .then((response) => {
                        var temp = [...users];
                        temp.push(response.data);
                        setUsers(temp);
                        notify_success();
                    })
                    .catch((error) => {
                        notify_fail();
                        throw (error);
                    })
                setOpen(false);
            } else {
                alert("您必须填写所有信息")
            }
        } else {
            const user = {
                id: id,
                email: email,
                info: info,
                e_roles: role,
                pw: pw
            }
            if (email != "" && info != "" && pw != "") {
                axios.put(api_prefix + "/user", user)
                    .then((response) => {
                        var temp = [...users];
                        temp.forEach((i, index) => {
                            if (response.data.id === i.id) {
                                temp[index] = response.data;
                            }
                        });
                        setUsers(temp);
                        notify_success();
                    })
                    .catch((error) => {
                        notify_fail();
                        throw (error);
                    })
                setOpen(false);
            } else {
                alert("您必须填写所有信息")
            }
        }
    }

    const handlePhone = (event) => {
        setEmail(event.target.value)
    }
    const handleName = (event) => {
        setInfo(event.target.value)
    }
    const handlePw = (event) => {
        setPw(event.target.value)
    }
    const handleCheckUser = (e, user, index) => {
        var temps = [];
        selUser.forEach((sel, i) => {
            temps[i] = false;
            if (i == index) {
                temps[i] = true;
            }
        });
        setSelUser(temps);
        if (e.target.checked) {
            // setUser(user); 
            var temp = Array(checked.length).fill(false)
            user.e_roles.forEach((i) => {
                temp[roles.indexOf(i)] = true;
            })
            setChecked(temp);
            setEmail(user.email);
            setPw(user.pw);
            setRole(user.e_roles);
            setInfo(user.info);
            setId(user.id);
        } else {
            var temp = {
                info: '',
                email: '',
                pw: '',
                e_roles: []
            }
            setEmail("");
            setPw("");
            setRole([]);
            setInfo("");
            setId("");
        }
    }
    const eidtPermission = () => {
        setEditState(true);
        setOpen(true);
    }
    const deleteUser = () => {
        axios.delete(api_prefix + "/user/" + id)
            .then((response) => {
                var temp = [...users];
                temp.forEach((i, index) => {
                    if (response.data === i.id) {
                        temp.splice(index, 1);
                    }
                });
                setUsers(temp);
                notify_success();
            })
            .catch((error) => {
                throw (error);
            })
    }

    return (
        <Container maxWidth="xl" className="user-scope">
            <Grid container spacing={2}>
                {
                    loading ?
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        </Grid> : <></>
                }
                <Grid item xs={12} md={12} className="header-wrap">
                    <div>
                        <IconButton onClick={handleClickOpen}>
                            <AddCircleIcon className="primary-color-btn-icon" />
                        </IconButton>
                    </div>
                    <div>
                        <Button variant="outlined" color="primary" className="primary-color-outline primary-ml" onClick={eidtPermission}>编辑</Button>
                        <Button variant="outlined" color="primary" className="primary-color-outline primary-ml" onClick={deleteUser}>删除</Button>
                    </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <div className="table-row">
                        <div className="table-cell cell-f">手机号码</div>
                        <div className="table-cell cell-s">姓名</div>
                        <div className="table-cell cell-t">权限</div>
                    </div>
                    {
                        loading == false && users.map((user, index) => {
                            return (
                                <div className="table-row" key={index}>
                                    <div className="table-cell cell-f">
                                        <Checkbox color="primary" className="check-row" checked={selUser[index]} onChange={(e) => handleCheckUser(e, user, index)} />
                                        <span>{user.email}</span>
                                    </div>
                                    <div className="table-cell cell-s">{user.info}</div>
                                    <div className="table-cell cell-t">{user.e_roles.toString()}</div>
                                </div>
                            );
                        })
                    }
                </Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b">新用户</DialogTitle>
                <DialogContent className="diag-cont">
                    <Grid container spacing={1} className="info-wrap">
                        <Grid item md={4}>
                            <TextField
                                id="outlined-textarea-source1"
                                label="姓名"
                                placeholder="姓名"
                                onChange={handleName}
                                value={info}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                id="outlined-textarea-source2"
                                label="手机号码"
                                placeholder="手机号码"
                                onChange={handlePhone}
                                value={email}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                id="outlined-textarea-source3"
                                label="密码"
                                placeholder="密码"
                                type="password"
                                onChange={handlePw}
                                value={pw}
                            />
                        </Grid>

                    </Grid>

                    <div className="divider-wrap">
                        <Divider className="divider-m" />
                    </div>
                    <div>
                        <p className="my-font-b">权限</p>
                    </div>
                    <Grid container spacing={2}>
                        {
                            roles.map((item, index) => {
                                return (
                                    <Grid item xs={12} md={4} key={index}>
                                        <FormControlLabel className="check-box-wrap" control={<Checkbox defaultChecked checked={checked[index]} onChange={(e) => handleChange(e, index)} inputProps={{ 'aria-label': 'controlled' }} />} label={`${item}`} />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" className="primary-color-outline primary-ml btn-primary-mw" onClick={addUser}>加入</Button>
                    <Button variant="outlined" color="primary" className="primary-color-outline primary-ml btn-primary-mw" onClick={handleClose}>取消</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
        </Container>

    );
}
export default UserList;

