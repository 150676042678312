import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const products = [
    createData('6008', 159, 6.0, 24, 4.0),
    createData('8746', 237, 9.0, 37, 4.3),
];

function AuditRecord() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [companyType, setCompanyType] = useState('');

    useEffect(() => {

    }, []);

    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changeCompanyType = (event) => {
        setCompanyType(event.target.value);
    };

    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="xl" className="sample-scope" >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <OutlinedInput
                                    endAdornment={<IconButton><SearchIcon /></IconButton>}
                                    value={searchVal}
                                    onChange={handleSearch}
                                />
                                {/* <IconButton className="erp-ml-10" onClick={() => setOpen(true)}>
                                    <AddCircleIcon className="erp-primary-color-btn-icon" />
                                </IconButton> */}
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TabContext value={tabValue}>
                                    <Grid container>
                                        <Grid item md={12} xs={12}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList onChange={handleTab} aria-label="lab API tabs example">
                                                    <Tab label="资料" value="1" />
                                                    <Tab label="供应商" value="2" />
                                                </TabList>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TabPanel value="1">


                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>公司名称</TableCell>
                                                                <TableCell align="right">合同号</TableCell>
                                                                <TableCell align="right">状态</TableCell>
                                                                <TableCell align="right">销售单号</TableCell>
                                                                <TableCell align="right">销售单号</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow
                                                                    key={row.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                                                    <TableCell align="right">{row.calories}</TableCell>
                                                                    <TableCell align="right">{row.calories}</TableCell>
                                                                    <TableCell align="right">{row.fat}</TableCell>
                                                                    <TableCell align="right">
                                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" >
                                                                            配镜完成
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>  
                                            </TabPanel>
                                            <TabPanel value="2">  
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>公司名称</TableCell> 
                                                                <TableCell align="right">销售单号</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow
                                                                    key={row.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">{row.name}</TableCell> 
                                                                    <TableCell align="right">
                                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" >
                                                                            配镜完成
                                                                        </Button>
                                                                    </TableCell> 
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>  
                                            </TabPanel>
                                        </Grid>  
                                    </Grid>  
                                </TabContext>
                            </Grid>
                        </Grid>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                        <ToastContainer />
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <TabContext value={tabValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTab} aria-label="lab API tabs example">
                                                <Tab label="公司" value="1" />
                                                <Tab label="个人" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Grid container>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="公司名称"
                                                        placeholder="公司名称"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="company-type">公司性质</InputLabel>
                                                            <Select
                                                                labelId="company-type"
                                                                id="company-type"
                                                                value={companyType}
                                                                label="company"
                                                                onChange={changeCompanyType}
                                                            >
                                                                <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                                                <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                                                <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="联系人"
                                                        placeholder="联系人"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="电话"
                                                        placeholder="电话"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="地址"
                                                        placeholder="地址"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <Grid container>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="公司名称"
                                                        placeholder="公司名称"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="company-type">公司性质</InputLabel>
                                                            <Select
                                                                labelId="company-type"
                                                                id="company-type"
                                                                value={companyType}
                                                                label="company"
                                                                onChange={changeCompanyType}
                                                            >
                                                                <MenuItem value={10} className="erp-ml-10 erp-sel">P1</MenuItem>
                                                                <MenuItem value={20} className="erp-ml-10 erp-sel">P2</MenuItem>
                                                                <MenuItem value={30} className="erp-ml-10 erp-sel">P3</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="联系人"
                                                        placeholder="联系人"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="电话"
                                                        placeholder="电话"
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} className="erp-mt-20 erp-pr-10">
                                                    <TextField
                                                        className="erp-w-100"
                                                        label="地址"
                                                        placeholder="地址"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                                    取消
                                </Button>
                                <Button variant="outlined" id="upload" >
                                    确认
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
export default AuditRecord;