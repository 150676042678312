import { VX_STATUS_ACTIONS, ERP_STATUS_ACTIONS } from "./Action";


const defaultState = {};

const Reducer = (state = defaultState, action) => {
    switch (action.type){
        case ERP_STATUS_ACTIONS.SET_SAMPLE_TAGS:
          return {...state, setSampleTags: action.payload};
        case ERP_STATUS_ACTIONS.SET_BOX_TAGS:
          return {...state, setBoxTags: action.payload};
        case ERP_STATUS_ACTIONS.SET_LEG_TAGS:
          return {...state, setLegTags: action.payload};
        case ERP_STATUS_ACTIONS.SET_PAGE_INDEX:
          return {...state, setPageIndex: action.payload};
        case ERP_STATUS_ACTIONS.SET_VARIABLE:
          return {...state, setVariable: action.payload};
        case ERP_STATUS_ACTIONS.SET_SUP_MODEL:
          return {...state, setSupModel: action.payload};
        case VX_STATUS_ACTIONS.SHOW_CHAT:
          return {...state, showChat: action.payload};
        case VX_STATUS_ACTIONS.SHOW_PAPER:
            return {...state, showPaper: action.payload};      
        default:
          return state;        
    }
};

export default Reducer