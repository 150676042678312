import React from 'react';
import { useHistory } from "react-router-dom"; 
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import BannerBackImg from '../../../images/vixen_profile_back.jpg';
import RecommendIcon from '@mui/icons-material/Recommend';
import PaymentIcon from '@mui/icons-material/Payment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import NearMeIcon from '@mui/icons-material/NearMe';

import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq'; 
import BannerImg from '../../../images/vixen_profile_back.jpg';
import './it.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,  
    backgroundImage: `url(${BannerBackImg})`,
    paddingTop: '70px',
    paddingBottom: '50px',
  },   
}));

export default function Banner() {
  const classes = useStyles();  

  const widthMatch = useMediaQuery(
    json2mq({
      minWidth: 600,
    }),
  );
  const isMobile = useMediaQuery(
    json2mq({
      minWidth: 465,
    }),
  );

  const goTerm = ()=>{

  }
  

  return (
    <div className={classes.root}>
    <Container  maxWidth="lg">
      <Grid  container spacing={3}> 
            <Grid  item xs={12} md={3}>
                <p className={"profileTitle"}>Profile</p>
            </Grid>       
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <RecommendIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Recommend</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <PaymentIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Passport</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <FavoriteBorderIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>SuperLike</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <TrackChangesIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Match</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <HealthAndSafetyIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Vacinated</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <OnlinePredictionIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Online</p>
                </div>  
            </Grid>
            <Grid   item xs={3} md={1}>
                <div className={"btnWrap"}>
                    <NearMeIcon className={"bannerBtn"} />
                    <p className={"btnDescription"}>Nearby</p>
                </div>  
            </Grid>
            <Grid  item xs={12} md={2}> 
            </Grid>
            
      </Grid>
      </Container>
    </div>
  );
}
