import React, {useEffect} from "react";   
import { makeStyles } from '@material-ui/core/styles'; 
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button"; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import VixenContactImg_1 from '../../../images/contact/wixen_contact2.png';    
import VixenContactImg_2 from '../../../images/contact/wixen_contact.png';   
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';  
 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,  
    backgroundColor: '#fafbff',  
    paddingTop:'74px',
    paddingBottom:'70px',
    textAlign:'center',
    position:'relative'
  },
  formWrap:{
    textAlign:'center'
  },
  wrap:{
    textAlign:'center',
    display: 'block',
    zIndex:'9'
  }, 
  left:{
    position: 'absolute',
    left: '0px',
    top: '40%',
  },
  right:{
    position: 'absolute',    
    right: '0px',
    top: '40%',
  },   
  
}));

export default function Contact() {
  const classes = useStyles();  

  useEffect(() => {  
    
  }, []);  

  const widthMatch = useMediaQuery(
    json2mq({
      minWidth: 1000,
    }),
  );
  // const isMobile = useMediaQuery(
  //   json2mq({
  //     minWidth: 465,
  //   }),
  // );
   
  return (
    <div className={classes.root}>
           {widthMatch?
              <img src={VixenContactImg_1} className={classes.left}  alt="vixen_dating"/>:
              <span></span> 
           } 
           <img src={VixenContactImg_2} className={classes.right} alt="vixen_dating"/>
         
        <Container  maxWidth="lg">
            <Grid container spacing={1} className={classes.wrap}>
                <h3 className={"memberTitle"}>Contact Us</h3>
                <h1 className={"memberPlan"}>Get in Touch</h1> 
                <h3>We'd love to hear from you! Let us know how we can help.</h3>
                <div>
                    <Grid container spacing={1}>
                        <Grid  item xs={1} md={3} ></Grid>
                        <Grid  item xs={10} md={6} className={classes.formWrap}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                name="name"
                                autoComplete="full name"
                                variant="standard"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                variant="standard"
                            /> 
                            <TextField
                                id="message"
                                fullWidth
                                label="Message"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="standard"
                            />
                            <Button className={"vixen_button_purple"}  variant="contained"> Send </Button> 
                        </Grid> 
                        <Grid  item xs={1} md={3} ></Grid>
                    </Grid>
                </div>
               
            </Grid> 
        
        </Container>
    </div>
  );
}
