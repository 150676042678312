import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const products = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
];



function SpotSample() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const user = useSelector((state) => state.user.signInfo);
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0);

    const [items, setItems] = useState(null);
    const [c_name, setC_name] = useState("");
    const [contact, setContact] = useState("");
    const [delivery, setDelivery] = useState(null);
    const [s_num, setS_num] = useState("");
    const [pay, setPay] = useState("");
    const [uid, setUid] = useState("");

    const [extra, setExtra] = useState([]);
    const [model, setModel] = useState("");
    const [sku, setSku] = useState("");
    const [price, setPrice] = useState(0);
    const [quanty, setQuanty] = useState(0);
    const [sum, setSum] = useState(0);
    const [modelList, setModelList] = useState([]);
    const [skuList, setSkuList] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const [spotList, setSpotList] = useState([]);

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);

    useEffect(() => { 
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if(roles.includes('全部')){
                    setHasRole(true);
                }
            } else {
                
            }
        }else{
            //history.push("/sign_in");
        }
    }, [user])

    useEffect(() => {
        setLoaded(true)
        axios.get(api_prefix + '/product')
            .then(res => {
                var temp = [];
                res.data.forEach((item) => {
                    temp.push(item.p_name);
                })
                setModelList(temp);
                axios.get(api_prefix + '/customer')
                    .then(res => {
                        var resData = res.data;
                        var temp = [];
                        resData.forEach((item) => {
                            temp.push(item.c_name);
                        })
                        setCustomerList(temp);
                    }).catch((error) => {
                    });
                setLoaded(false)
            }).catch((error) => {
            });
        axios.get(api_prefix + '/spot')
            .then(res => {
                setSpotList(res.data);
            }).catch((error) => {
            });

    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changePay = (event) => {
        setPay(event.target.value);
    };

    const selectModel = (e, i) => {
        var model = "";
        if (i == 1) {
            model = e.target.value;
        } else {
            model = e;
        }
        setModel(e.target.value);
        setLoaded(true);
        var data = {
            mode: 1,
            model
        }
        axios.patch(api_prefix + '/product', data)
            .then(res => {
                var temp = res.data;
                setSkuList(temp);
                setLoaded(false)
                setSku(temp[0].sku);
            }).catch((error) => {
            });
    }

    const setPriceData = (e) => {
        setPrice(e.target.value);
        setSum(e.target.value * quanty);
    }

    const setQuantyData = (e) => {
        setQuanty(e.target.value);
        setSum(e.target.value * price);
    }

    const createOrderNum = () => {
        const t = Date.now().toString();
        var od = "S" + t.substring(1, 10);
        setS_num(od);
    }

    const addNew = () => {
        setEditMode(0);
        createOrderNum();
        setOpen(true);
    }

    const editItem = (item, index) => {
        setItems(item);
        setEditMode(1);
        setEditIndex(index);
        setUid(item.id);
        setC_name(item.c_name);
        setContact(item.info.contact);
        setDelivery(item.info.delivery);
        setPay(item.info.pay);
        setExtra(item.extra);
        setS_num(item.info.s_num);
        setOpen(true);
    }

    const addExtra = () => {
        var e_data = {
            model,
            sku,
            price,
            quanty,
            sum,
        };
        var temp = [...extra];
        temp.push(e_data);
        setExtra(temp);
    }

    const removeItem = (item, index) => {
        var temp = [...extra];
        temp.splice(index, 1);
        setExtra(temp);
    }

    const initData = () => {
        setC_name("");
        setContact("");
        setDelivery("");
        setPay("");
        setExtra([]);
        setPrice(0);
        setQuanty(0);
        setSum(0);
    }

    const saveOne = () => {
        var data = {
            c_name,
            info: {
                contact,
                delivery,
                s_num,
                pay,
                state: items.info.state,
                at: items.info.at,
                mt: Date.now(),
                creator
            },
            extra
        }
        setLoaded(true);
        axios.post(api_prefix + '/spot', data)
            .then(res => {
                var temp = [...spotList];
                temp.push(res.data);
                setSpotList(temp);
                setLoaded(false);
                initData();
                setOpen(false);
                notify_success("成功添加!");
            }).catch((error) => {
                notify_fail("网络问题，请检查!");
            });
    }

    const addInfo = () => {
        if (editMode == 0) {
            saveOne();
        }
    }

    const getDate = (t) => {
        var date = new Date(t);
        return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    }
    const getQuanty = (item) => {
        var q = 0;
        item.forEach((e) => {
            q += e.quanty * 1;
        })
        return q;
    }
    const getAmount = (item) => {
        var q = 0;
        item.forEach((e) => {
            q += e.sum * 1;
        })
        return q;
    }

    const updateInfo = () => {
        var data = {
            id: uid,
            c_name,
            info: {
                contact,
                delivery,
                s_num,
                pay,
                state: items.info.state,
                at: items.info.at,
                mt: Date.now(),
                creator
            },
            extra
        }
        setLoaded(true);
        axios.put(api_prefix + '/spot', data)
            .then(res => {
                var temp = [...spotList];
                temp[editIndex] = res.data;
                setSpotList(temp);
                setLoaded(false);
                initData();
                setOpen(false);
                notify_success("成功更新!");
            }).catch((error) => {
                notify_fail("网络问题，请检查!");
            });
    }

    const deleteItem = () => {
        setLoaded(true);
        axios.delete(api_prefix + '/spot/' + items.id)
            .then(res => {
                var temp = [...spotList];
                temp.splice(editIndex, 1);
                setSpotList(temp);
                setLoaded(false);
                initData();
                setOpen(false);
                notify_success("成功更新!");
            }).catch((error) => {
                notify_fail("网络问题，请检查!");
            });
    }

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        endAdornment={<IconButton><SearchIcon /></IconButton>}
                        value={searchVal}
                        onChange={handleSearch}
                    />
                    <IconButton className="erp-ml-10" onClick={() => addNew()}>
                        <AddCircleIcon className="erp-primary-color-btn-icon" />
                    </IconButton>

                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>日期</TableCell>
                                    <TableCell align="right">销售单号</TableCell>
                                    <TableCell align="right">客户名称</TableCell>
                                    <TableCell align="right">数量</TableCell>
                                    <TableCell align="right">金额</TableCell>
                                    <TableCell align="right">支付</TableCell>
                                    <TableCell align="right">物流</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {spotList.map((item, index) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{getDate(item.info.mt)}</TableCell>
                                        <TableCell align="right">{item.info.s_num}</TableCell>
                                        <TableCell align="right">{item.c_name}</TableCell>
                                        <TableCell align="right">{getQuanty(item.extra)}</TableCell>
                                        <TableCell align="right">{getAmount(item.extra)}</TableCell>
                                        <TableCell align="right">{item.info.pay}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" >
                                                已发货
                                            </Button>
                                            <Button variant="contained" className="erp-orb-color erp-ml-5 erp-btn-padding" onClick={() => editItem(item, index)}>
                                                编辑
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b"> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12} className="erp-mt-20 erp-pt-10">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel >客户名称</InputLabel>
                                        <Select
                                            value={c_name}
                                            label="客户名称"
                                            onChange={(e) => setC_name(e.target.value)}
                                        >
                                            {
                                                customerList.map((item) => {
                                                    return (
                                                        <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="联系方式"
                                    placeholder="联系方式"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={12} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="发货地址"
                                    placeholder="发货地址"
                                    value={delivery}
                                    onChange={(e) => setDelivery(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="erp-mt-20 erp-pt-10">
                                <TextField
                                    className="erp-w-100"
                                    label="销售单号"
                                    placeholder="销售单号"
                                    value={s_num}
                                    onChange={(e) => setS_num(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="erp-mt-20 erp-pt-10">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="pay-sel">支付</InputLabel>
                                        <Select
                                            labelId="pay-sel"
                                            id="pay-sel"
                                            value={pay}
                                            label="platform"
                                            onChange={changePay}
                                        >
                                            <MenuItem value={'支付'} className="erp-ml-10 erp-sel">支付</MenuItem>
                                            <MenuItem value={'未支付'} className="erp-ml-10 erp-sel">未支付</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} className="erp-mt-20 erp-pt-10">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>产品型号</TableCell>
                                                <TableCell align="right">产品SKU</TableCell>
                                                <TableCell align="right">单价</TableCell>
                                                <TableCell align="right">数量</TableCell>
                                                <TableCell align="right">金额</TableCell>
                                                <TableCell align="right">笔记</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {extra.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{item.model}</TableCell>
                                                    <TableCell align="right">{item.sku}</TableCell>
                                                    <TableCell align="right">{item.price}</TableCell>
                                                    <TableCell align="right">{item.quanty}</TableCell>
                                                    <TableCell align="right">{item.sum}</TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => removeItem(item, index)}>
                                                            删除
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableBody>
                                            <TableRow
                                                key={'i-data'}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <select className="erp-sel-w" name="model" onChange={(e) => selectModel(e, 1)}>
                                                        {
                                                            modelList.map((item, index) => {
                                                                return (
                                                                    <option value={item}>{item}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <select className="erp-sel-w" name="model" onChange={(e) => setSku(e.target.value)}>
                                                        {
                                                            skuList.map((item, index) => {
                                                                return (
                                                                    <option value={item.sku}>{item.sku}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </TableCell>
                                                <TableCell align="right"><input className="erp-sel-w" type="number" min={0} value={price} onChange={(e) => setPriceData(e)} /></TableCell>
                                                <TableCell align="right"><input className="erp-sel-w" type="number" min={0} value={quanty} onChange={(e) => setQuantyData(e)} /></TableCell>
                                                <TableCell align="right"><input className="erp-sel-w" type="number" value={sum} /></TableCell>
                                                <TableCell align="right">
                                                    <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addExtra()}>
                                                        添加
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                        取消
                    </Button>
                    {editMode == 0 ?
                        <Button variant="outlined" id="upload" onClick={() => addInfo()}>
                            确认并发至仓库
                        </Button> :
                        <>
                            <Button variant="outlined" id="upload" onClick={() => deleteItem()}>
                                删除
                            </Button>
                            <Button variant="outlined" id="upload" onClick={() => updateInfo()}>
                                更新
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default SpotSample;