import { useHistory } from "react-router-dom"; 
import axios from 'axios';
const api_prefix  = process.env.REACT_APP_API_PREFIX;

export const VX_STATUS_ACTIONS = {
    SHOW_CHAT : 'VX_SHOW_CHAT', 
    SHOW_PAPER : 'VX_SHOW_PAPER',
    SET_CART:'VX_SET_CART'
}

export const ERP_STATUS_ACTIONS = {
    SET_SAMPLE_TAGS : 'ERP_SAMPLE_TAGS',
    SET_BOX_TAGS : 'ERP_BOX_TAGS',
    SET_LEG_TAGS : 'ERP_LEG_TAGS',
    SET_PAGE_INDEX : 'ERP_PAGE_INDEX',
    SET_VARIABLE : 'ERP_VARIABLE',
    SET_SUP_MODEL : 'ERP_SUP_MODEL',
}

export const setErpSampleTag = (payload) => ({
    type: ERP_STATUS_ACTIONS.SET_SAMPLE_TAGS,
    payload,
});

export const setErpSampleTags =  (payload) => (dispatch) =>{ 
    dispatch(setErpSampleTag(payload));
    const data = {
        id: 'glassTag',
        data: payload
    } 
    axios.put(api_prefix + "/data", data)
      .then((response) => {  
      })
      .catch((error) => {
          console.log("res",error)
        throw (error);
      })
} 

export const setErpBoxTags = (payload) =>({
    type: ERP_STATUS_ACTIONS.SET_BOX_TAGS,
    payload,
});

export const setErpBoxTag =  (payload) => (dispatch) =>{ 
    dispatch(setErpBoxTags(payload));
    const data = {
        id: 'frameTag',
        data: payload
    } 
    axios.put(api_prefix + "/data", data)
      .then((response) => {  
      })
      .catch((error) => {
          console.log("res",error)
        throw (error);
      })
} 

export const setErpLegTags = (payload) =>({
    type: ERP_STATUS_ACTIONS.SET_LEG_TAGS,
    payload,
});

export const setErpLegTag=  (payload) => (dispatch) =>{ 
    dispatch(setErpLegTags(payload));
    const data = {
        id: 'legTag',
        data: payload
    } 
    axios.put(api_prefix + "/data", data)
      .then((response) => {  
      })
      .catch((error) => {
          console.log("res",error)
        throw (error);
      })
} 

export const setErpPageIndex = (payload) =>({
    type: ERP_STATUS_ACTIONS.SET_PAGE_INDEX,
    payload,
})

export const setErpVariable = (payload) => ({
    type: ERP_STATUS_ACTIONS.SET_VARIABLE,
    payload,
})

export const setErpSupModel = (payload) => ({
    type: ERP_STATUS_ACTIONS.SET_SUP_MODEL,
    payload,
})  

export const callErpSupModel = () => (dispatch) => {
    axios.get(api_prefix + "/product")
      .then((res) => {    
          dispatch(setErpSupModel(res.data))
      })
      .catch((error) => { 
        throw (error);
      })
}

export const setShowChat = (payload) =>({
    type: VX_STATUS_ACTIONS.SHOW_CHAT,
    payload,
});

export const setShowPaper = (payload) =>({
    type: VX_STATUS_ACTIONS.SHOW_PAPER,
    payload,
})

 