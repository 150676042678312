import Navbar from '../../components/Global/Header/Nav';
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, LinearProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import './it.css';
import SearchBox from './part/SearchBox';
import CategoryBox from './part/CategoryBox';
import ItemBox from './part/ItemBox';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));


function MatchPart() {
    return (
        <div >
            <Navbar />
            <Container maxWidth="lg" className='search-scope'>
                <h2>纯音科技管理系统</h2>
                <Grid container spacing={2}>
                    <SearchBox /> 
                </Grid>
            </Container>
        </div>
    );
}

export default MatchPart;
