import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './it.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ManageBoxTag from "./part/ManageBoxTag";
import LegUpload from "./part/LegUpload";
import FrameUpload from "./part/FrameUpload";
import ManageLegTag from "./part/ManageLegTag";
import FrameEdit from "./part/FrameEdit";
import LegEdit from "./part/LegEdit";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function AiDesign(props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {

    }, []);
    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 2, borderColor: 'divider' }}
                    >
                        <Tab label="上传框素材" {...a11yProps(0)} />
                        <Tab label="上传腿素材" {...a11yProps(1)} /> 
                        <Tab label="框的标签管理" {...a11yProps(2)} />
                        <Tab label="腿标签管理" {...a11yProps(3)} /> 
                        <Tab label="我的框素材" {...a11yProps(4)} />
                        <Tab label="我的腿素材" {...a11yProps(5)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={10} className="manage-scope">
                    <TabPanel value={value} index={0}>
                        <FrameUpload />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <LegUpload />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ManageBoxTag />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ManageLegTag />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <FrameEdit />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <LegEdit />
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
}
export default AiDesign;

