import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PlusOneIcon from '@mui/icons-material/PlusOne';



const attributesERP = ["成镜", "镜片", "包装", "眼镜配件", "电子配件", "辅料"];

function DataEntry() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const s3_prefix = process.env.REACT_APP_S3_PREFIX;
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [companyType, setCompanyType] = useState('');

    const [productList, setProductList] = useState([]);
    const [extraList, setExtraList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0);

    const [id, setId] = useState("");
    const [p_name, setP_name] = useState("");
    const [supplier, setSupplier] = useState("");
    const [price, setPrice] = useState(0);
    const [proImg, setProImg] = useState(null);
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [attribute, setAttribute] = useState("");
    const [model, setModel] = useState("");
    const [sku, setSku] = useState("");
    const [color, setColor] = useState("");
    const [other, setOther] = useState("");

    useEffect(() => {
        setLoaded(true)
        axios.get(api_prefix + '/product')
            .then(res => {
                var resData = res.data;
                var temp = [...productList];
                resData.forEach((item) => {
                    temp.push(item)
                })
                setProductList(temp);
                setLoaded(false)
            }).catch((error) => {
            });
        axios.get(api_prefix + '/supplier')
            .then(res => {
                var resData = res.data;
                var temp = [];
                resData.forEach((item) => {
                    temp.push(item.c_name);
                })
                setSupplierList(temp);
            }).catch((error) => {
            });
    }, []);

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setProImg(e.target.files[0]);
            setUrl("");
        }
    }

    const addExtra = () => {
        var temp = [...extraList];
        var data = {
            sku,
            color,
            other
        };
        temp.push(data);
        setExtraList(temp);
        setSku("");
        setColor("");
        setOther("");
    }
    const removeExtra = (index) => {
        var temp = [...extraList];
        temp.splice(index, 1);
        setExtraList(temp);
    }
    const uploadFile = async (dir, names) => {
        let reader = new FileReader();
        await reader.readAsDataURL(proImg);
        reader.onload = function () {
            var data = {
                dir: dir,
                img: reader.result,
                imgName: names
            }
            axios.post(api_prefix + "/uploadImg", data)
                .then((res) => {
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }
    const createProduct = () => {
        if (p_name === "" || supplier === "" || price == 0 || name === "" || proImg === null || attribute === "") {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var fileName = Date.now();
        var dir = "products";
        var n = proImg.name.split('.');
        var ext = n[n.length - 1];
        uploadFile(dir, fileName);
        var urls = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
        var data = {
            p_name,
            info: {
                supplier,
                price,
                name,
                url: urls,
                attribute,
                model
            },
            extra: extraList
        }
        axios.post(api_prefix + '/product', data)
            .then(res => {
                var temp = [...productList];
                temp.push(res.data)
                setProductList(temp);
                notify_success("创建成功");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("未创建！ 检查您的网络");
            });

    }

    const editProduct = async () => {
        if (p_name === "" || supplier === "" || price == 0 || name === "" || attribute === "" || model === "" || extraList.length == 0) {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var urls = "";
        if (url === "") {
            var fileName = Date.now();
            var dir = "products";
            var n = proImg.name.split('.');
            var ext = n[n.length - 1];
            uploadFile(dir, fileName);
            urls = 'https://erp-image.s3.ap-northeast-2.amazonaws.com/' + dir + '/' + fileName + "." + ext;
        } else {
            urls = url;
        }

        var data = {
            id,
            p_name,
            info: {
                supplier,
                price,
                name,
                url: urls,
                attribute,
                model
            },
            extra: extraList
        }
        axios.put(api_prefix + '/product', data)
            .then(res => {
                var temp = [...productList];
                temp[editIndex] = data;
                setProductList(temp);
                notify_success("成功更新");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const deleteProduct = () => {
        setLoaded(true)
        axios.delete(api_prefix + '/product/' + id)
            .then(res => {
                var temp = [...productList];
                temp.splice(editIndex, 1);
                setProductList(temp);
                notify_success("成功删除");
                setLoaded(false)
                setOpen(false);
            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const openCreateModal = () => {
        setId("");
        setP_name("");
        setSupplier("");
        setPrice("");
        setUrl("");
        setName("");
        setAttribute("");
        setModel("");
        setExtraList([]);
        setOpen(true);
    }
    const openEditModal = (item, index) => {
        setId(item.id);
        setP_name(item.p_name);
        setSupplier(item.info.supplier);
        setPrice(item.info.price);
        setUrl(item.info.url);
        setName(item.info.name);
        setAttribute(item.info.attribute);
        setModel(item.info.model);
        setExtraList(item.extra);
        setEditIndex(index);
        setEditMode(1);
        setOpen(true);
    }

    const confirmEdit = () => {
        if (editMode === 0) {
            createProduct();
        } else {
            editProduct();
        }
    }

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changeCompanyType = (event) => {
        setCompanyType(event.target.value);
    };

    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth="xl" className={'profile-container'}>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="xl" className="sample-scope" >
                        <Grid container spacing={3}>
                            {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                            <Grid item xs={12} md={12}>
                                <OutlinedInput
                                    endAdornment={<IconButton><SearchIcon /></IconButton>}
                                    value={searchVal}
                                    onChange={handleSearch}
                                />
                                <IconButton className="erp-ml-10" onClick={() => openCreateModal()}>
                                    <AddCircleIcon className="erp-primary-color-btn-icon" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>款号</TableCell>
                                                <TableCell align="right">价格</TableCell>
                                                <TableCell align="right">名称</TableCell>
                                                <TableCell align="right">供应商</TableCell>
                                                <TableCell align="right">明细</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productList && productList.map((item, index) => (
                                                <TableRow
                                                    key={item.p_name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{item.p_name}</TableCell>
                                                    <TableCell align="right">{item.info.price}</TableCell>
                                                    <TableCell align="right">{item.info.name}</TableCell>
                                                    <TableCell align="right">{item.info.supplier}</TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => openEditModal(item, index)} >
                                                            查看或更改
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>

                            </Grid>
                        </Grid>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                        <ToastContainer />
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle className="my-font-b"> </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Grid container>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="新增款号"
                                                placeholder="新增款号"
                                                value={p_name}
                                                onChange={(e) => setP_name(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="supplier">供应商</InputLabel>
                                                    <Select
                                                        labelId="supplier"
                                                        value={supplier}
                                                        label="paycycle"
                                                        onChange={(e) => setSupplier(e.target.value)}
                                                    >
                                                        {
                                                            supplierList.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="价格"
                                                placeholder="价格"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                type="number"
                                                InputProps={{ inputProps: { step: 0.1, min: 0 } }}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <input accept="image/*" type="file" onChange={imageChange} id="main-image" style={{ display: 'none' }} />
                                            <label htmlFor="main-image">
                                                <IconButton className="erp-ml-10" variant="contained" component="span" >
                                                    <FileUploadIcon className="erp-primary-color-btn-icon" /><span className="erp-font-s">图片上传</span>
                                                </IconButton>
                                            </label>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="名称"
                                                placeholder="名称"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="attributes">属性</InputLabel>
                                                    <Select
                                                        labelId="attributes"
                                                        id="attributes"
                                                        value={attribute}
                                                        label="attributes"
                                                        onChange={(e) => setAttribute(e.target.value)}
                                                    >
                                                        {
                                                            attributesERP.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                            <TextField
                                                className="erp-w-100"
                                                label="工厂款号"
                                                placeholder="工厂款号"
                                                value={model}
                                                onChange={(e) => setModel(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={12} className="erp-mt-20 erp-pr-10">
                                        </Grid>
                                        <Grid item xs={12} md={11} className="erp-mt-20 erp-pr-10">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 475 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>SKU</TableCell>
                                                            <TableCell align="right">颜色</TableCell>
                                                            <TableCell align="right">其他</TableCell>
                                                            <TableCell align="right"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {extraList && extraList.map((item, index) => (
                                                            <TableRow
                                                                key={item.sku}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{item.sku}</TableCell>
                                                                <TableCell align="right">{item.color}</TableCell>
                                                                <TableCell align="right">{item.other}</TableCell>
                                                                <TableCell align="right">
                                                                    <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => removeExtra(index)}>
                                                                        删除
                                                                    </Button>
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row"><input value={sku} onChange={(e) => setSku(e.target.value)} /></TableCell>
                                                            <TableCell align="right"><input value={color} onChange={(e) => setColor(e.target.value)} /></TableCell>
                                                            <TableCell align="right"><input value={other} onChange={(e) => setOther(e.target.value)} /></TableCell>
                                                            <TableCell align="right">
                                                                <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addExtra()}>
                                                                    添加
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {editMode == 1 ?
                                    <Button variant="outlined" id="upload" onClick={() => deleteProduct()} >
                                        删除
                                    </Button> : <></>
                                }
                                <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                                    取消
                                </Button>
                                <Button variant="outlined" id="upload" onClick={() => confirmEdit()}>
                                    添加并审核
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
export default DataEntry;