import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SideImg from "../images/network/wixen_signin.jpg";
import LogoImg from "../images/logoback.png";
import { useSelector, useDispatch } from 'react-redux';
import { setSignInfo } from '../data/User/Action';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';

function Copyright(props) {
  let history = useHistory();
  const home = () => {
    history.push("/");
  }

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link onClick={home} color="inherit" href="#">
        Hindar Optcal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  let history = useHistory();
  const dispatch = useDispatch();
  const api_prefix = process.env.REACT_APP_API_PREFIX;
  const [info, setInfo] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");

  const sign_up = () => {
    history.push("/sign_up");
  }

  const reset_pass = () => {
  }

  const custom_route = (roles) => {
    if (roles.length == 0) {
      history.push("/search_glass");
    } else {
      history.push("/admin");
    }
  }

  const email_sign_in = () => {
    try {
      const user = {
        email: email,
        pw: pw
      }
      if (email != "" && pw != "") {
        axios.patch(api_prefix + "/user", user)
          .then((res) => {
            console.log(">>>>>res", res);
            var user = res.data;
            user.user.pw = "";
            if (res.status === 200) {
              localStorage.setItem('user', JSON.stringify(user));
              dispatch(setSignInfo(res.data));
              custom_route(user.user.e_roles);
            } else {
              notify_success("没有用户. 请注册.");
            }
          })
          .catch((error) => {
            notify_fail("网络有问题.");
            throw (error);
          })
      } else {
        notify_warn("您必须填写所有信息")
      }

    } catch (e) {
      console.log("error", e);
    }

  }

  const notify_success = (msg) => toast.success(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notify_warn = (msg) => toast.warn(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notify_fail = (msg) => toast.error(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${SideImg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center", }} >
            <img src={LogoImg} className={"sign_in_logo"} alt="vixen_logo" />
            <Box component="form" noValidate sx={{ mt: 1 }} >
              <TextField value={email} margin="normal" required fullWidth label="电话号码" autoComplete="email" onChange={(e) => setEmail(e.target.value)} />
              <TextField value={pw} margin="normal" required fullWidth label="密码" type="password" autoComplete="current-password" onChange={(e) => setPw(e.target.value)} />
              <FormControlLabel control={<Checkbox value="remember" color="primary" />}
                label="记住账号" className={"vixen_font"} />
              <Button onClick={() => email_sign_in()} className={"vixen_button_pink"} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
                登入
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link onClick={reset_pass} variant="body2" className={"vixen_font have_account"}>
                    忘记密码?
                  </Link>
                </Grid> */}
                {/* <Grid item>
                  <Link onClick={sign_up} variant="body2" className={"vixen_font have_account"}>
                    {"没有帐户？ 报名"}
                  </Link>
                </Grid> */}
              </Grid>
              <Copyright className={"vixen_font"} sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <ToastContainer />
    </ThemeProvider>
  );
}
