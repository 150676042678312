import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { Input } from '@mui/material'; 


function SaleContract() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const s3_prefix = process.env.REACT_APP_S3_PREFIX;
    const user = useSelector((state) => state.user.signInfo);
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [tabValue, setTabValue] = useState('1');
    const [platform, setPlatform] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [editIndex, setEditIndex] = useState(0);

    const [customerList, setCustomerList] = useState([]);
    const [id, setId] = useState("");
    const [contractlist, setContractList] = useState([]);
    const [extraList, setExtraList] = useState([]);
    const [clientName, setClientName] = useState("");
    const [proImg, setProImg] = useState(null);
    const [clientId, setClientId] = useState("");
    const [c_num, setC_num] = useState("");
    const [quanty, setQuanty] = useState(0);
    const [price, setPrice] = useState(0);
    const [sum, setSum] = useState(0);
    const [total, setTotal] = useState(0);
    const [url, setUrl] = useState("");
    const [at, setAt] = useState(0);

    const [orderNum, setOrderNum] = useState("");

    const [items, setItems] = useState(null);

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);

    useEffect(() => { 
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if(roles.includes('全部')){
                    setHasRole(true);
                }
            } else {
                
            }
        }else{
            //history.push("/sign_in");
        }
    }, [user])

    useEffect(() => {
        setLoaded(true)
        var data = {
            mode: "external",
        };
        axios.patch(api_prefix + '/contract', data)
            .then(res => {
                var resData = res.data;
                var temp = [];
                resData.forEach((item) => {
                    if(item.info.from == "external"){
                        temp.push(item);
                    } 
                })
                setContractList(temp); 
                axios.get(api_prefix + '/customer')
                    .then(res => {
                        var resData = res.data;
                        var temp = [];
                        resData.forEach((item) => {
                            temp.push(item.c_name);
                        })
                        setCustomerList(temp);
                        setLoaded(false)

                    }).catch((error) => {
                    });

            }).catch((error) => {
            });
    }, []);

    const notify_success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_warn = (msg) => toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notify_fail = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });



    const handleClose = () => {
        setOpen(false);
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }

    const changePlatform = (event) => {
        setPlatform(event.target.value);
    }

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setProImg(e.target.files[0]);
            setUrl("");
        }
    }

    const uploadFile = async (dir, names) => {
        let reader = new FileReader();
        await reader.readAsDataURL(proImg);
        reader.onload = function () {
            var data = {
                dir: dir,
                img: reader.result,
                imgName: names
            }
            axios.post(api_prefix + "/uploadImg", data)
                .then((res) => {
                    console.log(">>>>>RES, res", res)
                })
                .catch((error) => {
                    throw (error);
                })
        };
        reader.onerror = function (error) {
            console.log(error);
        };
    }

    const createContract = async () => {
        if (clientName === "" || extraList.length == 0 || proImg === null) {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var fileName = Date.now();
        var dir = "contract";
        var n = proImg.name.split('.');
        var ext = n[n.length - 1];
        if (ext === "jpg") {
            ext = "jpeg";
        }
        uploadFile(dir, fileName);
        var urls = s3_prefix + '/' + dir + '/' + fileName + "." + ext;
        var data = {
            c_name: clientName,
            s_num: orderNum,
            info: {
                c_num,
                delivery:"",
                url: urls,
                extraList,
                state: "created", 
                at: Date.now(),
                mt: Date.now(),
                from:'external',
                creator,
            }
        }
        axios.post(api_prefix + '/contract', data)
            .then(res => {
                var temp = [...contractlist];
                temp.push(res.data)
                setContractList(temp);
                notify_success("创建成功");
                setLoaded(false)
                setOpen(false);

            }).catch((error) => {
                notify_fail("未创建！ 检查您的网络");
            });
    }

    const editContract = async () => {
        if (clientName === "" || extraList.length == 0) {
            notify_warn("字段不能为空")
            return
        }
        setLoaded(true)
        var urls = "";
        if (url === "") {
            var fileName = Date.now();
            var dir = "contract";
            var n = proImg.name.split('.');
            var ext = n[n.length - 1];
            if (ext === "jpg") {
                ext = "jpeg";
            }
            uploadFile(dir, fileName);
            urls = s3_prefix + '/' + dir + '/' + fileName + "." + ext;
        } else {
            urls = url;
        }
        var data = {
            id,
            c_name: clientName,
            s_num: orderNum,
            info: {
                c_num,
                delivery:"",
                url: urls,
                extraList,
                state: "created", 
                at,
                mt: Date.now(),
                from:'external',
                creator
            }
        }
        axios.put(api_prefix + '/contract', data)
            .then(res => {
                var temp = [...contractlist];
                temp[editIndex] = data;
                setContractList(temp);
                notify_success("成功更新");
                setLoaded(false);
                setOpen(false);

            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const deleteContract = () => {
        setLoaded(true)
        axios.delete(api_prefix + '/contract/' + id)
            .then(res => {
                var temp = [...contractlist];
                temp.splice(editIndex, 1);
                setContractList(temp);
                notify_success("成功删除");
                setLoaded(false)
                setOpen(false);
            }).catch((error) => {
                notify_fail("更新失败，检查网络.");
            });
    }

    const removeExtra = (index) => {
        var temp = [...extraList];
        temp.splice(index, 1);
        setExtraList(temp);
        var t = 0;
        temp.forEach((item) => {
            t += item.sum;
        })
        setTotal(t);
    }

    const addExtra = () => {
        var temp = [...extraList];
        var data = {
            clientId,
            quanty,
            price,
            sum: quanty * price,
            detail:[],
            c_model:""
        };
        temp.push(data);
        setExtraList(temp);
        setClientId("");
        setQuanty(0);
        setSum(0);
        setPrice(0);
        var t = 0;
        temp.forEach((item) => {
            t += item.sum;
        })
        setTotal(t);
    }

    const openCreateModal = () => {
        setId("");
        setClientName("");
        setExtraList([]);
        setEditIndex(0);
        setEditMode(0);
        setOpen(true);
        createOrderNum();
    }

    const openEditModal = (item, index) => {
        setItems(item);
        setId(item.id);
        setClientName(item.c_name);
        setUrl(item.info.url);
        setExtraList(item.info.extraList);
        setAt(item.info.at);
        setOrderNum(item.s_num)
        setC_num(item.info.c_num)
        setEditIndex(index);
        setEditMode(1);
        setOpen(true);
    }

    const confirmEdit = () => {
        if (editMode === 0) {
            createContract();
        } else {
            editContract();
        }
    }

    const createOrderNum = () => {
        const t = Date.now().toString();
        var od = "S" + t.substring(1, 10);
        setOrderNum(od);
    }

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ? <Grid item xs={12} md={12}><LinearProgress /></Grid> : <></>}
                <Grid item xs={12} md={12}>
                    <OutlinedInput
                        endAdornment={<IconButton><SearchIcon /></IconButton>}
                        value={searchVal}
                        onChange={handleSearch}
                    />
                    <IconButton className="erp-ml-10" onClick={() => openCreateModal()}>
                        <AddCircleIcon className="erp-primary-color-btn-icon" />
                    </IconButton>
                </Grid>

                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>公司名称</TableCell>
                                    <TableCell align="right">合同号</TableCell>
                                    <TableCell align="right">销售单号</TableCell>
                                    <TableCell align="right">状态</TableCell>
                                    <TableCell align="right">查看详细</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contractlist.map((item, index) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{item.c_name}</TableCell>
                                        <TableCell align="right">{item.info.c_num}</TableCell>
                                        <TableCell align="right">{item.s_num}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-orb-color erp-btn-padding" >
                                                配镜完成
                                            </Button>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => openEditModal(item, index)}>
                                                编辑
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>

                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b"> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12} className="erp-mt-20 erp-pt-10">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel >公司名称</InputLabel>
                                        <Select
                                            labelId="property"
                                            value={clientName}
                                            label="property"
                                            onChange={(e) => setClientName(e.target.value)}
                                        >
                                            {
                                                customerList.map((item) => {
                                                    return (
                                                        <MenuItem value={item} className="erp-ml-10 erp-sel">{item}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                <input accept="image/*" type="file" onChange={imageChange} id="main-image" style={{ display: 'none' }} />
                                <label htmlFor="main-image">
                                    <IconButton className="erp-ml-10" variant="contained" component="span" >
                                        <FileUploadIcon className="erp-primary-color-btn-icon" /><span className="erp-font-s">资质上传</span>
                                    </IconButton>
                                </label>
                            </Grid> 
                            <Grid item md={4} xs={12} className="erp-mt-10">
                                {
                                    url != "" ?
                                        <img src={url} className="erp-thumnails" alt="thumb" /> :
                                        proImg && <img src={proImg && URL.createObjectURL(proImg)} className="erp-thumnails" alt="thumb" />
                                }
                            </Grid>
                            <Grid item md={4} xs={12} className="erp-mt-20 erp-pr-10">
                                <TextField
                                    className="erp-w-100"
                                    label="客户订单号"
                                    placeholder="客户订单号"
                                    value={c_num}
                                    onChange={(e)=>setC_num(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g'}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>客人款号</TableCell>
                                                <TableCell align="right">数量</TableCell>
                                                <TableCell align="right">单价</TableCell>
                                                <TableCell align="right">金额</TableCell>
                                                <TableCell align="right">明细</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {extraList && extraList.map((item, index) => (
                                                <TableRow
                                                    key={item.clientId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{item.clientId}</TableCell>
                                                    <TableCell align="right">{item.quanty}</TableCell>
                                                    <TableCell align="right">{item.price}</TableCell>
                                                    <TableCell align="right">{item.sum}</TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => removeExtra(index)}>
                                                            删除
                                                        </Button>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableBody>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row"><input value={clientId} onChange={(e) => setClientId(e.target.value)} /></TableCell>
                                                <TableCell align="right"><input type="number" min="0" value={quanty} onChange={(e) => setQuanty(e.target.value)} /></TableCell>
                                                <TableCell align="right"><input type="number" min="0" value={price} onChange={(e) => setPrice(e.target.value)} /></TableCell>
                                                <TableCell align="right">{quanty * price}</TableCell>
                                                <TableCell align="right">
                                                    <Button variant="contained" className="erp-pb-color erp-btn-padding" onClick={() => addExtra()}>
                                                        添加
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12} className={'row-wrap-g erp-right'}>
                                <p className="erp-font-s">总金额：{total}</p>
                            </Grid>

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {editMode == 1 ?
                        <Button variant="outlined" onClick={() => deleteContract()} >
                            删除
                        </Button> : <></>
                    }
                    <Button variant="outlined" id="upload" onClick={() => setOpen(false)} >
                        取消
                    </Button>
                    <Button variant="outlined" id="upload" onClick={() => confirmEdit()} >
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default SaleContract;