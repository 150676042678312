import { combineReducers } from 'redux';
import UserReducer from '../data/User/Reducer';
import StatusReducer from '../data/Status/Reducer'; 

const appReducer = combineReducers({
  user: UserReducer,
  status: StatusReducer, 
});

const rootReducer = (state, action) => { 
      return appReducer(state, action); 
};

export default rootReducer;
