import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button, LinearProgress } from '@material-ui/core';
import glass from './glass.jpg';
import { styled } from '@mui/material/styles';
import '../it.css';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));


function DownBox() {
    return (
        <Grid item xs={12} md={3} className="right-scope">
            <div>
                <StyledBadge badgeContent={4} color="primary" className='folder-badge'>
                    <FolderOpenIcon color="action" className='folder-icon' />
                </StyledBadge >
            </div>

            <div className='item-area'>
                <Grid container>
                    <Grid item xs={6} md={6}>
                        <div className="image-wrap">
                            <img src={glass} className="main-image-item" alt="thumb" />
                            <IconButton className="image-control-btn" >
                                <CancelRoundedIcon className="close-icon" />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div className="image-wrap">
                            <img src={glass} className="main-image-item" alt="thumb" />
                            <IconButton className="image-control-btn" >
                                <CancelRoundedIcon className="close-icon" />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                

            </div>
            <div className='btn-area'>
                <Button variant="contained" className='primary-color primary-width primary-tm'>下载 PDF</Button>
                <br/>
                <Button variant="contained" className='primary-color primary-width primary-tm'>开始</Button>
            </div>

        </Grid>
    );
}

export default DownBox;
