import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import Jimp from 'jimp';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import './it.css';
import Radio from '@mui/material/Radio';
// import calls from './custom.js'

function LegUpload() {
    const api_prefix = process.env.REACT_APP_API_PREFIX;
    const category = useSelector((state) => state.status.setLegTags);
    const user = useSelector((state) => state.user.signInfo);
    const [categoryData, setCategoryData] = useState({});
    const [open, setOpen] = React.useState(false);
    const [lid, setLid] = useState("");
    const [angle, setAngle] = useState(0);
    const [len, setLen] = useState(0);
    const [tags, setTags] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [selectedImageMain, setSelectedImageMain] = useState(null);
    const [optimizeImg, setOptimizeImg] = useState(null);
    const [selectedImageSub, setSelectedImageSub] = useState(null);
    const [checked, setChecked] = React.useState({});
    const [upcnt, setUpcnt] = useState(0);
    var sTemp = "";
    var mTemp = "";
    var callCnt = 0;

    const [point1, setPoint1] = useState([0, 0]);
    const [point2, setPoint2] = useState([0, 0]);
    const [selPoint, setSelPoint] = useState([1, 0]);

    const [selectedValue, setSelectedValue] = React.useState('a');

    const [token, setToken] = useState("");
    const [role, setRole] = useState([]);
    const [creator, setCreator] = useState(null);
    const [hasRole, setHasRole] = useState(false);
    const [success, setSuccess] = useState(false);

    const confirmUpload = () => {
        setSuccess(false);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
        setAngle(0);
        setLen(0)
        setTags([]);
        setSelectedImageMain(null);
        setSelectedImageSub([]);
        setOptimizeImg(null);
        setUpcnt(0);
        setPoint1([0, 0]);
        setPoint2([0, 0]);
        setSelectedValue('a');
        setSelPoint([1, 0]);
        sTemp = "";
        mTemp = "";
        callCnt = 0;
    }

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
    };

    var c = null;
    var ctx = null;
    var zoomR = 0.5;
    let legPoint = [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]];
    var paddingVal = 20;

    useEffect(() => { 
        if (user != undefined) {
            if ('user' in user) {
                setToken(user.token);
                setRole(user.user.e_roles);
                var c = {
                    name: user.user.info,
                    id: user.user.id,
                    phone: user.user.email,
                    at: Date.now(),
                }
                setCreator(c);
                var roles = user.user.e_roles;
                if(roles.includes('图库管理')|| roles.includes('全部')){
                    setHasRole(true);
                }
            } else {
                
            }
        }else{
            //history.push("/sign_in");
        }
    }, [user])

    useEffect(() => {
        setCategoryData(category);
        var temp = {};
        category && Object.keys(category).map((keys, indexs) => {
            category[keys].forEach((item, index) => {
                var tp = item + ":" + keys;
                temp[tp] = false;
            });
        });
        setChecked(temp);
    }, [category]);

    useEffect(() => {
        c = document.getElementById("myCanvas");
        // c.replaceWith(c.cloneNode(true));
        ctx = c.getContext("2d");
        var z = document.getElementById("zoomCanvas");
        var zCtx = z.getContext("2d");
        let rect = c.getBoundingClientRect();
        z.width = 200;
        z.height = 200;
        c.addEventListener("mousemove", function (e) {
            var sT = window;
            zCtx.fillStyle = "white";
            zCtx.fillRect(0, 0, 200, 200);
            zCtx.drawImage(c, e.offsetX / zoomR - 100, e.offsetY / zoomR - 100, 200, 200, 0, 0, 200, 200);
            z.style.top = 0 + 'px';
            z.style.left = 0 + 'px';
            zCtx.beginPath()
            zCtx.moveTo(100, 0);
            zCtx.lineTo(100, 200);
            zCtx.moveTo(0, 100);
            zCtx.lineTo(200, 100);
            zCtx.lineWidth = 1;
            zCtx.strokeStyle = '#000';
            zCtx.stroke();
            z.style.zoom = 1;
            z.style.display = 'block';
        });
        c.addEventListener("mouseout", function () {
            z.style.display = 'none';
        });
    }, [selectedImageMain])


    useEffect(() => {
        c = document.getElementById("myCanvas");
        c.removeEventListener("mousedown", act);
        function act(e) {
            let rect = c.getBoundingClientRect();
            let x = e.offsetX / zoomR;
            let y = e.offsetY / zoomR;
            // console.log(">>>pos", e.offsetX / zoomR, "--", e.offsetY / zoomR);
            var cha = document.getElementById("ch-a");
            var chb = document.getElementById("ch-b");
            if (cha.checked) {
                setPoint1([x, y]);
            }
            if (chb.checked) {
                setPoint2([x, y]);
            }
        }
        c.addEventListener("mousedown", act);
    }, [selectedImageMain]);


    useEffect(() => {
        selectedImageMain && Jimp.read(URL.createObjectURL(selectedImageMain))
            .then(img => {

                var imgHeight = img.bitmap.height;
                var imgWidth = img.bitmap.width;
                var maxX = 0;
                var minX = imgWidth;
                var maxY = 0;
                var minY = imgHeight;

                for (var x = 10; x < imgWidth - 10; x++) {
                    for (var y = 10; y < imgHeight - 10; y++) {
                        var colorVal = img.getPixelColor(x, y);
                        var rgba = Jimp.intToRGBA(colorVal);
                        if (colorVal != 0 && rgba["a"] > 150) {
                            //  find the 0 point
                            if (x < minX) {
                                minX = x;
                                legPoint[0][0] = minX;
                                legPoint[0][1] = y;
                            }
                            // find the 2 point
                            if (x > maxX) {
                                maxX = x;
                                legPoint[2][0] = maxX;
                                legPoint[2][1] = y;
                            }
                            // find the 3 point
                            if (y < minY) {
                                minY = y;
                                legPoint[3][0] = x;
                                legPoint[3][1] = minY;
                            }
                            // find the 1 point
                            if (y > maxY) {
                                maxY = y;
                                legPoint[1][0] = x;
                                legPoint[1][1] = maxY;
                            }
                        }
                    }
                }

                var newWidth = legPoint[2][0] - legPoint[0][0];
                var newHeight = legPoint[1][1] - legPoint[3][1];
                var transparentImg = new Jimp(newWidth + paddingVal * 2, newHeight + paddingVal * 2, '#00000000');
                img.crop(legPoint[0][0], legPoint[3][1], legPoint[2][0] - legPoint[0][0], legPoint[1][1] - legPoint[3][1]);
                var imgLeg = transparentImg.blit(img, paddingVal, paddingVal);

                const WIDTH = imgLeg.bitmap.width;
                const HEIGHT = imgLeg.bitmap.height;
                c.width = WIDTH;
                c.height = HEIGHT;
                const arrayBuffer = new ArrayBuffer(WIDTH * HEIGHT * 4);
                const pixels = new Uint8ClampedArray(arrayBuffer);
                for (var y = 0; y < HEIGHT; y++) {
                    for (var x = 0; x < WIDTH; x++) {
                        const i = (y * WIDTH + x) * 4;
                        var colorVal = imgLeg.getPixelColor(x, y);
                        var rgba = Jimp.intToRGBA(colorVal);
                        pixels[i] = rgba["r"];   // red
                        pixels[i + 1] = rgba["g"];   // green
                        pixels[i + 2] = rgba["b"];   // blue
                        pixels[i + 3] = rgba["a"]; // alpha 
                    }
                }
                const imageData = new ImageData(pixels, WIDTH, HEIGHT);
                c.style.zoom = zoomR;
                ctx.putImageData(imageData, 0, 0);
                c = document.getElementById("myCanvas");
                c.style.border = 'solid 1px #6262626b';
                var dataURL = c.toDataURL();
                setOptimizeImg(dataURL);
            })
            .catch(err => {

            });

        // ctx.drawImage(img, 10, 10);
    }, [selectedImageMain]) 

    const selectCategory = () => {

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const imageChangeMain = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImageMain(e.target.files[0]);
            var cnt = upcnt + 1;
            setUpcnt(cnt);
        }
    }

    const removeSelectedImageMain = () => {
        setSelectedImageMain(null);
        var cnt = upcnt - 1;
        setUpcnt(cnt);
    }

    const handleChange = (e, item, mk) => {
        var temp = { ...checked };
        temp[`${item}:${mk}`] = e.target.checked;
        setChecked(temp);
        var temps = [];
        Object.keys(temp).forEach((item) => {
            if (temp[item] === true) {
                temps.push(item);
            }
        });
        setTags(temps);
    }

    const uploadImgInterface = async (file, mode) => {
        setLoaded(true);
        if (mode == 0) {
            var data = {
                dir: 'legs',
                img: optimizeImg
            }
            axios.post(api_prefix + "/upload", data)
                .then((response) => {
                    if (mode == 0) {
                        mTemp = response.data.Location;
                        callCnt++;
                    } else {
                        sTemp = response.data.Location;
                        callCnt++;
                    }
                    if (callCnt == upcnt) {
                        saveDate();
                    }
                })
                .catch((error) => {
                    throw (error);
                })
        } else {
            let reader = new FileReader();
            await reader.readAsDataURL(file);
            reader.onload = function () {
                var data = {
                    dir: 'legs',
                    img: reader.result
                }
                axios.post(api_prefix + "/upload", data)
                    .then((response) => {
                        if (mode == 0) {
                            mTemp = response.data.Location;
                            callCnt++;
                        } else {
                            sTemp = response.data.Location;
                            callCnt++;
                        }
                        if (callCnt == upcnt) {
                            saveDate();
                        }
                    })
                    .catch((error) => {
                        throw (error);
                    })
            };
            reader.onerror = function (error) {
                console.log(error);
            };
        }
    }

    const saveDate = () => {
        //console.log('>>>>ready to upload')
        var legData = {
            l_id: "leg",
            l_angle: angle,
            l_len: len,
            l_tags: tags,
            l_url: mTemp,
            l_point: [point1, point2],
            extra: {
                creator
            }
        }
        axios.post(api_prefix + '/leg', legData)
            .then(response => { 
                setLoaded(false);
                notify_success();
                setSuccess(true);
                var data = {
                    tbody: response.data,
                    sel: 2,
                };
                axios.post(api_prefix + "/assemble", data).
                    then((res) => {
                    });

            }).catch((error) => {
                notify_fail();
            });
        mTemp = "";
        callCnt = 0;
    }
    const handleSave = () => {
        uploadImgInterface(selectedImageMain, 0);
    }
    const handleLen = (e) => {
        setLen(e.target.value);
    }
    const handleAngle = (e) => {
        setAngle(e.target.value);
    }  
    const notify_success = () => toast.success('Successfully  updated!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_fail = () => toast.error('Update failed!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container maxWidth="xl" className="sample-scope" >
            <Grid container spacing={3}>
                {loaded ?
                    <Grid item md={12}>
                        <LinearProgress />
                    </Grid> :
                    <></>
                }
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TextField 
                        label="桩头高度"
                        placeholder="桩头高度" 
                        value={len}
                        onChange={handleLen}
                        type="number"
                        InputProps={{ inputProps: { step: 0.01, max: 50, min: 0 } }}
                    /> 
                </Grid>
                <Grid item xs={12} md={12} className={'row-wrap-g'}>
                    <TextField 
                        label="倾斜角度"
                        placeholder="倾斜角度" 
                        value={angle}
                        onChange={handleAngle}
                        type="number"
                        InputProps={{ inputProps: { step: 0.1, max: 50, min: -50 } }}
                    />
                </Grid>
                <Grid item xs={12} className="category-section">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" endIcon={<AddIcon />} className="primary-color" onClick={() => handleClickOpen()}>标签</Button>
                        </Grid>
                        <Grid item xs={12} md={10} className={'row-wrap-g'}>
                            {checked &&
                                Object.keys(checked).map((item) => {
                                    return (
                                        <>
                                            {checked[item] === true ?
                                                <div className="checked-item">{item}</div> : <></>
                                            }
                                        </>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <input accept="image/*" type="file" onChange={imageChangeMain} id="main-image" style={{ display: 'none' }} />
                <Grid item xs={12} className="main-img-section">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={3}>
                            <label htmlFor="main-image">
                                <Button variant="contained" endIcon={<AddIcon />} className="primary-color" component="span">腿侧面图</Button>
                            </label>
                            <p className="warn-msg">要求：透明底png格式</p>
                            <div className="in-row">
                                <Radio
                                    id="ch-a"
                                    checked={selectedValue === 'a'}
                                    onChange={handleChangeRadio}
                                    value="a"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                                <p className="ml"> Point1 : {`${point1[0]}`}-{`${point1[1]}`}</p>
                            </div>
                            <div className="in-row">
                                <Radio
                                    id="ch-b"
                                    checked={selectedValue === 'b'}
                                    onChange={handleChangeRadio}
                                    value="b"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                                <p className="ml"> Point2 : {`${point2[0]}`}-{`${point2[1]}`}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9} className={'row-wrap-g '}>
                            <canvas id="zoomCanvas" />
                        </Grid>
                        <Grid item xs={12} md={3}> </Grid>
                        <Grid item xs={12} md={9} className={'row-wrap-g'}>
                            {selectedImageMain ? <div className="image-wrap ">
                                <canvas id="myCanvas" />
                                <IconButton className="image-control-btn" onClick={removeSelectedImageMain}>
                                    <CancelRoundedIcon className="close-icon" />
                                </IconButton>
                            </div> : <canvas id="myCanvas" />}
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item xs={12} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} className={'row-wrap-g align-right'}>
                            <Button variant="outlined" color="primary" className="gap-right">取消</Button>
                            <Button variant="outlined" color="primary" className="gap-right" onClick={handleSave}>保存上传</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <ToastContainer />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className="my-font-b">选择类别</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            categoryData && Object.keys(categoryData).map((keys, indexs) => (
                                <>
                                    <div className="check-box-mux">
                                        <p className="check-label">{keys} :</p>
                                        {categoryData[keys].map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    className="check-box-wrap"
                                                    control={
                                                        <Checkbox defaultChecked checked={checked[`${item}:${keys}`]}
                                                            onChange={(e) => handleChange(e, item, keys)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label={`${item}`}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Divider className="my-divider" />
                                </>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="my-font-b" onClick={handleClose}>确认</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={success}
                onClose={() => setSuccess(false)}
            >
                <DialogTitle className="my-font-b">通知 : 上传成功</DialogTitle>
                <DialogActions>
                    <Button className="my-font-b primary-color" onClick={() => confirmUpload()}>确认</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
export default LegUpload;